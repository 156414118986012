import {Component, Input, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';

@Component({
  selector: 'ucs-logo-icon[distributionChannel][country]',
  templateUrl: './logo-icon.component.html',
  styleUrls: ['./logo-icon.component.scss']
})
export class LogoIconComponent implements OnDestroy {
  @Input() distributionChannel: DistributionChannel;
  @Input() country: Country;
  @Input() class: string;
  @Input() dialog: boolean;

  private unsubscribe: Subject<void> = new Subject<void>();
  private logoFiles = new Map<DistributionChannel, Map<Country, string>>([
    ['ALL_UC', new Map<Country, string>([
      ['AT', 'logo_ALL_UC_AT']
    ])],
    ['PB', new Map<Country, string>([
      ['AT', 'logo_PB_AT'],
      ['HR', 'logo_PB_HR'],
      ['HU', 'logo_PB_HU'],
      ['RO', 'logo_PB_RO'],
      ['RS', 'logo_PB_RS'],
      ['MK', 'logo_PB_MK']
    ])],
    ['PIA', new Map<Country, string>([
      ['AT', 'logo_PIA_AT']
    ])],
    ['DIN_BIL', new Map<Country, string>([
      ['SE', 'logo_DIN_BIL_SE']
    ])],
    ['VGRX', new Map<Country, string>([
      ['DE', 'logo_VGRX_DE']
    ])]
  ]);

  handleSVG(svg: SVGElement): SVGElement {
    svg.setAttribute('width', '100%');
    svg.setAttribute('height', '100%');
    if (this.dialog) {
      svg.setAttribute('width', this.getSvgStyle(this.distributionChannel).toString());
    }
    return svg;
  }

  getLogoFile(distributionChannel: DistributionChannel, country: Country): string {
    let result = this.logoFiles.get(distributionChannel).get(country);
    if (result === undefined) {
      return this.logoFiles.get(distributionChannel).get('AT');
    }
    return result;
  }

  getAdditionalClasses(): string {
    if(this.dialog) {
      if (this.distributionChannel === 'PB') {
        return 'pb-dialog-logo';
      } else {
        return '';
      }
    }
    return '';
  }

  private getSvgStyle(channel: DistributionChannel): number {
    switch (channel) {
    case 'ALL_UC':
      return 80;
    case 'DIN_BIL':
      return 80;
    case 'PB':
      return 120;
    case 'PIA':
      return 80;
    case 'VGRX':
      return 80;
    default:
      return 100;
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
