import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {first, takeUntil} from 'rxjs/operators';
import {SystemSettingsService} from '../../../service/system-settings.service';
import {Subject} from 'rxjs';
import {NgxPopperjsPlacements, NgxPopperjsTriggers} from 'ngx-popperjs';

/**
 * Hosts an vehicle card containing compact information on the vehicle as well as the possibility to bid/buy
 */
@Component({
  selector: 'ucs-vehicle-status',
  templateUrl: './vehicle-status.component.html',
  styleUrls: ['./vehicle-status.component.scss']
})
export class VehicleStatusComponent implements OnInit, OnDestroy {
  @Input() vehicle: VehicleBaseDto | VehicleDetailDto;

  missingImagesCount = 0;
  showInitialProposalIcon = false;
  etbDocumentNecessary = false;
  isAllowedToSeeSellerCalculations: boolean;

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(private systemSettingsService: SystemSettingsService) {
  }

  ngOnInit(): void {
    this.systemSettingsService
      .isSystemFeatureActivatedForChannel('INITIAL_PROPOSAL_ICON', this.vehicle.channel)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(showInitialProposalIcon => {
        this.showInitialProposalIcon = showInitialProposalIcon;
      });

    this.systemSettingsService
      .getSystemFeatureSettingForChannel('MANDATORY_DOCUMENT.mandatory_document',
        this.vehicle.channel)
      .pipe(first())
      .subscribe(value => {
        this.missingImagesCount = JSON.parse(JSON.parse(value).imageCount);
        this.vehicle.missingPicture = (this.vehicle.images.length < this.missingImagesCount);
        this.etbDocumentNecessary = JSON.parse(JSON.parse(value).etbNecessary);
      });

    this.systemSettingsService.getSystemFeatureSettingForChannel('VEHICLE_MAINTENANCE.seller_calculations',
      this.vehicle.channel)
      .pipe(takeUntil(this.unsubscribe)).subscribe(setting => {
        if (setting) {
          this.isAllowedToSeeSellerCalculations = setting === 'true';
        }
      });
  }

  showMissingDocumentIcon() {
    if (this.vehicle.seller.fleetDealer && this.vehicle.channel === 'ALL_UC') {
      return false;
    } else if (!this.etbDocumentNecessary && this.vehicle.channel === 'DIN_BIL') {
      return false;
    } else {
      return true;
    }
  }

  protected readonly NgxPopperjsTriggers = NgxPopperjsTriggers;
  protected readonly NgxPopperjsPlacements = NgxPopperjsPlacements;

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}

