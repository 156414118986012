<!-- automatically unsubscribe store selections via async pipe -->
<ucs-channel-selection *ngIf="(searchAggregation$ && channel$) | async"
  [canViewALLUC]="canViewALLUC"
  [canViewPB]="canViewPB"
  [canViewPIA]="canViewPIA"
  [canViewDINBIL]="canViewDINBIL"
  [hideDropDown]="hideDropDown"
  [showSidebar]="showSidebar"
  [platformListClass]="platformListClass"
  [elementsCountALLUC]="elementsCountALLUC"
  [elementsCountPB]="elementsCountPB"
  [elementsCountPIA]="elementsCountPIA"
  [elementsCountDINBIL]="elementsCountDINBIL"
  [selectedChannel]="channel"
  (channelChangedEmitter)="pickDistributionChannel($event)"
></ucs-channel-selection>
