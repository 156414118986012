<input #priceInput=ngModel
       (focus)="onFocus()"
       type="number"
       lang="de"
       step="{{ step }}"
       min="0"
       pattern="^\d*(\.\d{0,2})?$"
       class="{{ class }}"
       name="{{ name }}"
       id="{{ id }}"
       [(ngModel)]="innerModel"
       [disabled]="disabled"
       [ngClass]="{'no-valid-ppmv' : inputError}"
       (ngModelChange)="inputChange($event)"
       [required]="required" max="9999999999"
       (keyup.enter)="enterOnInputElement.emit()">
<div *ngIf="innerModel > 9999999999" class="error">{{ 'bid-dialog.validation.figure-to-high' | translate }}</div>
<div *ngIf="priceInput.invalid && priceInput.dirty && priceInput?.errors?.pattern"
     class="error">{{ 'bid-dialog.validation.pattern' | translate }}</div>
<div *ngIf="inputError"
     class="error mt-1">{{ 'error.gross-smaller-ppmv' | translate }}</div>
