<!-- PICTURES -->
<a [popper]="popPicture" [popperTrigger]="NgxPopperjsTriggers.hover" [popperPlacement]="NgxPopperjsPlacements.BOTTOM" [popperApplyClass]="'popper-content-display'" role="button" tabindex="0">
  <ucs-icon [shape]="vehicle.missingPicture ? 'camera' : 'camera_checked'"></ucs-icon>
</a>
<popper-content class="popper-content-display" #popPicture>
  <div *ngIf="vehicle.missingPicture && missingImagesCount > 1">
    {{ 'vehicle.missing-picture' | translate }}
    ({{ 'vehicle.missing-picture.prefix' | translate }}
    {{ (missingImagesCount ) }} {{ 'vehicle.missing-picture-count' | translate }})
  </div>
  <div *ngIf="vehicle.missingPicture && missingImagesCount === 1">
    {{ 'vehicle.missing-picture' | translate }}
    ({{ 'vehicle.missing-picture.prefix' | translate }}
    {{ (missingImagesCount ) }} {{ 'vehicle.missing-single-picture-count' | translate }})
  </div>
  <div *ngIf="!vehicle.missingPicture">
    {{ 'vehicle.enough-picture' | translate }}
  </div>
</popper-content>

<!-- DOCUMENTS -->
<ng-container *ngIf="showMissingDocumentIcon()">
  <a [popper]="popDocument" [popperTrigger]="NgxPopperjsTriggers.hover" [popperPlacement]="NgxPopperjsPlacements.BOTTOM" [popperApplyClass]="'popper-content-display'" role="button" tabindex="0">
    <ucs-icon [shape]="vehicle.missingDocument ? 'document' : 'document_checked'"></ucs-icon>
  </a>
  <popper-content class="popper-content-display" #popDocument>
    <div>
      <div>
        {{ (vehicle.missingEtb ? 'vehicle.missing-etb' : 'vehicle.complete-etb') | translate }}
      </div>
      <div *ngIf="vehicle.channel === 'PB'">
        {{ (vehicle.missingCertificate ? 'vehicle.missing-certificate' : 'vehicle.complete-certificate') | translate}}
      </div>
    </div>
  </popper-content>
</ng-container>

<!-- PERSON -->
<a [popper]="popPerson" [popperTrigger]="NgxPopperjsTriggers.hover" [popperPlacement]="NgxPopperjsPlacements.BOTTOM" [popperApplyClass]="'popper-content-display'" role="button" tabindex="0">
  <ucs-icon [shape]="vehicle.missingContactPerson ? 'user' : 'user_checked'"></ucs-icon>
</a>
<popper-content class="popper-content-display" #popPerson>
  <div>
    {{ (vehicle.missingContactPerson ? 'vehicle.missing-person' : 'vehicle.set-person') | translate }}
  </div>
</popper-content>

<!-- VEHICLE DETAIL -->
<a [popper]="popVehicleDetail" [popperTrigger]="NgxPopperjsTriggers.hover" [popperPlacement]="NgxPopperjsPlacements.BOTTOM" [popperApplyClass]="'popper-content-display'" role="button" tabindex="0">
  <ucs-icon data-cy="vehicle-detail.pencil-icon" [shape]="vehicle.missingVehicleDetail ? 'edit-3' : 'edit-3_checked'"></ucs-icon>
</a>
<popper-content class="popper-content-display"#popVehicleDetail>
  <div>
    {{ (vehicle.missingVehicleDetail ? 'vehicle.missing-vehicleDetail' : 'vehicle.set-vehicleDetail') | translate }}
  </div>
</popper-content>

<!-- INITIAL PROPOSAL -->
<ng-container *ngIf="showInitialProposalIcon">
  <a [popper]="popInitialProposal" [popperTrigger]="NgxPopperjsTriggers.hover" [popperPlacement]="NgxPopperjsPlacements.BOTTOM" [popperApplyClass]="'popper-content-display'" role="button" tabindex="0">
    <ucs-icon [shape]="vehicle.missingInitialProposalPrice ? 'plus-square' : 'plus-square_checked'"></ucs-icon>
  </a>
  <popper-content class="popper-content-display" #popInitialProposal>
    <div>
      {{ (vehicle.missingInitialProposalPrice ? 'vehicle.missing-initialProposalPrice' : 'vehicle.set-initialProposalPrice') | translate }}
    </div>
  </popper-content>
</ng-container>
<!-- SELLER CALCULATIONS -->
<ng-container *ngIf="isAllowedToSeeSellerCalculations">
  <a [popper]="popInitialProposal" [popperTrigger]="NgxPopperjsTriggers.hover" [popperPlacement]="NgxPopperjsPlacements.BOTTOM" [popperApplyClass]="'popper-content-display'" role="button" tabindex="0">
    <ucs-icon [shape]="vehicle.sellerCalculationsCompleted ? 'calculations_checked' : 'calculations'"></ucs-icon>
  </a>
  <popper-content class="popper-content-display" #popInitialProposal>
    <div>
      {{
        (vehicle.sellerCalculationsCompleted ? 'vehicle.set-sellerCalculations' :
          'vehicle.missing-sellerCalculations') |
      translate }}
    </div>
  </popper-content>
</ng-container>
