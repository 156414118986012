<div class="col-12 image mb-3 px-0 mx-md-3 d-print-none">
<div data-bs-toggle="modal" [attr.data-bs-target]="'#gallery-modal' + galleryId" class="modal-opener"></div>
  <div class="thumbnail-carousel-gallery-wrapper">
    <ks-carousel *ngIf="galleryImages.length > 0" #carouselGallery
                 [images]="galleryImages"
                 [config]="thumbnailCarouselConfig"
                 [id]="DETAIL_THUMBNAIL_CAROUSEL_ID"
                 class="d-block"></ks-carousel>
  </div>
</div>
<div #galleryModal class="modal fade gallery-modal ucs-modal-dialog-centered" id="{{'gallery-modal' + galleryId}}" tabindex="-1" role="dialog"
     aria-labelledby="gallery-modal-label"
     aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <button *ngIf="!galleryViewInScope" type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"></span>
        </button>
        <ks-plain-gallery *ngIf="galleryViewInScope && galleryImages?.length > 0"
                          [images]="galleryImages"
                          [config]="plainGalleryConfig"
                          [id]="DETAIL_PLAIN_GALLERY_ID" (clickImage)="onShow(DETAIL_PLAIN_GALLERY_ID, $event)"></ks-plain-gallery>
        <ks-carousel *ngIf="!galleryViewInScope && galleryImages?.length > 0" #modalCarouselGallery
                     class="col-12 carousel-gallery"
                     [images]="galleryImages"
                     [config]="carouselGalleryConfig"
                     [id]="DETAIL_CAROUSEL_GALLERY_ID">
        </ks-carousel>
      </div>
    </div>
  </div>
</div>
