import {Component, Input} from '@angular/core';
import {NgxPopperjsTriggers} from 'ngx-popperjs';

@Component({
  selector: 'ucs-info-icon',
  templateUrl: './info-icon.component.html',
  styleUrls: ['./info-icon.component.scss']
})
export class InfoIconComponent {
  @Input() infoText: string;
  @Input() labelVisible = true;
  @Input() popperPlacement = 'left';

  protected readonly NgxPopperjsTriggers = NgxPopperjsTriggers;
}
