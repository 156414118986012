<!-- type default => value[] has max length 1 -->
<div class="bullet" *ngIf="bullet && bullet.type === 'default' && bullet.value[0] !== ''">
  <div class="text px-3">
    <span *ngIf="bullet['key'].startsWith('channels')">{{ 'ucs.' + bullet.value[0].toLowerCase() | translate }}</span>
    <span *ngIf="bullet['key'].startsWith('combinedSellingDealers')">{{ bullet.value[0] }}</span>
    <span *ngIf="bullet['key'].startsWith('countries')">{{ 'api.type.country.' + bullet.value[0] | translate }}</span>
    <span *ngIf="bullet['key'] === 'vatType'">{{ 'api.type.vat.' + bullet.value[0] | translate }}</span>
    <span
      *ngIf="bullet['key'].startsWith('exploitationType')">{{ 'api.type.exploitation.' + bullet.value[0] | translate }}</span>
    <span *ngIf="bullet['key'].startsWith('colors')">{{ 'api.type.basecolor.' + bullet.value[0] | translate }}</span>
    <span *ngIf="bullet['key'].startsWith('chassis')">{{  bullet.value[0] }}</span>
    <span *ngIf="bullet['key'].startsWith('seats')"><span
      class="me-1">{{  bullet.value[0] }}</span>{{ 'search-filter.seats' | translate }}</span>
    <span *ngIf="bullet['key'].startsWith('gears')">{{  bullet.value[0] }}</span>
    <span *ngIf="bullet['key'].startsWith('gearTypes')">{{  bullet.value[0] }}</span>
    <span *ngIf="bullet['key'] === 'co2Emission'">{{  (bullet.value[0] | cast : Number) | gramsPerHundredKm }}</span>
    <span *ngIf="bullet['key'].startsWith('emissionClass')">{{  bullet.value[0] }}</span>
    <span *ngIf="bullet['key'].startsWith('fuels')">{{ bullet.value[0] }}</span>
    <span *ngIf="bullet['key'] === 'bundling'">{{ 'api.type.offerbundling.' + bullet.value[0] | translate }}</span>
    <span *ngIf="bullet['key'] === 'type'">{{ 'api.type.offertype.' + bullet.value[0] | translate }}</span>
    <span *ngIf="bullet['key'].startsWith('brand')">{{ bullet.value[0] }}</span>
    <span *ngIf="bullet['key'].startsWith('models')">{{ bullet.value[0] }}</span>
    <span *ngIf="bullet['key'].startsWith('modelVariants')">{{ bullet.value[0] }}</span>
    <span *ngIf="bullet['key'] === 'extDealerId'">{{'search-filter.extDealerId' | translate}}
      : {{ bullet.value[0] }}</span>
    <span *ngIf="bullet['key'] === 'extVehicleId'">{{'search-filter.extVehicleId' | translate}}
      : {{ bullet.value[0] }}</span>
    <span *ngIf="bullet['key'] === 'vin'">{{'search-filter.vin' | translate}}: {{ bullet.value[0] }}</span>
  </div>
  <ucs-icon class="icon" (click)="delete(bullet)" [shape]="'cancel'" [style]="'grey'"></ucs-icon>
</div>

<!-- type default => value[] has max length 1 -->
<div class="bullet" *ngIf="bullet && bullet.type === 'equipmentHighlight' && bullet.value[0] !== ''">
  <div class="text px-3">
    <span>{{ 'equipment.' + bullet.value[0] | translate }}</span>
  </div>
  <ucs-icon class="icon" (click)="delete(bullet)" [shape]="'cancel'" [style]="'grey'"></ucs-icon>
</div>

<!-- type range => value[] has max length 2 -->
<div class="bullet" *ngIf="bullet && bullet.type === 'range' && (bullet.value[0] !== '' || bullet.value[1] !== '')">
  <div class="text px-3">
        <span *ngIf="bullet.value[0] !== ''">
            <span *ngIf="!bullet['key'].startsWith('price')" class="px-1">{{ 'search-range-dropdown.from' | translate }}</span>
            <span *ngIf="bullet['key'].startsWith('price')">{{ 'search-filter.price.from' | translate }} <span
              class="pe-1">{{ (bullet.value[0] | cast : Number) | price }}</span></span>
            <span *ngIf="bullet['key'].startsWith('powerPs')">{{ (bullet.value[0] | cast : Number) | ps }} </span>
            <span *ngIf="bullet['key'].startsWith('mileage')">{{ (bullet.value[0] | cast : Number) | km }} </span>
            <span *ngIf="bullet['key'].startsWith('initialRegistration')">{{ bullet.value[0] }} </span>
            <span *ngIf="bullet['key'].startsWith('consumption')">
                <span *ngIf="bullet.value[1] !== ''">{{ bullet.value[0] }} </span>
                <span *ngIf="bullet.value[1] === ''">{{ (bullet.value[0] | cast : Number) | litersPerHundredKm }} </span>
            </span>
            <span *ngIf="bullet['key'].startsWith('motorCapacity')">
                <span *ngIf="bullet.value[1] !== ''">{{ bullet.value[0] }} </span>
                <span *ngIf="bullet.value[1] === ''">{{ (bullet.value[0] | cast : Number) | ccm }} </span>
            </span>
        </span>

    <span *ngIf="bullet.value[1] !== ''">
            <span *ngIf="!bullet['key'].startsWith('price')">{{ 'search-filter.until' | translate }} </span>
            <span *ngIf="bullet['key'].startsWith('price')">{{ 'search-filter.price.to' | translate }} </span>
            <span *ngIf="bullet['key'].startsWith('price')"><span
              class="pe-1">{{ (bullet.value[1] | cast : Number) | price }}</span></span>
            <span *ngIf="bullet['key'].startsWith('powerPs')">{{ (bullet.value[1] | cast : Number) | ps }}</span>
            <span *ngIf="bullet['key'].startsWith('mileage')">{{ (bullet.value[1] | cast : Number) | km }}</span>
            <span *ngIf="bullet['key'].startsWith('initialRegistration')">{{ bullet.value[1] }}</span>
            <span
              *ngIf="bullet['key'].startsWith('consumption')">{{ (bullet.value[1] | cast : Number) | litersPerHundredKm }}</span>
            <span *ngIf="bullet['key'].startsWith('motorCapacity')">{{ (bullet.value[1] | cast : Number) | ccm }}</span>
        </span>
  </div>
  <ucs-icon class="icon" (click)="delete(bullet)" [shape]="'cancel'" [style]="'grey'"></ucs-icon>
</div>

<!-- type city => value[] has max length 2 -->
<div class="bullet" *ngIf="bullet && bullet.type === 'city' && (bullet.value[0] !== '' || bullet.value[1] !== '')">
  <div class="text px-3">
    <span *ngIf="bullet.value[0] !== ''" class="pe-1">{{ bullet.value[0] }}</span>
    <span *ngIf="bullet.value[1] !== ''">{{ bullet.value[1] }}</span>
  </div>
  <ucs-icon class="icon" (click)="delete(bullet)" [shape]="'cancel'" [style]="'grey'"></ucs-icon>
</div>

<!-- type equipment -->
<div class="bullet" *ngIf="bullet && bullet.type === 'equipment'">
  <div class="text px-3">
    <span>{{ 'api.type.usedcarequipment.' + bullet.value[0] | translate }}</span>
  </div>
  <ucs-icon class="icon" (click)="delete(bullet)" [shape]="'cancel'" [style]="'grey'"></ucs-icon>
</div>

