<div [ngClass]="{
  'row':true,
  'mb-3':hint === undefined,
  'mb-2':hint !== undefined}">

  <div class="col-12 col-md-5 font-weight-bold">
    <span>
      {{label}}
    </span>
    <span class="d-md-none font-weight-medium">
      <br>{{value}}
    </span>
  </div>
  <div class="col-md-7 d-none d-md-block">
    {{value}}
  </div>
</div>
<div *ngIf="hint !== undefined" class="row mb-3 hint">
  <div class="col-12">
    {{hint}}
  </div>
</div>

