import {Component, Input} from '@angular/core';
import {NgxPopperjsTriggers} from 'ngx-popperjs';

@Component({
  selector: 'ucs-highlight-equipment',
  templateUrl: './highlight-equipment.component.html'
})
export class HighlightEquipmentComponent {

  @Input() highlightEquipments: HighlightEquipmentDto[];
  @Input() showEtbLongTimePending = false;

  equipmentList = [{equipment: 'XENON_LIGHT', shape: 'light_xenon', key: 'offering.tooltip.xenon'},
    {equipment: 'LED_LIGHT', shape: 'light_LED', key: 'offering.tooltip.led'},
    {equipment: 'FOUR_WD', shape: 'awd', key: 'offering.tooltip.awd'},
    {equipment: 'TRAILER_HITCH', shape: 'tow_hitch', key: 'offering.tooltip.tow-hitch'},
    {equipment: 'AUTOMATIC_DRIVE', shape: 'automatic_transmission', key: 'offering.tooltip.automatic'},
    {equipment: 'HEATED_SEATS', shape: 'seat_heating', key: 'offering.tooltip.seat-heating'},
    {equipment: 'LEATHER_SEATS', shape: 'leather', key: 'offering.tooltip.leather-seats'},
    {equipment: 'NAVIGATION_SYSTEM', shape: 'sat_nav', key: 'offering.tooltip.navigation'},
  ];

  hasHighlightEquipment(equipment: string) {
    for (let i = 0; i < this.highlightEquipments.length; i++) {
      if (this.highlightEquipments[i].type === equipment) {
        return true;
      }
    }
    return false;
  }

  protected readonly NgxPopperjsTriggers = NgxPopperjsTriggers;
}
