<div class="row">
  <ucs-price-display class="d-inline-block mt-2" data-cy="highest-bid-net-and-currency"
                     [heading]="offerType === 'BUYNOW' ? 'vehicle-footer.buynow-price' :
                     currentPrice?.net === startPrice?.net ?
                     offerType === 'ENFORCED_AUCTION' ? 'vehicle-footer.starting-price' :
                      'vehicle-footer.initial-bid' : 'vehicle-footer.highest-bid'"
                     [priceDto]="currentPrice"></ucs-price-display>
</div>

<div class="row mb-2">
  <ucs-calc-icon class="col-auto" *ngIf="vatType === 'STANDARD' && (userState.isAustrian || channel === 'ALL_UC')" [type]="vatType === 'STANDARD' ? 'NOK' : 'OK'" [text]="'icon.vat.' + vatType"></ucs-calc-icon>
  <ucs-calc-icon class="col-auto" *ngIf="(userState.isAustrian || isNovaInfoGrossPriceEnabled) && (offerType !== 'ENFORCED_AUCTION')" [type]="novaPaid ? 'OK': 'NOK'"
                 [text]="'icon.nova.' + (novaPaid ? 'OK': 'NOK')"></ucs-calc-icon>
</div>
