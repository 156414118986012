/**
 * This component displays the general terms and conditions (GTC)
 */
import {Component, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {UserService} from '../../service/user.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import * as UserActions from '../../store/user/user.actions';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../store/app.reducers';
import {TranslateService} from '@ngx-translate/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {GTC_FILENAMES_MAP} from '../../shared.module';
import {ToastAlertService} from '../../service/toast-alert.service';


@Component({
  selector: 'ucs-gtc-message',
  templateUrl: './gtc-message.component.html',
  styleUrls: ['./gtc-message.component.scss']
})
export class GtcMessageComponent implements OnChanges, OnDestroy {
  @Input() gtcs: GtcDto[];
  @Input() userInfo: UserInfoDto;

  checkbox: boolean;
  form: UntypedFormGroup;

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(private userService: UserService,
              private store: Store<fromRoot.AppState>,
              private alert: ToastAlertService,
              private translate: TranslateService,
              private formBuilder: UntypedFormBuilder) {
    this.form = this.formBuilder.group({});
  }

  ngOnChanges(changes: SimpleChanges){
    //check if gtcs have been changed and update formControls on change
    for (const propName in changes) {
      if (propName === 'gtcs') {
        this.form.controls = {};
        this.gtcs.forEach(
          gtc => this.form.addControl(gtc.channel + '-' + gtc.country,  new UntypedFormControl(false, Validators.requiredTrue))
        );
      }
    }
  }

  /**
   * Accept all general terms and conditions of one channel
   */
  acceptAll() {
    this.gtcs.forEach(gtc => {
      this.userService.updateGtc(gtc.id).pipe(takeUntil(this.unsubscribe))
        .subscribe(result => {
          this.store.dispatch(new UserActions.UpdateUserInfoAction());
          this.alert.success(this.translate.instant('gtc.dialog.success'));
        },
        err => {
          this.alert.danger(this.translate.instant('gtc.dialog.error'));
        });
    });
  }

  /**
   * Unsubscribe from Observables when component is destroyed to prevent memory leaks
   */
  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getGTCFile(distributionChannel: DistributionChannel, country: Country): string[] {
    let result = GTC_FILENAMES_MAP.get(distributionChannel).get(country);
    if(result === undefined) {
      return ['GTC_Placeholder_en.pdf'];
    }
    return result;
  }
}
