/**
 * Dialog for bid
 */
import {
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {OfferService} from '../../service/offer.service';
import {TranslateService} from '@ngx-translate/core';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../store/app.reducers';
import {isEnforcedAuctionOfferDto, isVehicleItemBaseDto} from '../../misc/typeguard';
import {first} from 'rxjs/operators';
import {SystemSettingsService} from '../../service/system-settings.service';
import {State} from '../../store/user/user.reducers';
import {TaxService} from '../../service/tax.service';
import {Subject} from 'rxjs';
import {ToastAlertService} from '../../service/toast-alert.service';
import {NgxPopperjsPlacements} from 'ngx-popperjs';

declare let $: any;

@Component({
  selector: 'ucs-bid-dialog',
  templateUrl: './bid-dialog.component.html',
  styleUrls: ['./bid-dialog.component.scss']
})
export class BidDialogComponent implements OnInit, OnChanges, DoCheck, OnDestroy {
  @Input() offer: AuctionOfferDto | EnforcedAuctionOfferDto;
  @Input() logOfferAccess = true;
  @Output() isOfferExpired = new EventEmitter<boolean>();
  auctionBid$ = this.store.select(fromRoot.getAuctionBid);
  bidRequest = {} as BidRequestDto;
  validationError: string;
  grossPriceValidationError: string;
  displayMinimumPurchasePrice: boolean;
  minimumStep: number;
  minimumStepGross: number;
  bidPrice: number;
  bidPriceGross: number;
  comment: string;
  custodyDealer: DealerDetailDto;
  vehicleItem: VehicleItemBaseDto;
  extraVehicleTaxRate: number;
  inputError: boolean;
  isExpired: boolean;
  oldMinimumBid: number;
  userState: State;
  isNovaInfoGrossPriceEnabled: boolean;
  isShowNovaRefundEnabled: boolean;
  isShowMinimumPurchasePriceEnabled = false;
  novaRefundTotalSum: number;
  isEnforcedAuction = false;
  @ViewChild('bidButton') bidButton: ElementRef;

  private unsubscribe: Subject<void> = new Subject<void>();
  isThermalWindowAffectedEnabled: boolean;
  showThermalWindowAffected: boolean;

  constructor(private offerService: OfferService,
              private alertService: ToastAlertService,
              private translate: TranslateService,
              private store: Store<fromRoot.AppState>,
              private systemSettingsService: SystemSettingsService,
              private taxService: TaxService) {
  }

  ngOnInit() {
    this.auctionBid$.subscribe((bid) => {
      if (bid) {
        this.initData();
      }
    });

    this.store
      .select(fromRoot.getUserState)
      .pipe(first())
      .subscribe(userState => {
        if (userState) {
          this.userState = userState;
        }
      });


    this.systemSettingsService
      .isSystemFeatureActivatedForAnyChannel('THERMAL_WINDOW_AFFECTED')
      .subscribe(value => {
        this.isThermalWindowAffectedEnabled = value;
        if (this.isThermalWindowAffectedEnabled) {
          this.systemSettingsService.getSystemFeatureSettingForChannel('THERMAL_WINDOW_AFFECTED.thermal_window_affected_step_one', this.offer.channel.data)
            .pipe(first())
            .subscribe(stringValue => {
              this.showThermalWindowAffected = JSON.parse(stringValue);
            });
        }
      });

    this.systemSettingsService
      .isSystemFeatureActivatedForChannel('SHOW_NOVA_INFO_GROSS_PRICE_TO_FOREIGN_DEALERS', this.offer.channel.data)
      .pipe(first())
      .subscribe(enabled => {
        this.isNovaInfoGrossPriceEnabled = enabled;
      });

    this.systemSettingsService
      .isSystemFeatureActivatedForChannel('SHOW_NOVA_REFUND', this.offer.channel.data)
      .pipe(first())
      .subscribe(enabled => {
        this.isShowNovaRefundEnabled = enabled;
      });

    this.isEnforcedAuction = isEnforcedAuctionOfferDto(this.offer);
  }

  evaluatePriceInputDisabled(): boolean {
    if (this.isExpired === true) {
      return true;
    } else {
      return this.bidRequest.bidType === 'REGULAR';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.novaRefundTotalSum = null;
    for (const vehicleItem of (<VehicleItemBaseDto[]>this.offer.items)) {
      if (vehicleItem.extraTax.paid) {
        vehicleItem.extraTax.refund = vehicleItem.extraTax.refund === null ? 0 : vehicleItem.extraTax.refund;
        this.novaRefundTotalSum = this.novaRefundTotalSum + vehicleItem.extraTax.refund;
      }
    }

    if (isVehicleItemBaseDto(this.offer.items[0])) {
      this.vehicleItem = <VehicleItemBaseDto>this.offer.items[0];
      this.extraVehicleTaxRate = this.vehicleItem.extraTax.rate;
    }
    this.initData();
  }

  ngDoCheck() {
    if (this.offer.minimumBid.net !== this.oldMinimumBid) {
      this.initData();
    }
  }

  /**
   * Add selected dealer to local variable
   * @param $event
   */
  dealerSelected($event) {
    this.custodyDealer = $event;
  }

  userFocus($event) {
    this.bidRequest.userFocus = <PriceType>$event.toUpperCase();
  }

  getInputError($event) {
    this.inputError = $event;
  }

  /**
   * On cancel clear error message and entered data
   */
  cancel() {
    this.validationError = undefined;
  }

  /**
   * Bid on the offer
   */
  bid() {
    this.validationError = this.validateBid();

    if (!this.validationError && this.bidPrice <= 999999999999) {
      this.issueBid();
    }
  }

  onRegularBidSelect() {
    this.initData(); // recalculates and sets minimum step bid
  }

  private validateBid(): string {
    let validationError = undefined;

    if (!this.bidPrice) {
      validationError = 'bid-dialog.validation.missing-bid';
    } else {
      if (('' + this.bidPrice).match('^[0-9]+$') === null) {
        validationError = 'bid-dialog.validation.bid-no-whole-number';
      }
      if (this.bidPrice < (this.offer.minimumBid.net)) {
        validationError = 'bid-dialog.validation.bid-lower-than-minimum';
      }
      if (this.offer.maximumBid !== null) {
        if (this.bidPrice < (this.offer.maximumBid.net)) {
          validationError = 'bid-dialog.validation.bid-lower-than-bidagent';
        }
      }
    }

    return validationError;
  }

  private issueBid(): void {
    this.bidRequest.logOfferAccess = this.logOfferAccess;
    this.bidRequest.netPrice = this.bidPrice;
    // remove gross price if bid on a non national sale
    this.bidRequest.grossPrice = this.offer.nationalSale ? this.bidPriceGross : null;
    this.offerService.bidOnOffer(this.offer.id, this.bidRequest).subscribe(res => { // Success
      $('#confirmBidModal' + this.offer.id).modal('hide');
      this.alertService.success(this.translate.instant('bid.dialog.bid.successful'));
    }, (err) => {
      if (err.status === 409) {
        this.isOfferExpired.emit(true);
        this.isExpired = true;
      }
    });
  }

  private initData(): void {
    this.systemSettingsService
      .isSystemFeatureActivatedForChannel('SHOW_MINIMUM_PURCHASE_PRICE', this.offer.channel.data)
      .pipe(first())
      .subscribe(enabled => {
        this.isShowMinimumPurchasePriceEnabled = enabled;
      });
    this.oldMinimumBid = this.offer.minimumBid.net;
    // if there has been a bid on this offer (and thus there is a highestBid), we can calculate minimumStep range
    // e.g. 2000 (minimumStep) = 25000 (minimumBid) - 23000 (highestBid)
    if (this.offer.highestBid) {
      this.minimumStep = this.offer.minimumBid.net - this.offer.highestBid.net;
      this.minimumStepGross = this.offer.minimumBid.gross - this.offer.highestBid.gross;
    }

    this.bidRequest.bidType = 'REGULAR';
    // if a maximum bid is defined, propose the new one with a minimumStep
    if (this.offer.maximumBid !== null && this.offer.maximumBid !== undefined
      && (this.offer.maximumBid.net + this.minimumStep) > this.offer.minimumBid.net) {
      this.bidPrice = (this.offer.maximumBid.net + this.minimumStep);
      if (!this.offer.maximumBid.gross || !this.minimumStepGross) {
        this.calculateAndSetBidPriceGross();
      } else {
        this.bidPriceGross = this.offer.maximumBid.gross + this.minimumStepGross;
      }
    } else { // if no maximum bid is defined, the maximum bid is the same as the next regular minimumBid
      this.bidPrice = this.offer.minimumBid.net;
      if (!this.offer.minimumBid.gross) {
        this.calculateAndSetBidPriceGross();
      } else {
        this.bidPriceGross = this.offer.minimumBid.gross;
      }
    }
    // set if we can display the minimum purchase price
    this.setDisplayMinimumPurchasePrice();
  }

  private setDisplayMinimumPurchasePrice() {
    if (this.offer.minimumPurchasePrice !== undefined
      && (this.offer.channel.data === 'PIA'
        || (!this.isShowMinimumPurchasePriceEnabled && this.offer.channel.data === 'ALL_UC')
        || (this.isShowMinimumPurchasePriceEnabled
          && (<AuctionOfferDto>this.offer).showMinimumPurchasePrice))) {
      this.displayMinimumPurchasePrice = true;
    }
  }

  private calculateAndSetBidPriceGross(): void {
    if (this.offer.nationalSale) {
      const vehicle = this.offer.items[0] as VehicleItemBaseDto;
      this.taxService.getGrossPriceWithExtraTax(this.bidPrice, vehicle.vatType.data,
        vehicle.extraTax.paid ? 0 : vehicle.extraTax.rate, vehicle.extraTax.amount,
        <Country>this.userState.userInfo.country, this.offer.channel.data
      ).subscribe(grossValue => {
        this.bidPriceGross = grossValue;
      });
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  onFooterKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.bidButton.nativeElement.click();
    }
  }

  protected readonly NgxPopperjsPlacements = NgxPopperjsPlacements;
}
