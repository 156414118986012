<div class="countdown w-100 {{ showBidBuyButtons ? 'text-lg-end text-md-center float-lg-end' : '' }}
{{ footerStatus === 'AUCTION_ACTIVE' || (footerStatus === 'AUCTION_EXTENDED' && hideFooterStatus) ? 'mb-2' : '' }}
{{ offerType === 'BUYNOW' ? 'invisible': '' }}">
  <div *ngIf="showCountdown">
    <ucs-countdown [expirationDate]="expirationDate" [small]="true"></ucs-countdown>
  </div>
</div>
<div [ngClass]="{'float-md-end': showBidBuyButtons}">

  <a *ngIf="userState.isCustodianPb
            && ['FOLLOWUP_PROPOSAL_NEW', 'FOLLOWUP_PROPOSAL_ACCEPTED','FOLLOWUP_PROPOSAL_REJECTED' ].includes(footerStatus);
            else defaultOfferStatus"
     class="offer-link cursor-pointer"
     [routerLink]="'/maintenance/vehicle/' + vehicle.id"
     [fragment]="'followup-section'">
    <strong>{{('vehicle-footer.' + footerStatus) | translate}}</strong>
  </a>

  <ng-template #defaultOfferStatus>
    <span *ngIf="footerStatus !== 'AUCTION_ACTIVE' && ((footerStatus === 'AUCTION_EXTENDED' && !hideFooterStatus) || (footerStatus !== 'AUCTION_EXTENDED'))"
          [class]="'d-flex justify-content-md-start justify-content-center status-text ' + footerStatus">
      {{ disableBidding ? (('vehicle-footer.AUCTION_EXPIRED') | translate) : (('vehicle-footer.' + footerStatus) | translate) }}
    </span>

    <ng-container *ngIf="['AUCTION_FINISHED','AUCTION_EXPIRED','BUYNOW_FINISHED'].includes(footerStatus)
                          && ((['AUCTION','ENFORCED_AUCTION'].includes(offerType) && expirationDate) || (offerType === 'BUYNOW' && purchaseDate))">
      <div class="status-text d-flex justify-content-md-start justify-content-center">
        <span class="pt-1 status-date">{{ (offerType === 'BUYNOW' ? purchaseDate : expirationDate) | date : 'dd.MM.yyyy - HH:mm:ss'}}</span>
        <button *ngIf="!!expectedExpirationDate" class="btn-popover" data-html="true"
                [popper]="expectedExpiration"
                [popperTrigger]="NgxPopperjsTriggers.hover"
                [popperPlacement]="NgxPopperjsPlacements.BOTTOM"
                [popperApplyClass]="'popper-icon-content'">
          <ucs-icon [shape]="'info'" [size]="16" [class]="'blue'"></ucs-icon>
        </button>
        <popper-content #expectedExpiration>
          <div class="popper-icon-content">{{'offer-status-buy-bid-expected-expiration' | translate }}
            <br/>{{ expectedExpirationDate| date : 'dd.MM.yyyy - HH:mm:ss'}}
          </div>
        </popper-content>
      </div>
    </ng-container>

    <!--Angebot anzeigen (Listenansicht)-->
    <div *ngIf="showOfferLink &&
              (!quickLinkToDirectSaleDealerIds || !currentDealerIsDirectSaleDealer || !userState.canBuyOffersPb)
              && !userState.isCustodianPb"
         class="d-flex justify-content-start mb-2 offer-link">
      <a (click)="linkToOfferClicked.emit()" [routerLink]="'/maintenance/offer/' + offerId">
        <strong>{{ 'vehicle-footer.show-offer' | translate }}</strong></a>
    </div>
    <div *ngIf="showOfferLink && vehicle.offerDataDto.directSaleDealerIds && quickLinkToDirectSaleDealerIds && currentDealerIsDirectSaleDealer && userState.canBuyOffersPb" class="offer-link d-flex justify-content-end">
      <a (click)="linkToOfferClicked.emit()" [routerLink]="'/offer/' + offerId"
         (click)="storeBacklink()">
        <strong>{{ 'vehicle-footer.show-directSaleOffer' | translate }}</strong></a>
    </div>

    <!-- showing both the offer link and the bid-buy buttons would disturb the design -->
    <ng-container *ngIf="showBidBuyButtons && !showOfferLink">
      <ucs-bid-buy [offerId]="offerId"
                   [offerType]="offerType"
                   data-cy="bid-buy-component"
                   [inputPrice]="inputPrice"
                   [disabled]="disableBidding"
                   [thermalWindowAffected]="thermalWindowAffected"
                   class="ps-0 w-100 d-md-block d-flex justify-content-center">
      </ucs-bid-buy>
    </ng-container>
  </ng-template>
</div>
