<ng-template #notificationModalTemplate let-c="close">

  <div class="modal-header justify-content-start notification">
    <ucs-icon [shape]="'info'" [size]="24" [class]="'white'" class="me-2 mb-1" ></ucs-icon>
    <h5 class="modal-title">{{ currentNotification?.notificationTitle }}</h5>
  </div>

  <div class="modal-body">
    <div [innerHTML]="currentNotification?.notificationContent"></div>
    <br>
    <div *ngIf="currentNotification?.needsUserApproval">
      <input [(ngModel)]="notificationApproved" (click)="toggleNotificationApproved()" type="checkbox"
             id="{{currentNotification?.id}}">
      <label for="{{currentNotification?.id}}">
        {{'ucs.user-notifications.modal.message-approve' | translate }}
      </label>
    </div>
    <br>

    <strong>
      {{'ucs.user-notifications.modal.message-concerns' | translate }}
    </strong>
      <span *ngIf="currentNotification.targetChannels.length === 5; else channelList" >
      {{ 'ucs.user-notifications.modal.message-all-channels' | translate}}
      </span>
      <ng-template #channelList>
        <span *ngFor="let channel of currentNotification.targetChannels ; let i = index">
            &nbsp;{{('ucs.' + (channel | lowercase) | translate) + (i !== currentNotification.targetChannels.length-1 ? ',' : '')}}
          </span>
      </ng-template>

  </div>

  <div class="modal-footer">
    <button id="btn-approve-notification" type="button" class="btn btn-primary button-ok"
            (click)="approveNotification()" [disabled]="currentNotification.needsUserApproval && !notificationApproved">
      OK
    </button>
  </div>
</ng-template>
