<form #manualInputDealer="ngForm">
  <div class="col-12 px-3 mt-1">
    <strong>{{'manual-dealer-input.name' | translate}}</strong>
    <input [(ngModel)]="externalDealer.name" (ngModelChange)="formChanged()" name="name"
           required
           class="col-12 mt-1 form-control">
    <div
      *ngIf="manualInputDealer.form.controls.name?.invalid && manualInputDealer.form.controls.name?.touched"
      class="error">{{ 'manual-dealer-input.error.missing-name' | translate }}
    </div>

    <strong class="mt-3 d-inline-block">{{'manual-dealer-input.address' | translate}}</strong>
    <input [(ngModel)]="externalDealer.address" (ngModelChange)="formChanged()"
           name="address" required
           class="col-12 form-control">
    <div
      *ngIf="manualInputDealer.form.controls.address?.invalid && manualInputDealer.form.controls.address?.touched"
      class="error">{{ 'manual-dealer-input.error.missing-address' | translate }}
    </div>

    <div class="col-12 ps-0 pe-0 mt-3 mb-3">
      <div class="row">
        <div class="col-6 pe-2">
          <strong>{{'manual-dealer-input.zip' | translate}}</strong>
          <input [(ngModel)]="externalDealer.zipCode" (ngModelChange)="formChanged()"
                 name="zip" required
                 class="col-12 mt-1 form-control">
          <div
            *ngIf="manualInputDealer.form.controls.zip?.invalid && manualInputDealer.form.controls.zip?.touched"
            class="error">{{ 'manual-dealer-input.error.missing-zip' | translate }}
          </div>
        </div>
        <div class="col-6 ps-2">
          <strong>{{'manual-dealer-input.city' | translate}}</strong>
          <input [(ngModel)]="externalDealer.city" (ngModelChange)="formChanged()"
                 name="city" required
                 class="col-12 mt-1 form-control">
          <div
            *ngIf="manualInputDealer.form.controls.city?.invalid && manualInputDealer.form.controls.city?.touched"
            class="error">{{ 'manual-dealer-input.error.missing-city' | translate }}
          </div>
        </div>
      </div>
    </div>

    <strong>{{'manual-dealer-input.country' | translate}}</strong>
    <select class="col-12 mt-1 mb-3 form-select"
            [(ngModel)]="externalDealer.country" (ngModelChange)="formChanged()"
            name="country" required>
      <option *ngFor="let country of masterData?.countries" [value]="country.countryCode">
        {{ country.countryText }}
      </option>
    </select>
    <div *ngIf="manualInputDealer.form.controls.country?.invalid && manualInputDealer.form.controls.country?.touched"
         class="error">{{ 'manual-dealer-input.error.missing-country' | translate }}
    </div>
  </div>
</form>
