<div class="detail-container row">
  <nav class="col-auto mx-auto col-lg mx-lg-0">

    <ul *ngIf="paginationPages?.length > 0" class="pagination">
      <li class="page-item">
        <button (click)="selectPage(pageable.pageNumber - 1)"
                [disabled]="pageable.pageNumber === 0"
                [ngClass]="{'disabled': pageable.pageNumber === 0}"
                class="page-link">
          <ucs-icon [shape]="'angle_left'" [class]="'blue'" [size]="24"></ucs-icon>
          <span class="sr-only">{{'ucs.pagination.previous' | translate}}</span>
        </button>
      </li>
      <li *ngIf="!getPagesSubSelection().includes(getFirstPage())">
        <button (click)="selectPage(0)" [ngClass]="{'selected': 0 === pageable.pageNumber}"
                class="page-link mx-1" href="#">
          <strong> 1 </strong>
        </button>
      </li>
      <li *ngIf="!getPagesSubSelection().includes(getFirstPage())" class="align-items-center d-flex">
        <strong class="mx-1 align-bottom dots">...</strong>
      </li>
      <li *ngFor="let page of paginationPages.length > 5 ? getPagesSubSelection(): paginationPages" class="page-item">
        <button (click)="selectPage(page)" [ngClass]="{'selected': page === pageable.pageNumber}"
                class="page-link mx-1" href="#"><strong>{{ page + 1 }}</strong></button>
      </li>
      <li *ngIf="!getPagesSubSelection().includes(getLastPage())" class="align-items-center d-flex">
        <strong class="mx-1 dots">...</strong>
      </li>
      <li *ngIf="!getPagesSubSelection().includes(getLastPage())">
        <button (click)="selectPage(paginationPages.length - 1)" [ngClass]="{'selected': paginationPages.length - 1 === pageable.pageNumber}"
                class="page-link mx-1" href="#">
          <strong>{{ paginationPages.length }}</strong>
        </button>
      </li>
      <li *ngIf="paginationPages" class="page-item">
        <button (click)="selectPage(pageable.pageNumber + 1)"
                [disabled]="pageable.pageNumber === paginationPages[paginationPages.length - 1]"
                [ngClass]="{'disabled': pageable.pageNumber === paginationPages[paginationPages.length - 1]}"
                class="page-link">
          <span class="sr-only">{{'ucs.pagination.next' | translate}}</span>
          <ucs-icon [shape]="'angle_right'" [class]="'blue'" [size]="24"></ucs-icon>
        </button>
      </li>
    </ul>
  </nav>

  <div *ngIf="paginationPages?.length > 0" class="w-100 d-lg-none"></div>

  <div *ngIf="paginationPages?.length > 0" class="text-lg-right dropdown m-1 col-auto mx-auto">
    <span>{{ 'ucs.pagination.items-per-page' | translate }}</span>
    <button class="btn btn-light dropdown-toggle small ms-2" type="button" data-bs-toggle="dropdown">
      <span class="me-1">{{ pageable.pageSize }}</span>
    </button>
    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
      <button (click)="selectPaginationDropdownState(state)" *ngFor="let state of paginationDropdownStates"
              class="dropdown-item">{{ state }}
      </button>
    </div>
  </div>

</div>
