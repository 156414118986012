import {Inject, Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {APP_CONFIG} from '../misc/inject-tokens';
import {AppConfig} from '../model/app-config.model';

@Injectable({
  providedIn: 'root'
})
export class SurveyorMaintenanceService {

  constructor(private http: HttpClient, @Inject(APP_CONFIG) private config: AppConfig) {}

  getSurveyorsByDealerId(dealerId: number) {
    return this.http.get<SurveyorDto[]>(
      this.config.salesApiUrl + '/maintenance/surveyor/surveyors/dealer?dealerId=' + dealerId
    );
  }

  updateSurveyorsAssignment(dealerId: number,updatedList: SurveyorAssignmentUpdateDto[]) {
    return this.http.put(
      `${this.config.salesApiUrl}/maintenance/surveyor/surveyors/${dealerId}/assign`, updatedList);
  }

  getAllSurveyorContractTypes() {
    return this.http.get<SurveyContractTypeDto[]>(
      this.config.salesApiUrl + '/maintenance/surveyor/contract-types'
    );
  }

  getSurveyorContractTypeInfo(contractType: ContractSpecialType) {
    return this.http.get<SurveyContractTypeDto>(
      this.config.salesApiUrl + '/maintenance/surveyor/contract-types/' + contractType
    );
  }

  updateSurveyorContractTypes(contractTypes: SurveyContractTypeDto[]) {
    return this.http.put(
      this.config.salesApiUrl + '/maintenance/surveyor/contract-types', contractTypes
    );
  }

  getAllSurveyorsForUserCountry() {
    return this.http.get<SurveyorDto[]>(this.config.salesApiUrl + '/maintenance/surveyor/surveyors');
  }

  getSurveyorPriceInfo(surveyorId: number) {
    return this.http.get<SurveyorPriceInfoDto>(this.config.salesApiUrl + '/maintenance/surveyor/' + surveyorId + '/price-info');
  }

  updateSurveyorPriceInfo(activeSurveyorPriceList: SurveyorPriceInfoTempDto[]) {
    return this.http.put(this.config.salesApiUrl + '/maintenance/surveyor/price-info', activeSurveyorPriceList);
  }
}
