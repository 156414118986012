<ng-container *ngIf="support">
  <div *ngFor="let tag of tags" class="mb-3">
    <input type="checkbox"
           [checked]="activeTags.includes(tag)"
           [id]="'view-settings-' + tag"
           (click)="toggleTag(tag)"
           [class]="'blue me-1'"/>
    <label [for]="'view-settings-' + tag">{{ 'view-settings.' + tag | translate }}</label>
  </div>
  <p><button tabindex="1" class="btn btn-tertiary" (click)="resetToDefaults()">{{ 'view-settings.reset-to-defaults' | translate }}</button></p>
  <p>{{ errorMessage | translate }}</p>
</ng-container>
<ng-container *ngIf="!support">
  <p>{{ 'view-settings.no-support' | translate }}</p>
</ng-container>
