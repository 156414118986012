import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[ucsNumberFormat]'
})
export class NumberFormatDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    const cleanedValue = value.replace(/[^\d]/g, '');
    const wholeNumber = cleanedValue.slice(0, -3);

    const formattedWholeNumber = wholeNumber.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    this.el.nativeElement.value = formattedWholeNumber + (formattedWholeNumber.length > 0 ? '.' : '') + cleanedValue.slice(-3);
  }
}
