import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {debounceTime, distinctUntilChanged, skip, takeUntil} from 'rxjs/operators';
import {UntypedFormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {SystemSettingsService} from '../../../service/system-settings.service';
import {ucsIsNil} from '../../../misc/utils';
import {NgxPopperjsPlacements} from 'ngx-popperjs';

/**
 * This component is uses as a simple, styled <input/>
 */
@Component({
  selector: 'ucs-simple-input',
  templateUrl: './search-simple-input.component.html',
  styleUrls: ['./search-simple-input.component.scss']
})
export class SearchSimpleInputComponent implements OnInit, OnDestroy {
  @Input() label: string;
  @Input() placeholder: string;
  @Input() class: string;
  @Input() initialValue: string;
  @Input() selectedValue: string;
  @Input() inputWithSearchImage = false;
  @Input() inputWithInfoImage = false;
  @Input() infoImageText: string;
  @Input() minCharacterCount = 4;
  @Input() allowResetOnEmptyInput: boolean = false;
  @Output() onInput = new EventEmitter<any>();
  @Output() onUnvalidatedInput = new EventEmitter<any>();
  @Output() onKeyUpEnter = new EventEmitter<any>();
  simpleInputFormControl = new UntypedFormControl();
  validationError: boolean;
  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private systemSettingsService: SystemSettingsService) {
  }

  /**
   * initializes value-change listener
   */
  ngOnInit() {
    if (!this.selectedValue && this.initialValue)  {
      this.selectedValue = this.initialValue;
    }
    this.validationError = false;
    this.simpleInputFormControl.valueChanges
      .pipe(
        skip(1), // skip first emit, as we don't need to reload for the initial "undefined" value
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.unsubscribe)
      )
      .subscribe(newValue => this.input(newValue));
  }

  /**
   * unsubscribes subscriptions on destroy
   */
  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  /**
   * Emits the selected item to the parent
   */
  input(value: string): void {
    this.validInput();
    this.onUnvalidatedInput.emit(this.selectedValue);
    if (!this.validationError) {
      this.onInput.emit(this.selectedValue);
    }
  }

  /**
   * Emits the value to the consumers if the events code is 'Enter'
   * @param $event
   */
  keyUpEnter($event): void {
    if ($event && $event.key === 'Enter') {
      this.validInput();
      if (!this.validationError) {
        this.onKeyUpEnter.emit(this.selectedValue);
      }
    }
  }

  search() {
    this.validInput();
    if (!this.validationError) {
      this.onInput.emit(this.selectedValue);
    }
  }

  validInput() {
    if (this.allowResetOnEmptyInput){
      if (!ucsIsNil(this.selectedValue)){
        if (this.selectedValue.trim().length > 0){
          this.validationError = this.selectedValue.trim().length < this.minCharacterCount;
        } else {
          this.validationError = false;
        }
      } else {
        this.validationError = false;
      }

    } else {
      this.validationError = !(!ucsIsNil(this.selectedValue)
        && (this.selectedValue.trim().length >= this.minCharacterCount && this.selectedValue.trim() !== ''));
    }
  }

  /**
   * Clears the selection of the input
   */
  clear() {
    this.selectedValue = undefined;
  }

  /**
   * Resets the validation of the input
   */
  resetValidation(){
    this.validationError = false;
  }

  /**
   * External way to set an validation error
   */
  hasValidationError(){
    this.validationError = true;
  }

  /**
   * Allows overriding the current selection (intended to be used by parent component)
   */
  overrideSelection(value: string) {
    if (value === undefined) { // clear the selection
      this.clear();
    } else { // or set the selected value if not undefined
      this.selectedValue = value;
    }
  }

  protected readonly NgxPopperjsPlacements = NgxPopperjsPlacements;
}
