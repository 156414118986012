<div [popper]="expirationDate | date : 'dd.MM.yyyy HH:mm (z)' "
     [popperTrigger]="NgxPopperjsTriggers.hover"
     [popperPlacement]="NgxPopperjsPlacements.TOP"
     [popperApplyClass]="'countdown-popper-size'"
      class="d-flex justify-content-md-end justify-content-center">
  <ucs-icon class="icon-small-height" [class]="color" [shape]="'time_clock'" [size]="small ? 13 : 14"></ucs-icon>
  <span *ngIf="remainingSeconds && isFutureDate" [class]="countdownClass + (small ? ' small-font' : '')
    + (isOffer ? ' is-offer-style' : '')
    + (isOfferDetail ? ' is-offer-detail-style' : '')
    + (isExternalDealerBidDialog ? ' is-external-dealer-bid-dialog' : '')
    + (isBidDialog ? ' is-bid-dialog-style' : '')">
    <span class="d-inline-flex px-1">{{ 'offer-item.remaining' | translate }}</span>
    <div class="d-inline-flex">
      <span>
        <span *ngIf="showDays">
          {{ remainingDays }}d
        </span>
        <span *ngIf="showHours">
          {{ remainingHours }}h
        </span>
        <span *ngIf="showMinutes">
          {{ remainingMinutes }}min
        </span>
        <span *ngIf="!showHours">
          {{ remainingSeconds }}s
        </span>
      </span>
    </div>
  </span>
  <span class="me-2" [class]="countdownClass + (small ? ' small-font' : '')" *ngIf="isFutureDate === false">
  {{ 'api.type.offerstatus.EXPIRED' | translate }}
  </span>
</div>

