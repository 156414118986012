<div class="row mt-3 mb-2 multiselect-label-row" *ngIf="label">
  <div class="col-12 text">
    <strong>{{label}}</strong>
    <button *ngIf="popoverText"
            type="button"
            class="button-borderless"
            [popper]="popoverText"
            [popperPlacement]="NgxPopperjsPlacements.TOP">
      <ucs-icon [shape]="'info'" [size]="16" [class]="'black me-1'"></ucs-icon>
    </button>
  </div>
</div>

<ng-select
  [items]="aggregatedStates"
  [multiple]="multiselect"
  [searchable]="true"
  (search)="onSearch($event)"
  (add)="updateAggregatedStates($event)"
  (remove)="updateAggregatedStates($event.value)"
  (clear)="deselectAll()"
  [closeOnSelect]="false"
  [clearSearchOnAdd]="!multiselect"
  bindLabel="option"
  placeholder="{{placeholder}}"
  [(ngModel)]="selectedElements"
  (change)="onInput.emit(selectedElements)"
  [readonly]="selectionDisabled">

  <ng-template ng-header-tmp *ngIf="showSelectButtons && multiselect">
    <button (click)="selectAll()" class="btn btn-sm btn-link">{{'search-filter.select-all' | translate}}</button>
    <button (click)="deselectAll()" class="btn btn-sm btn-link">{{'search-filter.deselect-all' | translate}}</button>
  </ng-template>

  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
    <div *ngIf="items.length === 1" class="multi-item-label">
      <span class="ng-value-label">{{items[0].option}}</span>
    </div>
    <div class="multi-item-label" *ngIf="items.length > 1">
      <span class="ng-value-label">{{items.length}} {{'search-filter.selected' | translate}} </span>
    </div>
  </ng-template>
  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
    <input id="item-{{index}}" type="checkbox" class="checkbox-primary checkbox-item"
           [(ngModel)]="item$.selected"/> {{item.option}}
  </ng-template>
</ng-select>

