import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {isLocationDto, isValueAggregationEntry} from '../../../misc/typeguard';
import {TranslateService} from '@ngx-translate/core';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../store/app.reducers';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {State} from '../../../store/user/user.reducers';
import {NgxPopperjsPlacements} from 'ngx-popperjs';

/**
 * This component encapsulates a ng-select component from https://github.com/ng-select/ng-select
 */
@Component({
  selector: 'ucs-search-multi-select',
  templateUrl: './search-multi-select.component.html',
  styleUrls: ['./search-multi-select.component.scss']
})
export class SearchMultiSelectComponent implements OnInit, OnChanges, OnDestroy {
  @Input() id: string;
  @Input() label: string;
  @Input() states: any[];
  @Input() placeholder: string;
  @Input() popoverText: string;
  @Input() selectionDisabled: boolean;
  @Input() multiselect = true;
  @Output() onSelected = new EventEmitter<any>();
  @Output() onInput = new EventEmitter<any>();


  //Specific input only used for model and modelVariant multiselect
  @Input() modelOrVariantSearchParams: any;

  private unsubscribe: Subject<void> = new Subject<void>();
  userState: State;

  selectableElements = [];
  @Input() selectedElement: string;
  @Input() selectedModel: string;
  @Input() selectedModelVariant: string;
  selectedElements = [];
  aggregatedStates = [];
  showSelectButtons: boolean = false;

  constructor(private store: Store<fromRoot.AppState>,
              private translationService: TranslateService) {
  }

  ngOnInit() {
    this.store.pipe(select(fromRoot.getUserState))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(userState => {
        if (userState) {
          this.userState = userState;
          this.translationService.use(userState.local);
        }
      });

    this.initAggregatedStates();
    if (!!this.selectedElement && !this.multiselect) {
      this.selectedElements = this.aggregatedStates.find(e => e.option === this.selectedElement);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initAggregatedStates();
    if (changes['modelOrVariantSearchParams']) {
      this.overrideSelection(this.modelOrVariantSearchParams);
    }
    if (changes['selectionDisabled'] && this.selectionDisabled) {
      this.selectedElements = [];
    }
    if (changes['selectedElement'] && !this.multiselect) {
      if (!!this.selectedElements && 'length' in this.selectedElements) {
        this.selectedElements = this.selectedElements[0];
      }
      if (!this.selectedElements || this.selectedElement !== this.selectedElements['option']) {
        this.selectedElements = [];
        this.selectedElements = this.aggregatedStates.find(e => e.option === this.selectedElement);
      }
    }
    /*
    When navigating from a saved search, the 'changes['modelOrVariantSearchParams']' function is not triggered because no data has changed.
    To address this issue and re-run the override method, two inputs, 'this.selectedModel' and 'this.selectedModelVariant', have been added
    to force the selection to be overridden again. It was noted that when transitioning from a saved search to an offer,
    only 'states' and 'selectionDisabled' are invoked. However, solely checking for these would trigger the override
    method for any other changes as well, which we want to avoid.
    */
    if (changes['states'] && changes['selectionDisabled'] && this.selectedModel) {
      this.overrideSelection(this.modelOrVariantSearchParams);
    }

    if (changes['states'] && changes['selectionDisabled'] && this.selectedModelVariant) {
      this.overrideSelection(this.modelOrVariantSearchParams);
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  selectAll() {
    this.selectedElements.push(...this.selectableElements);
    this.onSelected.emit(this.selectedElements.map(aggregatedEntry => aggregatedEntry.code));
  }

  deselectAll() {
    this.selectedElements = [];
    this.onSelected.emit(this.selectedElements);
  }

  onSearch(event: any) {
    this.selectableElements = event.items;
    this.showSelectButtons = this.states.length !== this.selectableElements.length;
  }

  /**
   * Initializes the aggregated states for optimized display, also adding an id for easier handling
   */
  private initAggregatedStates(): void {
    if (!this.states) {
      return;
    }
    const myAggregatedStates = [];
    let id = 0;
    if (this.states) {
      for (const entry of this.states) {
        if (isValueAggregationEntry(entry)) {
          let aggregatedValue;
          // check if backend offers an aggregation count and append it, if so
          if (entry.count) {
            aggregatedValue = this.translateEntryText(entry.text) + ' (' + entry.count + ')';
          } else {
            aggregatedValue = this.translateEntryText(entry.text);
          }

          myAggregatedStates.push({id: id, option: aggregatedValue, code: entry.value});
          id++;
        } else {
          console.error('Unknown input state');
        }
      }
      this.aggregatedStates = myAggregatedStates;
      this.selectableElements = this.aggregatedStates;
      this.updateSelectedElements();
    }
  }

  private updateSelectedElements() {
    if (!!this.selectedElements) {
      if (!('length' in this.selectedElements)) {
        this.selectedElements = this.selectableElements.filter(
          selectable => selectable.code === this.selectedElements['code']
        );
        this.selectedElements = this.selectedElements[0];
      } else {
        this.selectedElements = this.selectableElements.filter(
          selectable => this.selectedElements.find(selected => selectable.code === selected.code)
        );
      }
    }
  }

  updateAggregatedStates(event: any): void {
    this.onSelected.emit(this.selectedElements.map(aggregatedEntry => aggregatedEntry.code));
    // re-initialize aggregation states
    this.initAggregatedStates();
  }


  /**
   * Allows overriding the current selection (intended to be used by parent component)
   */
  overrideSelection(searchParams: any) {
    if (!searchParams || searchParams.length === 0) { // if undefined, clear the selection
      this.selectedElements = [];
    } else {
      this.selectedElements = [];
      let item;
      if (isLocationDto(searchParams)) {
        item = this.aggregatedStates.find(element => element.code === searchParams.country);
        //only one country can be selected for defined location
        this.selectableElements = [item];
        this.selectedElements = this.selectableElements;
      } else {
        this.selectedElements = this.aggregatedStates.filter(
          aggregation => searchParams.find(param => aggregation.code === param.toString())
        );
      }
    }
  }

  private translateEntryText(text: string): string {
    if (text === 'UNKNOWN') {
      return this.translationService.instant('api.type.emission-class.UNKNOWN');
    }
    return text;
  }

  protected readonly NgxPopperjsPlacements = NgxPopperjsPlacements;
}
