import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../store/app.reducers';
import {Subject} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {first, takeUntil} from 'rxjs/operators';
import {SurveyMaintenanceService} from '../../service/survey-maintenance.service';
import {SystemSettingsService} from '../../service/system-settings.service';
import {ToastAlertService} from '../../service/toast-alert.service';
import {NgxPopperjsPlacements, NgxPopperjsTriggers} from 'ngx-popperjs';
import {SurveyorMaintenanceService} from '../../service/surveyor-maintenance.service';

@Component({
  selector: 'ucs-survey-request',
  templateUrl: './survey-request.component.html',
  styleUrls: ['./survey-request.component.scss']
})

/**
 * Dialog component for survey request management
 */
export class SurveyRequestComponent implements OnInit, OnChanges, OnDestroy {

  @Input() vehicleId: number;
  @Input() dealerId: number;
  @Input() licensePlate: string;
  @Input() surveyRequestId: number;
  @Input() channel: DistributionChannel;
  @Input() userCountry: string;
  @Input() isPBadmin: boolean;
  @Input() contractSpecialType: ContractSpecialType;
  @Output() surveyUpdated = new EventEmitter<void>();
  surveyors: SurveyorDto[];
  surveyCreation: SurveyCreationDto;
  selectedSurveyor: SurveyorDto;
  showAllSurveyors: boolean;
  surveyRequest: SurveyDto;
  infosFromAudanet: boolean;
  isSurveyButtonDisabled = true;
  isCustodyDealerMaintenanceEnabled: boolean;
  hasAssignedSurveyors: boolean;
  contractTypeInfo: SurveyContractTypeDto;
  isUserAT: boolean;
  isSurveyButtonHidden: boolean = false;
  isSurveyMaintenanceEnabled: boolean = false;
  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(private store: Store<fromRoot.AppState>,
              private route: ActivatedRoute,
              private alertService: ToastAlertService,
              private translateService: TranslateService,
              private surveyService: SurveyMaintenanceService,
              private systemSettings: SystemSettingsService,
              private surveyorService: SurveyorMaintenanceService) {
  }

  ngOnInit() {
    if (this.userCountry === 'AT') {
      this.isUserAT = true;
      this.showAllSurveyors = false;
    } else {
      this.showAllSurveyors = true;
    }
    this.surveyCreation = {} as SurveyCreationDto;
    this.surveyCreation.emailNotification = true;
    this.surveyCreation.comment = '';
    if (this.surveyRequestId) {
      this.showAllSurveyors = true;
      this.surveyService.getSurveyRequest(this.vehicleId, this.surveyRequestId, this.channel).pipe(takeUntil(this.unsubscribe))
        .subscribe((surveyRequest) => {
          this.surveyRequest = surveyRequest;
          this.selectedSurveyor = surveyRequest.surveyor;
          this.surveyors = [];
          this.surveyors.push(surveyRequest.surveyor);
          this.checkInfosFromAudanet(this.surveyRequest);
        });
    } else {
      this.surveyService.getSurveyors(this.vehicleId, this.channel).pipe(takeUntil(this.unsubscribe)).subscribe((surveyors) => {
        this.surveyors = this.sortSurveyorsByContractTypeAndOrdering(surveyors);
        this.hasAssignedSurveyors = this.surveyors.some(surveyor => surveyor.assigned === true);
      });
    }

    this.systemSettings.isSystemFeatureActivatedForAnyChannel('CUSTODY_DEALER_MAINTENANCE')
      .subscribe((isFeatureActive) => {
        this.isCustodyDealerMaintenanceEnabled = isFeatureActive;
      });

    this.systemSettings.isSystemFeatureActivatedForChannel('SURVEY_MAINTENANCE', this.channel)
      .subscribe(isActivated => {
        this.isSurveyMaintenanceEnabled = isActivated;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (propName === 'contractSpecialType' && this.isSurveyMaintenanceEnabled) {
        this.surveyorService.getSurveyorContractTypeInfo(this.contractSpecialType)
          .pipe(first())
          .subscribe(contractTypeInfo => {
            this.contractTypeInfo = contractTypeInfo;
            if (!this.surveyCreation.comment && !this.surveyRequest) {
              this.surveyCreation.comment = this.contractTypeInfo.comment;
            } else {
              this.surveyCreation.comment = this.surveyRequest.commentFromCreation;
            }
          });
      }
    }
  }

  checkInfosFromAudanet(surveyRequest: SurveyDto) {
    if (['COMPLETE', 'CORRECTION_COMPLETE'].includes(surveyRequest.status) &&
      !!this.surveyRequest.feeFromSurvey &&
      !!this.surveyRequest.estimatedValueFromSurvey &&
      !!this.surveyRequest.forecastValueFromSurvey &&
      !!this.surveyRequest.numberOfDamagesFromSurvey &&
      !!this.surveyRequest.sumOfDamagesFromSurvey) {
      this.infosFromAudanet = true;
    }
  }


  openDialog() {
    if (this.isSurveyMaintenanceEnabled) {
      this.surveyorService.getSurveyorContractTypeInfo(this.contractSpecialType)
        .pipe(first())
        .subscribe(contractTypeInfo => {
          this.contractTypeInfo = contractTypeInfo;
          if (!this.surveyCreation.comment && !this.surveyRequest) {
            this.surveyCreation.comment = this.contractTypeInfo.comment;
          } else {
            this.surveyCreation.comment = this.surveyRequest.commentFromCreation;
          }
        });
    }

    this.surveyService.getSurveyors(this.vehicleId, this.channel).pipe(first()).subscribe((surveyors) => {
      this.surveyors = this.sortSurveyorsByContractTypeAndOrdering(surveyors);
    });
  }

  getSurveyors() {
    return this.showAllSurveyors ? this.surveyors : this.surveyors.filter(surveyor => surveyor.assigned);
  }

  setSelectedSurveyor(surveyor: SurveyorDto) {
    this.selectedSurveyor = surveyor;
    this.surveyCreation.surveyorId = surveyor.id;
    this.isSurveyButtonDisabled = false;
  }

  /**
   * Request a survey. This is only possible, when no request is present.
   */
  requestSurvey() {
    this.isSurveyButtonHidden = true;
    if (((this.licensePlate || !this.isUserAT) && this.channel === 'PB') ||
      this.channel === 'PIA' ||
      this.channel === 'DIN_BIL' ||
      this.channel === 'ALL_UC' ||
      this.channel === 'VGRX') {
      this.surveyService.createSurveyRequest(this.vehicleId, this.channel, this.surveyCreation).subscribe((survey) => {
        this.surveyRequest = survey;
        this.surveyRequestId = survey.id;
        this.alertService.success(this.translateService.instant('survey-request.modal.request-success'));
        this.surveyUpdated.emit();
        this.isSurveyButtonDisabled = true;
        this.isSurveyButtonHidden = false;
      }, (err) => {
        this.alertService.danger(this.translateService.instant('survey-request.modal.request-error'));
        this.isSurveyButtonHidden = false;
      });
    } else {
      this.alertService.danger(this.translateService.instant('survey-request.modal.request-error-missing-license-plate'));
      this.isSurveyButtonHidden = false;
    }
  }

  /**
   * Cancel a survey request. This is only possible, when there exists an existing survey request, but no complete
   * survey yet.
   */
  cancelSurveyRequest() {
    delete this.surveyCreation.surveyorId;
    this.surveyService.cancelSurveyRequest(this.vehicleId, this.surveyRequestId, this.channel,
      this.surveyCreation as SurveyModificationDto)
      .subscribe(() => {
        this.alertService.success(this.translateService.instant('survey-request.modal.cancel-success'));
        this.surveyService.getSurveyors(this.vehicleId, this.channel).pipe(takeUntil(this.unsubscribe)).subscribe((surveyors) => {
          this.surveyors = surveyors;
        });
        this.surveyRequest = undefined;
        this.selectedSurveyor = undefined;
        this.surveyCreation.comment = this.contractTypeInfo?.comment ?? '';
        this.surveyCreation.emailNotification = true;
        this.surveyUpdated.emit();
      }, (err) => {
        this.alertService.danger(this.translateService.instant('survey-request.modal.cancel-error'));
      });
  }

  /**
   * Urge a survey request. This is only possible, when there exists an existing survey request, but no complete
   * survey yet.
   */
  urgeSurveyRequest() {
    this.surveyService.urgeSurveyRequest(this.vehicleId, this.surveyRequestId, this.channel).subscribe(() => {
      this.alertService.success(this.translateService.instant('survey-request.modal.urge-success'));
    }, (err) => {
      this.alertService.danger(this.translateService.instant('survey-request.modal.urge-error'));
    });
  }

  /**
   * Discard an already existing survey.
   */
  discardSurvey() {
    this.surveyService.discardSurvey(this.vehicleId, this.surveyRequestId, this.channel).subscribe(() => {
      this.alertService.success(this.translateService.instant('survey-request.modal.discard-success'));
      this.surveyService.getSurveyors(this.vehicleId, this.channel).pipe(takeUntil(this.unsubscribe)).subscribe((surveyors) => {
        this.surveyors = surveyors;
      });
      this.surveyRequest = undefined;
      this.selectedSurveyor = undefined;
      this.surveyCreation.comment = this.contractTypeInfo?.comment ?? '';
      this.surveyCreation.emailNotification = true;
    }, (err) => {
      this.alertService.danger(this.translateService.instant('survey-request.modal.discard-error'));
    });
  }

  /**
   * Request a correction of an already existing survey.
   */
  correctSurvey() {
    const surveyCreation = {...this.surveyCreation};
    delete surveyCreation.surveyorId;
    this.surveyService.correctSurvey(this.vehicleId, this.surveyRequestId, this.channel, surveyCreation).subscribe(() => {
      this.alertService.success(this.translateService.instant('survey-request.modal.correct-success')
        + ' ' + this.translateService.instant('vehicle-maintenance.detail.refresh-page'));
    }, (err) => {
      this.alertService.danger(this.translateService.instant('survey-request.modal.correct-error'));
    });
  }

  sortSurveyorsByContractTypeAndOrdering(surveyors) {
    return surveyors.sort((a, b) => {
      const numA = a.contractSpecialTypes['STANDARD'];
      const numB = b.contractSpecialTypes['STANDARD'];

      if (numA !== undefined && numB !== undefined) {
        return numA - numB;
      } else if (numA !== undefined && numB === undefined) {
        return -1;
      } else if (numA === undefined && numB !== undefined) {
        return 1;
      }
      return 0;
    });
  }


  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  protected readonly NgxPopperjsTriggers = NgxPopperjsTriggers;
  protected readonly NgxPopperjsPlacements = NgxPopperjsPlacements;
  protected readonly Object = Object;
}
