<ng-container *ngIf="offers">

  <!-- optionsList.type 'offer' is used to display results in offer result list -->
  <ng-container *ngIf="optionsList.type === 'offer'">
    <div class="mb-3" *ngFor="let offer of offers" data-cy="offer-list.offer-list-core">
      <!-- show the single offer card or the offer bundle head card -->
      <ucs-sales-single-offer-card *ngIf="offer.items.length === 1"
        [offer]="offer"
        [enableNote]="false"
        data-cy="offer-list-item">
      </ucs-sales-single-offer-card>
      <ucs-sales-bundle-offer-card *ngIf="offer.items.length > 1"
        [offer]="offer"
        [enableNote]="false"
        data-cy="offer-list-item">
      </ucs-sales-bundle-offer-card>
    </div>
  </ng-container>

  <!-- optionsList.type 'offer-small' is used for display in carousel image sliders -->
  <!-- carousel image sliders are used both horizontally (direction = row) and vertically (direction = column) -->
  <div *ngIf="optionsList.type === 'offer-small'"
     [ngClass]="{
     'd-flex': true,
     'flex-column': optionsList.direction === 'column',
     'flex-row': optionsList.direction === 'row'}">
    <ucs-offer-small
      [ngClass]="{
        'mb-2': optionsList.direction === 'column' && i !== 2,
        'mx-3': optionsList.direction === 'row' && i === 1
      }"
      *ngFor="let offer of offers; let i = index"
      [offer]="offer"
      [index]="i">
    </ucs-offer-small>
  </div>

</ng-container>
