import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable, of, Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import * as fromRoot from '../store/app.reducers';
import {Inject, Injectable} from '@angular/core';
import {tap} from 'rxjs/operators';
import {SystemSettingsService} from './system-settings.service';
import {APP_CONFIG} from '../misc/inject-tokens';
import {AppConfig} from '../model/app-config.model';

/**
 * This service handles vehicle-related http calls to the respective REST endpoints
 */
@Injectable()
export class VehicleMaintenanceService {
  private noCacheHeaders = new HttpHeaders({
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
  });

  constructor(private http: HttpClient, private store: Store<fromRoot.AppState>,
              private systemSettingsService: SystemSettingsService,
              @Inject(APP_CONFIG) private config: AppConfig) {
  }

  /**
   * Fetches a vehicle based on its id
   * We always fetch the detail for a single vehicle, never only a single BaseVehicle
   * Same function as getVehicleManagementDetails since the backend always returns a VehicleDetail for this route
   * @returns {Observable<VehicleDetailDto>}: the vehicle matching the provided id
   */
  getVehicle(id: number): Observable<VehicleDetailDto> {
    return this.http.get<VehicleDetailDto>(this.config.salesApiUrl + '/maintenance/vehicles/' + id,
      {headers: this.noCacheHeaders});
  }

  /**
   * Same function as getVehicle
   * @deprecated use the single vehicle from vehicle-maintenance store, not this function
   * @param vehicleId
   */
  getVehicleManagementDetails(vehicleId: number): Observable<VehicleDetailDto> {
    return this.http.get<VehicleDetailDto>(this.config.salesApiUrl + '/maintenance/vehicles/' + vehicleId);
  }

  getVehicleDetailsMasterData(vehicleId: number): Observable<VehicleDetailMasterDataDto> {
    return this.http.get<VehicleDetailMasterDataDto>(
      this.config.salesApiUrl + '/maintenance/vehicles/' + vehicleId + '/masterdata'
    );
  }

  /**
   * Gets the media info for the vehicle with the given vehicle ID.
   * @param vehicleId The ID of the vehicle for which to fetch the documents.
   */
  getVehicleMediaInfo(vehicleId: number): Observable<MediaInfoResultDto> {
    return this.http.get<MediaInfoResultDto>(
      this.config.salesApiUrl + '/maintenance/vehicles/' + vehicleId + '/mediaInfos'
    );
  }

  updateVehicleMaintenanceDetails(vehicleId: number, vehicle: VehicleDetailUpdateDto): Observable<any> {
    return this.http.put<VehicleDetailDto>(this.config.salesApiUrl + '/maintenance/vehicles/' + vehicleId, vehicle);
  }

  reactivateVehicle(vehicleId: number): Observable<any> {
    return this.http.post<VehicleDetailDto>(this.config.salesApiUrl + '/maintenance/vehicles/reactivate/' + vehicleId, null);
  }

  /**
   * Add initial proposal to an vehicle
   * @param {number} vehicleId
   */
  makeInitialProposal(vehicleId: number, initialProposalPost: ProposalMakeDto): Observable<any> {
    return this.http.post(
      this.config.salesApiUrl + '/maintenance/vehicles/' + vehicleId + '/initialProposals', initialProposalPost
    );
  }

  /**
   * Create a pdf from a vehicle and/or not send it per mail
   * @param createPdfDto
   */
  printVehicle(createPdfDto: CreatePdfDto): Observable<any> {
    return this.http.post(
      this.config.salesApiUrl + '/maintenance/vehicles/pdf', createPdfDto, {responseType: 'blob'}).pipe(
      tap((pdf: Blob) => {
        this.downloadPdf(pdf, 'print_' + createPdfDto.vehicleId);
      }));
  }


  downloadPdf(pdf: Blob, filename: string) {
    const dateTime = new Date().toISOString().slice(0, 19);
    const pdfName = filename + '_' + dateTime + '.pdf';

    // Chrome, FF, Edge
    const url = URL.createObjectURL(pdf);
    const a = document.createElement('a');
    a.href = url;
    a.download = pdfName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);

  }

  /**
   * Deletes a initial proposal to an vehicle
   * @param {number} proposalId
   */
  deleteInitialProposal(vehicleId: number, proposalId: number): Observable<any> {
    return this.http.delete(
      this.config.salesApiUrl + '/maintenance/vehicles/' + vehicleId + '/initialProposals/' + proposalId
    );
  }

  /**
   * Update initial proposal to an vehicle
   * @param {number} vehicleId
   */
  updateInitialProposal(vehicleId: number, proposalId: number, initialProposalUpdate: ProposalUpdateDto): Observable<any> {
    return this.http.put(
      this.config.salesApiUrl + '/maintenance/vehicles/' + vehicleId + '/initialProposals/' + proposalId, initialProposalUpdate);
  }

  /**
   * resend initial proposal mail to custody dealers
   * @param {number} vehicleId
   */
  resendInitialProposalEmails(vehicleId: number): Observable<any> {
    return this.http.put(
      this.config.salesApiUrl + '/maintenance/vehicles/resendInitialProposalEmails/' + vehicleId, vehicleId
    );
  }

  /**
   * Create a transport request.
   * @param {TransportRequestCreationDto} transportRequestCreation The data representing the transport request.
   * @returns {Observable<TransportRequestDto>} The newly created transport request
   */
  createTransportRequest(transportRequestCreation: TransportRequestCreationDto): Observable<TransportRequestDto> {
    return this.http.post<TransportRequestDto>(
      this.config.salesApiUrl + '/maintenance/vehicles/transportRequest', transportRequestCreation
    );
  }

  /**
   * Order a test report.
   *
   * @param {number} vehicleId The vehicle's ID
   * @param {boolean} includePictures If pictures shall be included in the resulting PDF
   * @param {boolean} includeComments If comments shall be included in the resulting PDF
   * @returns {Observable<any>} The default HTTP response.
   */
  orderTestReport(vehicleId: number, includePictures: boolean, includeComments: boolean): Observable<any> {
    return this.http.post(this.config.salesApiUrl + '/maintenance/vehicles/' + vehicleId + '/testReports?includePictures=' +
      includePictures + '&includeComments=' + includeComments, null);
  }

  /**
   * Order a test report without eTB license.
   *
   * @param {number} vehicleId The vehicle's ID
   * @returns {Observable<any>} The default HTTP response.
   */
  orderTestReportWithoutEtbLicense(vehicleId: number): Observable<any> {
    return this.http.post(this.config.salesApiUrl + '/maintenance/vehicles/' + vehicleId + '/testReportsWithoutEtbLicense', null);
  }

  /**
   * Gets the given vehicle's test report
   *
   * @param {number} vehicleId The vehicle's ID
   * @returns {Observable<TestReportDto[]>} A list of test reports
   */
  getTestReports(vehicleId: number): Observable<TestReportDto[]> {
    return this.http.get<TestReportDto[]>(this.config.salesApiUrl + '/maintenance/vehicles/' + vehicleId + '/testReports');
  }

  /**
   * Get the test report in PDF format.
   *
   * @param vehicleId The vehicle's ID.
   * @param reportId The report's ID.
   * @param includePictures If pictures shall be included.
   * @param includeComments If comments shall be included.
   */
  getTestReport(vehicleId: number, reportId: number, includePictures: boolean, includeComments: boolean): Observable<any> {
    const httpOptions = {
      'responseType': 'arraybuffer' as 'json'
    };
    return this.http.get<any>(this.config.salesApiUrl + '/maintenance/vehicles/' + vehicleId + '/testReports/' + reportId +
      '?includePictures=' + includePictures + '&includeComments=' + includeComments, httpOptions);
  }

  /**
   *
   * @param {number} vehicleId The vehicle's ID
   * @param {number} reportId The test report's ID
   * @param {boolean} includePictures If pictures shall be included in the resulting PDF
   * @param {boolean} includeComments If comments shall be included in the resulting PDF
   * @returns {Observable<any>}
   */
  attachTestReport(vehicleId: number, reportId: number, includePictures: boolean, includeComments: boolean): Observable<any> {
    return this.http.put(this.config.salesApiUrl + '/maintenance/vehicles/' + vehicleId + '/testReports/' + reportId
      + '?includePictures=' + includePictures + '&includeComments=' + includeComments, null);
  }

  /**
   * Cancel a test report.
   *
   * @param {number} vehicleId The vehicle's ID
   * @param {number} reportId The test report's ID
   * @returns {Observable<any>} The default HTTP response.
   */
  cancelTestReport(vehicleId: number, reportId: number): Observable<any> {
    return this.http.delete(this.config.salesApiUrl + '/maintenance/vehicles/' + vehicleId + '/testReports/' + reportId);
  }

  /**
   * Urge a test report to remind the tester to finish the work.
   *
   * @param {number} vehicleId The vehicle's ID
   * @param {number} reportId The test report's ID
   * @returns {Observable<Object>}
   */
  urgeTestReport(vehicleId: number, reportId: number) {
    return this.http.put(
      this.config.salesApiUrl + '/maintenance/vehicles/' + vehicleId + '/testReports/' + reportId + '/notifications', null
    );
  }

  /**
   * Get a list of logs for the given vehicle.
   *
   * @param {number} vehicleId The ID of the vehicle
   * @returns {Observable<LogDto[]>} An observable of a list of auditlogs
   */
  getLogs(vehicleId: number): Observable<LogDto[]> {
    return this.http.get<LogDto[]>(this.config.salesApiUrl + '/maintenance/vehicles/' + vehicleId + '/logs');
  }

  /**
   * Get a list of open tasks for this dealer.
   *
   * @returns {Observable<OpenTaskListDto>} The list of open tasks
   */
  getOpenTasks(): Observable<OpenTaskListDto[]> {
    return this.http.get<OpenTaskListDto[]>(`${this.config.salesApiUrl}/maintenance/open-tasks`);
  }

  /**
   * Urge the open tasks, given a list of task IDs.
   *
   * @returns {Observable<OpenTaskListDto>} The new list of open tasks
   */
  urgeOpenTasks(taskIds: number[]): Observable<OpenTaskListDto[]> {
    return this.http.post<OpenTaskListDto[]>(`${this.config.salesApiUrl}/maintenance/open-tasks`, taskIds);
  }

  /**
   * Derive the vehicle footer status from the vehicle.
   */
  deriveVehicleFooterStatusFromVehicle(vehicle: VehicleBaseDto): VehicleFooterStatus {
    const dataIncomplete =
      vehicle.missingVehicleDetail ||
      vehicle.missingContactPerson ||
      vehicle.missingPicture ||
      vehicle.missingDocument;

    // data incomplete
    if (dataIncomplete) {
      return 'DATA_INCOMPLETE';
    }
    // data complete & no offer exists
    if (!vehicle.offerDataDto) {
      return 'READY_FOR_OFFER_PHASE';
    }
    // offer exists: check for valid offer states for offer types
    if (['PREPARATION', 'ACTIVE', 'EXTENDED','FINISHED','CANCELLED', 'COPIED', 'DELETED', 'EXPIRED']
      .includes(vehicle.offerDataDto.offerStatus)) {
      // followup proposal status exists
      if (vehicle.offerDataDto.latestFollowUpProposalStatus) {
        return <VehicleFooterStatus>('FOLLOWUP_PROPOSAL_' + vehicle.offerDataDto.latestFollowUpProposalStatus);
      }
      return <VehicleFooterStatus>(vehicle.offerDataDto.offerType + '_' + vehicle.offerDataDto.offerStatus);
    }
    // offer exists: check for valid offer states for offer types
    console.error('Unknown vehicle footer status!');
    return 'UNKNOWN';
  }


}
