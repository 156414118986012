<div class="modal" tabindex="-1" role="dialog" id="openTasksModal">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ 'vehicle-maintenance.open-tasks.title' | translate }}</h5>
        <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"></span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="openTasks && selectedTasks" class="row">
          <div class="col-4 col-md-3 open-task-sidebar">
            <div>
              <input type="checkbox" id="select-all" [checked]="filterCategories.length === selectedCategories.length"
                     (click)="toggleSelectAll()"/>
              <label for="select-all">{{ 'vehicle-maintenance.open-tasks.filter-category.select-all' | translate }}
              </label>
            </div>
            <hr/>
            <div *ngFor="let category of filterCategories" class="mt-2">
              <input type="checkbox" [id]="'filter-' + category" [checked]="selectedCategories.includes(category)"
                     (click)="toggleFilterCategory(category)"/>
              <label
                [for]="'filter-' + category">{{ ('vehicle-maintenance.open-tasks.filter-category.' + category) | translate }}
              </label>
            </div>
          </div>
          <div class="col-8 col-md-9 open-task-list">
            <p *ngIf="openTasks.length < 1">{{ 'vehicle-maintenance.open-tasks.no-open-tasks' | translate }}</p>
            <div *ngFor="let openTask of openTasks; let i = index; let last = last" class="task row col-12">
              <ng-container *ngIf="isTaskVisible(openTask)">
                <div class="row col-12">
                  <span class="index me-4">{{ i + 1 }}.</span>
                  <input type="checkbox" [id]="'task' + i" [checked]="isSelected(openTask.tasks)"
                         (click)="toggleTask(openTask.tasks)">
                  <label class="dealer-name" [for]="'task'+i">{{ openTask.dealerNumber + ' ' + openTask.dealerName }}</label>
                  <button *ngIf="openTask.note" type="button" class="btn-popover" [popper]="openTask.note">
                    <ucs-icon class="ms-2" [shape]="'info'" [class]="'blue'"></ucs-icon>
                  </button>
                </div>
                <div class="row col-12 indent">
                  <div class="col contract-partner">{{ openTask.contractPartner }}</div>
                </div>
                <div class="row col-12 indent">
                  <div class="col col-6">
                    {{ openTask.modelVariant }}
                  </div>
                  <div class="col col-6">
                      <div class="row">
                          <div class="col-4">
                              {{ 'vehicle-maintenance.open-tasks.urgency' | translate }}:
                          </div>
                          <div class="col-4">
                              {{ 'vehicle-maintenance.open-tasks.urged-on' | translate }}
                          </div>
                          <div class="col-4">
                              {{ 'vehicle-maintenance.open-tasks.urged-count' | translate }}
                          </div>
                      </div>
                  </div>
                </div>
                <div class="row col-12 indent">
                  <div class="col col-6">
                    {{ openTask.vin }}
                  </div>
                    <div class="col col-6">
                        <div *ngFor="let task of openTask.tasks" class="row">
                            <popper-content #taskTemplate>
                                {{ 'vehicle-maintenance.open-tasks.created-on' | translate }}
                                : {{ task.created | date: ('ucs.date' | translate) }}<br>
                                <span *ngIf="task.lastUrgeDate">{{ 'vehicle-maintenance.open-tasks.urged-on' | translate }}
                                    : {{ task.lastUrgeDate | date: ('ucs.date' | translate) }}</span>
                            </popper-content>
                            <div class="col-4">
                                <button type="button" class="btn-popover" [popper]="taskTemplate">
                                  <span class="badge badge-secondary me-2">
                                    {{ 'vehicle-maintenance.open-tasks.type.' + task.name | translate }}
                                  </span>
                                </button>
                            </div>
                            <div class="col-4">
                                {{ task.lastUrgeDate | date: ('ucs.date' | translate) }}
                            </div>
                            <div class="col-4">
                                {{ task.urgeCount }}
                            </div>
                        </div>
                    </div>

                </div>
                <hr *ngIf="!last"/>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" [disabled]="urgeButtonDisabled || openTasks?.length < 1"
                (click)="urgeOpenTasks()">
          {{ 'vehicle-maintenance.open-tasks.contact-partner' | translate }}</button>
      </div>
    </div>
  </div>
</div>
