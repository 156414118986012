<div *ngIf="isBundleTransportRequestToggleActive && (allVehiclesAreSoldByMe || userState.isDevPoi || userState.isSupportPoi) && featureTransport">
  <ucs-transport [existingTransportRequests]="getTransportRequests()"
                 [channel]="pageSettings.channel"
                 [disabled]="(pageSettings.channel === 'PB' && !allVehiclesHaveSameCustodyDealer) || vehicles.length < 1"
                 [selectedVehicles]="vehicles | cast : VehicleDetailOfferItemDtos"
                 [id]="'Basket'"
                 [sellerInformation]="vehicles[0]?.seller"
                 [tooltipText]="transportTooltip">
  </ucs-transport>
</div>

<!-- OLD BUNDLE MODAL -->
<div *ngIf="!isBundleTransportRequestToggleActive" class="modal fade" id="basketModal" aria-labelledby="basketModal" aria-hidden="true" tabindex="-1"
     role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ 'basket.title' | translate }}</h5>
        <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"></span>
        </button>
      </div>

      <ng-container *ngIf="vehicles?.length > 0; else noMatches">
        <div class="modal-body col-12">
          <div *ngFor="let vehiclesBasket of vehicles">
            <ucs-icon (click)="changeCheck(vehiclesBasket)"
                      [shape]="vehiclesBasket ? 'square_checked' : 'square_outline'"
                      [class]="'blue'"></ucs-icon>
            <strong class="ms-1">{{vehiclesBasket.modelVariant}}</strong><br>
            <small>{{vehiclesBasket.externalId}}</small><br>
          </div>
        </div>
      </ng-container>
      <ng-template #noMatches>
        <div class="modal-body col-12">
          {{ 'basket.empty' | translate }}
        </div>
      </ng-template>

      <div
        *ngIf="vehicles.length > 0 && (allVehiclesAreSoldByMe || userState.isDevPoi || userState.isSupportPoi) && featureTransport"
        class="mt-2 ms-3 mb-3">
        <ucs-transport [existingTransportRequests]="getTransportRequests()"
                       [channel]="pageSettings.channel"
                       [disabled]="pageSettings.channel === 'PB' && !allVehiclesHaveSameCustodyDealer"
                       [selectedVehicles]="vehicles | cast : VehicleDetailOfferItemDtos"
                       [sellerInformation]="vehicles[0].seller"
                       [tooltipText]="transportTooltip"></ucs-transport>
      </div>

      <div class="modal-footer">
        <button type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal">{{ 'basket.cancel' | translate }}
        </button>

        <ng-container class="buy" *ngIf="vehicles?.length === 1">
          <button *ngIf="mayCreateBuyNowOffer"
                  class="btn btn-primary align-items-center no-radius-right pt-2 pe-3 pb-2 ps-3 me-0"
                  data-bs-dismiss="modal"
                  (click)="createOffer('BUYNOW')">
            <strong>{{ 'basket.buy-now' | translate }}</strong>
          </button>
          <button *ngIf="mayCreateAuctionOffer"
                  class="btn btn-primary align-items-center no-radius-left pt-2 pe-3 pb-2 ps-3 ms-0"
                  data-bs-dismiss="modal"
                  (click)="createOffer('AUCTION')">
            <strong>{{ 'basket.auction' | translate }}</strong>
          </button>
        </ng-container>
        <ng-container *ngIf="vehicles?.length > 1 && mayCreateBuyNowOffer"> <!-- bundles are always only BUYNOW -->
          <button type="button"
                  class="btn btn-primary"
                  data-bs-dismiss="modal"
                  (click)="createOffer('BUYNOW')">{{'basket.create-bundle' | translate }}
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</div>
