<div class="status-container">
  <span class="no-bid" *ngIf="(!status || status === 'NONE') && (offerStatus === 'ACTIVE' || offerStatus === 'EXTENDED')">
    <ucs-icon class="pe-1" [shape]="'hammer'" [class]="'grey'" [size]="20"></ucs-icon>
    <span>{{ 'offer-item.no-bid' | translate }}</span>
  </span>
  <span class="lower-step-size" *ngIf="status === 'LOWER_STEP_SIZE'">
    <ucs-icon class="pe-1" [shape]="'feather/x'" [class]="'feather-red'" [size]="20"></ucs-icon>
    <strong>{{ 'offer-item.lower-step-size' | translate }}</strong>
  </span>
  <span [class]="displaySpecialBid ? 'special-bid' : 'outbid'" *ngIf="status === 'OUTBID'">
    <ucs-icon *ngIf="!displaySpecialBid" class="pe-1" [shape]="displayTooLate ? 'feather/x' : 'feather/alert-triangle'" [class]="'feather-red'" [size]="20"></ucs-icon>
    <ucs-icon *ngIf="displaySpecialBid" class="pe-1" [shape]="'feather/check'" [class]="'feather-green'" [size]="20"></ucs-icon>
      <strong>{{messageInCaseOfOutbid | translate}} <br class="d-none d-md-inline">
        ({{bidTypeMsgToDisplay | translate}}:
        {{userMaxOrLatestBidToDisplay?.net | price}} {{userMaxOrLatestBidToDisplay?.currency.text}}{{isUAOffer ? '*':''}} {{'api.type.pricetype.NET' | translate}}{{showGrossPriceInStatus ?
          ' / ' + (userMaxOrLatestBidToDisplay?.gross | price) + ' ' + userMaxOrLatestBidToDisplay?.currency.text + (isUAOffer ? '* ': ' ') + ('api.type.pricetype.GROSS' | translate)
          : ''}})</strong>
  </span>
  <span *ngIf="status === 'HIGHEST'">
    <span class="your-highest-bid"
          data-cy="your-highest-bid"
          *ngIf="purchaseReceived === null || purchaseReceived === undefined || purchaseReceived === true; else purchaseNotReceived">
      <ucs-icon class="pe-1" [shape]="'feather/check'" [class]="'feather-green'" [size]="20"></ucs-icon>
      <strong *ngIf="!displayMaximumBid">{{ 'offer-item.your-highest-bid' | translate }}</strong>
      <strong *ngIf="displayMaximumBid">{{'offer-item.your-highest-bid' | translate}} <br class="d-none d-md-inline">
        ({{ 'offer-item.your-maximum' | translate}}:
        {{maximumBid.net | price}} {{maximumBid.currency.text}}{{isUAOffer ? '*':''}} {{'api.type.pricetype.NET' | translate}}{{showGrossPriceInStatus ?
          ' / ' + (maximumBid.gross | price) + ' ' + maximumBid.currency.text + (isUAOffer ? '* ': ' ') + ('api.type.pricetype.GROSS' | translate)
          : ''}})</strong>
    </span>
    <ng-template #purchaseNotReceived>
      <span class="outbid"
            data-cy="outbid">
        <ucs-icon class="pe-1" [shape]="'feather/alert-triangle'" [class]="'feather-red'" [size]="20"></ucs-icon>
        <strong>{{ 'offer-item.purchase-not-received' | translate }}</strong>
      </span>
      </ng-template>
  </span>
  <span class="success" *ngIf="status === 'SUCCESSFUL' && purchasePrice">
    <ucs-icon class="pe-1" [shape]="'feather/check'" [class]="'feather-green'" [size]="20"></ucs-icon>
    <strong>{{ 'offer-item.success' | translate }}
      ({{ purchasePrice?.net | price: purchasePrice?.currency?.data }}{{isUAOffer ? '*':''}} {{ 'api.type.pricetype.NET' | translate }})
    </strong>
  </span>
  <span class="minimum-purchase-price-not-reached"
       *ngIf="status === 'MINIMUM_PURCHASE_PRIZE_NOT_REACHED'">
        <ucs-icon class="pe-1" [shape]="'alert-triangle'" [size]="20"></ucs-icon>
        <strong>{{ 'offer-item.minimum-purchase-price-not-reached' | translate }}</strong> <br class="d-none d-md-inline">
        <strong>{{ 'offer-item.minimum-purchase-price-not-reached-info' | translate }}</strong>
    </span>
    <span class="purchase-not-obtained" *ngIf="status === 'PURCHASE_NOT_OBTAINED'">
    <ucs-icon class="pe-1" [shape]="'feather/x'" [class]="'feather-red'" [size]="20"></ucs-icon>
    <strong>{{ 'offer-item.purchase-not-obtained' | translate }}</strong>
  </span>
</div>

