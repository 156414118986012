<button class="w-100 btn btn-secondary" data-bs-toggle="modal" data-bs-target="#surveyAssignmentDialog"
        (click)="openDialog()">
  {{ (surveyRequest ? 'survey-request.button.existing-label' : 'survey-request.button.label') | translate }}
</button>

<div class="modal fade" id="surveyAssignmentDialog" aria-labelledby="surveyAssignmentDialog" aria-hidden="true"
     tabindex="-1"
     role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ 'survey-request.modal.title' | translate }}</h5>
        <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"></span>
        </button>
      </div>
      <div class="modal-body" *ngIf="!!surveyors; else noSurveyors">
      <div class="row">
        <strong class="col col-12 ms-3 mt-3 mb-1">{{ 'survey-request.modal.choose-surveyor' | translate }}</strong>
      </div>
      <div *ngIf="surveyors" class="d-grid">
        <ul class="list-group ps-3 pe-3">
          <li *ngFor="let surveyor of getSurveyors()" class="list-group-item list-group-item-action"
              [ngClass]="{'active': surveyCreation.surveyorId === surveyor.id, 'assigned': surveyor.assigned,
              'locked': surveyor.locked}"
              (click)="!surveyor.locked && (setSelectedSurveyor(surveyor))">
            <strong>{{ surveyor.name }}<span *ngIf="surveyor.linkedToAudaNet">*</span></strong>
            <span class="badge badge-pill ms-1">{{ surveyor.numberOfWeekAssignments }}</span>
            <button type="button" data-container="body"
                    [popper]="popSurveyor"
                    [popperTrigger]="NgxPopperjsTriggers.hover"
                    [popperPlacement]="NgxPopperjsPlacements.TOP"
                    [popperHideOnScroll]="true"
                    >
              <ucs-icon [shape]="'info'"
                        [class]="surveyCreation.surveyorId === surveyor.id ? 'white' : 'blue'"></ucs-icon>
            </button>
            <div class="contract-special-types">
              {{ Object.keys(surveyor.contractSpecialTypes).join(', ') }}
            </div>
            <popper-content  #popSurveyor>
              <div class="popper-content">
                <div>{{ surveyor.name }}</div>
                <div>{{ surveyor.address }}</div>
                <div>{{ surveyor.zipCode + ' ' + surveyor.city }}</div>
                <div>{{ surveyor.country }}</div>
                <span><a href="{{ 'mailto:' + surveyor.email }}">{{ surveyor.email }}</a></span>
                <div>{{ surveyor.phone }}</div>
                <div *ngIf="surveyor.locked">{{ ('survey-request.modal.locked' | translate) + surveyor.lockReason }}</div>
              </div>
            </popper-content>
          </li>
        </ul>
        <div class="d-flex justify-content-start" *ngIf="!showAllSurveyors && !hasAssignedSurveyors">
           <em class="ms-3">{{ 'survey-request.modal.no-assigned-surveyors' | translate }}</em>
            <div class="col-md-6 ms-2" *ngIf="isCustodyDealerMaintenanceEnabled">
              <a [routerLink]="'/administration/settings/surveyor-maintenance/' + dealerId" data-bs-dismiss="modal">
               {{ 'settings.surveyor-maintenacne.headline-text' | translate }}
              </a>
            </div>
        </div>
      </div>
      <!--is feature toggle not enabled show this to be removed when toggle 'CUSTODY_DEALER_MAINTENANCE' activated-->
      <div class="d-flex" *ngIf="!isCustodyDealerMaintenanceEnabled">
        <div *ngIf="!surveyRequest && isUserAT" class="ms-auto accordion mt-2 me-3 pe-3">
          <button *ngIf="showAllSurveyors" (click)="showAllSurveyors = false" class="button-borderless">
            <ucs-icon [shape]="'chevron-up'"></ucs-icon>
            {{ 'survey-request.modal.only-show-assigned-surveyors' | translate }}</button>
          <button *ngIf="!showAllSurveyors" (click)="showAllSurveyors = true" class="button-borderless">
            <ucs-icon [shape]="'chevron-down'"></ucs-icon>
            {{ 'survey-request.modal.show-assigned-surveyors' | translate }}</button>
        </div>
      </div>
        <div *ngIf="isCustodyDealerMaintenanceEnabled" class="d-flex justify-content-between">
          <div *ngIf="!surveyRequest && isUserAT" class="col ms-3 mt-1">
            <button (click)="showAllSurveyors = false" *ngIf="showAllSurveyors" class="button-borderless">
              <ucs-icon [shape]="'chevron-up'"></ucs-icon>
              {{ 'survey-request.modal.only-show-assigned-surveyors' | translate }}</button>
            <button (click)="showAllSurveyors = true" *ngIf="!showAllSurveyors" class="button-borderless">
              <ucs-icon [shape]="'chevron-down'"></ucs-icon>
              {{ 'survey-request.modal.show-assigned-surveyors' | translate }}</button>
          </div>
          <div *ngIf="isCustodyDealerMaintenanceEnabled && hasAssignedSurveyors" class="ms-auto accordion mt-2 me-3 pe-3">
            <a [routerLink]="'/administration/settings/surveyor-maintenance/' + dealerId" data-bs-dismiss="modal">
              {{ 'settings.surveyor-maintenacne.headline-text' | translate }}
            </a>
          </div>
        </div>
      <div class="row ms-3">
        <div class="col-5 ps-0">
          <ucs-survey-request-detail
            [audanetArea]="false"
            [surveyRequest]=surveyRequest
            [selectedSurveyor]="selectedSurveyor"
            [userCountry]=userCountry
            [contractTypeInfo]="contractTypeInfo">
          </ucs-survey-request-detail>
        </div>
        <div *ngIf="['CORRECTION_COMPLETE','COMPLETE'].includes(surveyRequest?.status) && isUserAT && infosFromAudanet"
             class="col-5 background">
          <ucs-survey-request-detail
            [audanetArea]="true"
            [surveyRequest]=surveyRequest
            [selectedSurveyor]="selectedSurveyor"
            [userCountry]=userCountry
            [contractTypeInfo]="contractTypeInfo">
          </ucs-survey-request-detail>
        </div>
      </div>
      <div class="row">
        <div class="col col-12 mt-3 ms-3"><strong>{{ 'survey-request.modal.comment' | translate }}</strong></div>
        <div class="col-8">
        <textarea class="form-control ms-3"
                  [(ngModel)]="surveyCreation.comment"
                  maxlength="500"></textarea>
        </div>
        <span class="ms-3 pt-1" *ngIf="surveyCreation?.comment?.length > 0">{{surveyCreation.comment.length}} / 500</span>
      </div>

      <div *ngIf="!['CORRECTION_COMPLETE'].includes(surveyRequest?.status)"
           class="email-copy mt-3 mb-3 ps-3 pe-3">
        <ucs-icon (click)="surveyCreation.emailNotification = !surveyCreation.emailNotification"
                  [shape]="surveyCreation.emailNotification ? 'square_checked' : 'square_outline'"
                  [class]="'blue'"></ucs-icon>
        <strong class="ms-2 label" (click)="surveyCreation.emailNotification = !surveyCreation.emailNotification">
          {{'survey-request.modal.email-notification' | translate}}
        </strong>
      </div>
      </div>
        <ng-template #noSurveyors>
          <div class="modal-body">
            <div class="warning-text">
              <ucs-icon [class]="'no-surveyor-warning-icon'" [shape]="'lock-alert'"></ucs-icon><br/>
              <br>
              <br><span> {{ 'survey-request.modal.no-surveyors-warning-first' | translate}} </span>
              <span><strong> „{{ 'survey-request.modal.no-surveyors-surveyor' | translate}}“  </strong></span>
              <span> {{ 'survey-request.modal.no-surveyors-warning-second' | translate}} </span>
            </div>

            <div class="info-text">
              <ucs-icon [shape]="'info'" [class]="'no-surveyor-info-icon'"></ucs-icon>
              <span>{{ 'survey-request.modal.no-surveyors-info' | translate}}</span>
            </div>
          </div>
        </ng-template>
      <div class="modal-footer d-flex justify-content-between">
        <div *ngIf="isUserAT" class="justify-content-start">
          <em>{{ 'survey-request.modal.request-via-audanet' | translate}}</em>
        </div>

        <div class="justify-content-end">
          <div *ngIf="surveyRequest && surveyRequest.status === 'IN_PROGRESS'" class="btn btn-link urge me-1" (click)="urgeSurveyRequest()">
            <ucs-icon [shape]="'time_clock'" [class]="'blue ms-1 me-1'"
            ></ucs-icon>
            <span>{{ 'survey-request.modal.urge' | translate }}</span>
          </div>
          <div *ngIf="surveyRequest && surveyRequest.status === 'COMPLETE'" class="btn btn-link discard me-1" (click)="discardSurvey()">
            <ucs-icon [shape]="'x'" [class]="'blue ms-1 me-1'"></ucs-icon>
            <span>{{ 'survey-request.modal.discard' | translate }}</span>
          </div>

          <button type="button" class="btn btn-secondary me-1 p-2" data-bs-dismiss="modal">
            {{ 'survey-request.modal.button.cancel' | translate }}
          </button>

          <button *ngIf="!surveyRequest" type="button" class="btn btn-primary p-2 me-1" (click)="requestSurvey()"
                  data-bs-dismiss="modal" [disabled]="isSurveyButtonDisabled" [hidden]="isSurveyButtonHidden" >
            <strong>{{ 'survey-request.modal.button.request' | translate }}</strong>
          </button>
          <button *ngIf="surveyRequest && surveyRequest.status === 'IN_PROGRESS'"
                  class="btn btn-primary p-2"
                  (click)="cancelSurveyRequest()" data-bs-dismiss="modal">
            <strong>{{ 'survey-request.modal.button.cancel-request' | translate }}</strong>
          </button>
          <button *ngIf="surveyRequest && surveyRequest.status === 'COMPLETE'"
                  class="btn btn-primary p-2"
                  (click)="correctSurvey()" data-bs-dismiss="modal">
            <strong>{{ 'survey-request.modal.button.correct' | translate }}</strong>
          </button>
        </div>

      </div>
    </div>
  </div>
</div>
