<ng-container *ngIf="!audanetArea">
  <div class="row">
    <div class="col-4 mt-3">
      <strong>{{ 'survey-request.modal.status' | translate }}</strong>
      <div>{{status}}
        <span *ngIf="['COMPLETE','CORRECTION_COMPLETE'].includes(surveyRequest?.status)">
               {{ surveyRequest?.finishedDate | date: ('ucs.datetime.with-time-clip' | translate) }}</span>
      </div>
    </div>
    <div class="col-4 mt-3">
      <strong>{{ 'survey-request.modal.surveyor' | translate }}</strong>
      <div>{{surveyor}}</div>
    </div>
    <div class="col-4 mt-3">
      <strong>{{ 'survey-request.modal.requested-on' | translate }}</strong>
      <div>{{!!requested && requested !== '-' ? (requested | date: ('ucs.datetime.with-time-clip' | translate)) : '-' }}</div>
    </div>
  </div>
  <div class="row">
    <div class="col-4 mt-3" *ngIf="isUserAT">
      <strong>{{ 'survey-request.modal.fee' | translate }}</strong>
      <div>{{fee}}</div>
    </div>
    <div class="col-4 mt-3" *ngIf="isUserAT">
      <strong>{{ 'settings.surveyor-maintenance-contract-types.rated-bodywork' | translate }}</strong>
      <div>{{contractTypeInfo?.rateBodyworkNet ?
        (contractTypeInfo.rateBodyworkNet | price) + ' ' + ('api.type.currency.EUR' | translate) + ' ' + ('api.type.pricetype.NET' | translate)
        : '-'}}</div>
    </div>
    <div class="col-4 mt-3 mb-3">
      <strong>{{ 'survey-request.modal.requested-by' | translate }}</strong>
      <div>{{requestedBy}}</div>
    </div>
  </div>
  <div class="row">
    <div class="col-4 mt-3" *ngIf="isUserAT">
      <strong>{{ 'survey-request.modal.fourth-damage-fee' | translate }}</strong>
      <div>{{fourthDamageFee}}</div>
    </div>
    <div class="col-4 mt-3" *ngIf="isUserAT">
      <strong>{{ 'settings.surveyor-maintenance-contract-types.rated-technics' | translate }}</strong>
      <div>{{contractTypeInfo?.rateTechnicsNet ?
        (contractTypeInfo.rateTechnicsNet | price) + ' ' + ('api.type.currency.EUR' | translate) + ' ' + ('api.type.pricetype.NET' | translate)
        : '-'}}</div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="audanetArea">
  <div class="row">
    <div class="col-6 mt-3">
      <strong>{{ 'survey-request.modal.fee-sv' | translate }}</strong>
      <div>{{feeSV}}</div>
    </div>
    <div class="col-6 mt-3">
      <strong>{{ 'survey-request.modal.replacement-value-sv' | translate }}</strong>
      <div>{{replacementValueSv}}</div>
    </div>
  </div>
  <div class="row">
    <div class="col-6 mt-3" *ngIf="isUserAT">
      <strong>{{ 'survey-request.modal.estimated-value-sv' | translate }}</strong>
      <div>{{estimatedValueSv}}</div>
    </div>
    <div class="col-6 mt-3">
      <strong>{{ 'survey-request.modal.forecast-value-sv' | translate }}</strong>
      <div>{{forecastValueSv}}</div>
    </div>
  </div>
  <div class="row">
    <div class="col-6 mt-3" *ngIf="isUserAT">
      <strong>{{ 'survey-request.modal.nr-of-damages-sv' | translate }}</strong>
      <div>{{nrOfDamagesSv}}</div>
    </div>
    <div class="col-6 mt-3 mb-3">
      <strong>{{ 'survey-request.modal.sum-of-damages-sv' | translate }}</strong>
      <div>{{sumOfDamagesSv}}</div>
    </div>
  </div>
</ng-container>
