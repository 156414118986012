import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {PricePipe} from '../../../pipe/price.pipe';
import {TranslateService} from '@ngx-translate/core';
import {SystemSettingsService} from '../../../service/system-settings.service';
import {first} from 'rxjs/operators';

@Component({
  selector: 'ucs-survey-request-detail',
  templateUrl: './survey-request-detail.component.html',
  styleUrls: ['./survey-request-detail.component.scss']
})

/**
 * Detail information component for survey request management
 */
export class SurveyRequestDetailComponent implements OnInit, OnChanges {

  @Input() surveyors: SurveyorDto[];
  @Input() surveyRequest: SurveyDto;
  @Input() selectedSurveyor: SurveyorDto;
  @Input() audanetArea: boolean;
  @Input() userCountry: string;
  @Input() infosFromAudanet: boolean;
  @Input() contractTypeInfo: SurveyContractTypeDto;

  isUserAT = false;

  status = '-';
  surveyor = '-';
  fee = '-';
  requested = '-';
  fourthDamageFee = '-';
  requestedBy = '-';

  feeSV: any;
  replacementValueSv: any;
  estimatedValueSv: any;
  forecastValueSv: any;
  nrOfDamagesSv: any;
  sumOfDamagesSv: any;

  isUCS540SurveyMaintenanceEnabled = false;

  constructor(private pricePipe: PricePipe,
              private translateService: TranslateService,
              private systemSettingsService: SystemSettingsService) {
  }

  ngOnInit() {
    this.translateService.get('survey-request.not-assigned').subscribe(value => {
      this.updateSurveyRequest();
      this.updateSelectedSurveyor();
      this.completedSurvey();
    });

    this.systemSettingsService.isSystemFeatureActivatedForAnyChannel('UCS_540_SURVEY_MAINTENANCE')
      .pipe(first()).subscribe(isEnabled => {
        this.isUCS540SurveyMaintenanceEnabled = isEnabled;
      });
  }

  ngOnChanges() {
    this.updateSurveyRequest();
    this.updateSelectedSurveyor();
    this.completedSurvey();
  }

  updateSurveyRequest() {
    this.status = this.surveyRequest ?
      this.translateService.instant('survey-request.status.' + this.surveyRequest.status) :
      this.translateService.instant('survey-request.not-assigned');
    this.surveyor = '-';
    if (this.surveyRequest) {
      this.surveyor = this.surveyRequest.surveyor.name;
    } else if (this.selectedSurveyor) {
      this.surveyor = this.selectedSurveyor.name;
    }
  }

  updateSelectedSurveyor() {
    this.fee = '-';
    this.fourthDamageFee = '-';
    this.requested = this.surveyRequest ? this.surveyRequest.requestDate : '-';
    this.requestedBy = this.surveyRequest ? this.surveyRequest.requester : '-';
    if (this.surveyRequest || this.selectedSurveyor) {
      let fee = this.surveyRequest?.fee.net ?? this.selectedSurveyor?.fee?.net;
      let fourthDamage = this.surveyRequest?.fourthDamageFee?.net ?? this.selectedSurveyor?.fourthDamageFee?.net;
      let currency = this.surveyRequest?.fee?.currency.text ?? this.selectedSurveyor?.fee?.currency.text;

      if (!this.surveyRequest && this.isUCS540SurveyMaintenanceEnabled) {
        let priceInfo = this.selectedSurveyor.surveyorContractPriceInfos.find(
          entry => entry.contractSpecialType === this.contractTypeInfo.contractSpecialType);
        fee = priceInfo.fee.net;
        fourthDamage = priceInfo.fourthDamageFee.net;
        currency = priceInfo.fee.currency.text;
      }
      this.fee = this.pricePipe.transform(fee)
        + ' ' + currency
        + '  ' + this.translateService.instant('api.type.pricetype.NET');
      this.fourthDamageFee = this.pricePipe.transform(fourthDamage)
        + ' ' + currency
        + ' ' + this.translateService.instant('api.type.pricetype.NET');
    }

  }

  completedSurvey() {
    if (this.userCountry === 'AT') {
      this.isUserAT = true;
    }
    if (!!this.surveyRequest && ['CORRECTION_COMPLETE', 'COMPLETE'].includes(this.surveyRequest?.status) &&
      this.audanetArea) {

      this.feeSV = this.getFeeSV();
      this.estimatedValueSv = this.getEstimatedValueSv();
      this.replacementValueSv = this.getReplacementValueSv();
      this.forecastValueSv = this.getForecastValueSv();
      this.estimatedValueSv = this.getEstimatedValueSv();
      this.nrOfDamagesSv = this.getNrOfDamagesSv();
      this.sumOfDamagesSv = this.getSumOfDamagesSv();
    }
  }

  private getFeeSV() {
    return this.surveyRequest.feeFromSurvey ? this.pricePipe.transform(this.surveyRequest.feeFromSurvey.net)
      + ' ' + this.surveyRequest.feeFromSurvey.currency.text
      + ' ' + this.translateService.instant('api.type.pricetype.NET') : '-';
  }

  private getSumOfDamagesSv() {
    return this.surveyRequest.sumOfDamagesFromSurvey ?
      this.pricePipe.transform(this.surveyRequest.sumOfDamagesFromSurvey?.gross)
      + ' ' + this.surveyRequest.sumOfDamagesFromSurvey?.currency.text
      + ' ' + this.translateService.instant('api.type.pricetype.GROSS') : '-';
  }

  private getNrOfDamagesSv() {
    return this.surveyRequest.numberOfDamagesFromSurvey ?
      this.surveyRequest.numberOfDamagesFromSurvey : '-';
  }

  private getEstimatedValueSv() {
    return this.surveyRequest.forecastValueFromSurvey ?
      this.pricePipe.transform(this.surveyRequest.estimatedValueFromSurvey.gross)
      + ' ' + this.surveyRequest.estimatedValueFromSurvey.currency.text
      + ' ' + this.translateService.instant('api.type.pricetype.GROSS') : '-';
  }

  private getForecastValueSv() {
    return this.surveyRequest.forecastValueFromSurvey ?
      this.pricePipe.transform(this.surveyRequest.forecastValueFromSurvey.gross)
      + ' ' + this.surveyRequest.forecastValueFromSurvey.currency.text
      + ' ' + this.translateService.instant('api.type.pricetype.GROSS') : '-';
  }

  private getReplacementValueSv() {
    return this.surveyRequest.replacementValueFromSurvey ?
      this.pricePipe.transform(this.surveyRequest.replacementValueFromSurvey.gross)
      + ' ' + this.surveyRequest.replacementValueFromSurvey.currency.text
      + ' ' + this.translateService.instant('api.type.pricetype.GROSS') : '-';
  }
}


