<div class="modal fade lineheight" id="{{'confirmBidModal' + offer.id}}" aria-labelledby="confirmBidModal"
     aria-hidden="true"
     tabindex="-1"
     role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">

      <div class="modal-header">
        <h5 class="modal-title">{{ 'offer-maintenance.detail.bids.bidForDealer' | translate }}</h5>
        <button type="button" class="btn-close btn-close-white" (click)="cancel()" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"></span>
        </button>
      </div>

      <div class="modal-body">
        <form>
          <div *ngIf="allowedToSeeManualInput" class="row col justify-content-end d-inline-block pe-0 text-end">
            <button *ngIf="!manualInput" type="button" class="btn-popover mb-1" (click)="switchDealerEntryType()">
              <ucs-icon [shape]="'edit-2'"></ucs-icon>
              <strong>{{'initial-proposal.dialog.manual-input' | translate}}</strong>
            </button>
            <button *ngIf="manualInput" type="button" class="btn-popover mb-1" (click)="switchDealerEntryType()">
              <ucs-icon [shape]="'search'" [class]="'blue'"></ucs-icon>
              <strong>{{'initial-proposal.dialog.search-dealer' | translate}}</strong>
            </button>
          </div>
          <ng-container *ngIf="!manualInput">
            <ucs-dealer-autocomplete class="d-inline-block col-12 ps-0"
                                     [sourceFunction]="dealerAutoCompleteSourceFunction"
                                     [channel]="offer.channel.data"
                                     [class]="'form-control ps-3'"
                                     [label]="'offer-maintenance.detail.bids.bidder' | translate"
                                     [placeholder]="'offer-maintenance.detail.bids.bidder.select' | translate"
                                     [sidebar]="true"
                                     (onSelected)="dealerSelected($event)"></ucs-dealer-autocomplete>
            <div class="col-12 mt-2 ms-2 ps-0" *ngIf="dealer?.name">
              {{ dealer?.companyNumber }} {{ dealer?.name }}<br/>
              {{ dealer?.address }}<br/>
              {{ dealer?.zipCode }} {{ dealer?.city }}
            </div>
          </ng-container>
          <ucs-manual-dealer-input *ngIf="manualInput" [vehicleId]="vehicleItem.vehicleId"
                                   (externalDealerChanged)="dealerEntered($event)"
                                   (validityChanged)="manualInputValid = $event"></ucs-manual-dealer-input>
          <ucs-net-gross-input [netPrice]="bidPrice?.net"
                               [grossPrice]="bidPrice?.gross"
                               [currency]="offer.startPrice.currency.data"
                               [netPriceValidationError]="validationError"
                               [grossPriceValidationError]="grossPriceValidationError"
                               [hideGrossPrice]="!offer.nationalSale"
                               [headingTranslationKey]="'bid.dialog.your-bid'"
                               [sectionScope]="'SALES_MAINTENANCE'"
                               [vehicleOrOfferDto]="offer"
                               (backGrossPriceChange)="bidPrice.gross = $event"
                               (backNetPriceChange)="bidPrice.net = $event"></ucs-net-gross-input>

          <div class="col-12 mt-2 ps-0">
            <strong>{{'vehicle-maintenance.detail.initial-proposal.comment' | translate}}</strong>
            <textarea [(ngModel)]="comment" name="comment" class="col-8 mt-1 mb-3 form-control" maxlength="250">
            </textarea>
          </div>
          <div *ngIf="generalValidationError" class="row col-12 mt-1">
            <span class="error">{{ generalValidationError | translate }}</span>
          </div>
          <div *ngIf="vehicleItem.thermalWindowAffected" class="row col-12 mt-1">
            <span>
              <ucs-icon [shape]="'alert-triangle'" [class]="'red'" [size]="17"></ucs-icon>
              {{'thermal-window.info' | translate}}
            </span>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <ucs-countdown class="me-auto" [expirationDate]="offer?.expiration" [isExternalDealerBidDialog]="true"></ucs-countdown>

        <button type="button" class="btn btn-secondary button-cancel" (click)="cancel()" data-bs-dismiss="modal">{{
          'bid.dialog.cancel' | translate }}
        </button>

        <button type="button" class="btn btn-primary button-save" (click)="bid()">{{ 'bid.dialog.bid' | translate }}
        </button>

      </div>
    </div>
  </div>
</div>
