<div class="mb-3">
    @if (!isEnforcedAuction) {
      <div class="net-price-container p-3" *ngIf="!isEnforcedAuction">
            <div class="row">
                <div class="col-12 bid-title">{{ headingTranslationKey | translate }}</div>
            </div>
            <div class="row">
              <span class="col-5">
                <ucs-price-input
                    data-cy="net-price-input"
                    [id]="id + '_net'"
                    [name]="id + '_net'"
                    [class]="'form-control'"
                    [(ngModel)]="netPrice"
                    [disabled]="priceInputDisabled"
                    (ngModelChange)="netPriceChange.emit($event)"
                    (userFocus)="userFocus($event)"
                    (enterOnInputElement)="enterOnInputElement.emit()"
                    name="price">
                </ucs-price-input>
               </span>
                <div class="col ms-1 mt-2">
                    <span class="currency">{{ currency }}{{isUAOffer? '*':''}}</span>
                    <span class="gross-net-font"> {{ 'api.type.pricetype.NET' | translate }}</span>
                </div>
                <div *ngIf="showVatNovaIcons" class="col-md-auto mt-2 d-flex">
                    <ucs-calc-icon
                            *ngIf="getVatTypeFromVehicleOrOffer() === 'STANDARD' && (userState.isAustrian || getChannelFromVehicleOrOffer() === 'ALL_UC')"
                            class="me-2" [type]="getVatTypeFromVehicleOrOffer() === 'STANDARD' ? 'NOK' : 'OK'"
                            [text]="'icon.vat.' + getVatTypeFromVehicleOrOffer()"></ucs-calc-icon>
                    <ucs-calc-icon *ngIf="(userState.isAustrian || isNovaInfoGrossPriceEnabled) && !isEnforcedAuction"
                                   [type]="getExtraTaxFromVehicleOrOffer()?.paid ? 'OK': 'NOK'"
                                   [text]="'icon.nova.' + (getExtraTaxFromVehicleOrOffer()?.paid ? 'OK': 'NOK')"></ucs-calc-icon>
                </div>
            </div>
            <div *ngIf="netPriceValidationError" class="row col-12 mt-1">
                <span class="error">{{ netPriceValidationError | translate }}</span>
            </div>
      </div>
    }
  <div *ngIf="!hideGrossPrice" class="p-3 gross-price-container" [ngClass]="{'enforced-gross-price-container':isEnforcedAuction}">
    <div class="row">
     <span class="col-5">
      <ucs-price-input
        data-cy="gross-price-input"
        [id]="id + '_gross'"
        [name]="id + '_gross'"
        [class]="'form-control gross-input'"
        [inputError]="inputError"
        [(ngModel)]="grossPrice"
        [disabled]="priceInputDisabled"
        (ngModelChange)="grossPriceChange.emit($event)"
        (userFocus)="userFocus($event)"
        (enterOnInputElement)="enterOnInputElement.emit()"
        name="grossId">
      </ucs-price-input>
     </span>
      <div class="col ms-1 mt-2">
        <span class="currency">{{ currency }}{{isUAOffer? '*':''}}</span>
        <span class="gross-net-font currency"> {{ 'api.type.pricetype.GROSS' | translate }}</span>
        <span *ngIf="showPPMV" class="currency">
        ({{ 'ucs.including-short'| translate }}
            {{ extraTaxAmount | price: undefined : undefined :  '1.2-2' }} {{ currency }}
            {{'vehicle-detail.extra-tax-label.PPMV' | translate}})
        </span>
      </div>

      <div *ngIf="showVatNovaIcons" class="col-md-auto mt-2 d-flex">
        <ucs-calc-icon
          *ngIf="getVatTypeFromVehicleOrOffer() === 'STANDARD' && (userState.isAustrian || getChannelFromVehicleOrOffer() === 'ALL_UC')"
          class="me-2" [type]="'OK'" [text]="'icon.vat.DIFFERENTIAL'"></ucs-calc-icon>
        <ucs-calc-icon *ngIf="(userState.isAustrian || isNovaInfoGrossPriceEnabled) && !isEnforcedAuction"
                       [type]="getExtraTaxFromVehicleOrOffer()?.paid ? 'OK' : (['PIA', 'PB'].includes(getChannelFromVehicleOrOffer()) ? 'NOK' : 'MANUAL')"
                       [text]="'icon.nova.' + (getExtraTaxFromVehicleOrOffer()?.paid ? 'OK' : (['PIA', 'PB'].includes(getChannelFromVehicleOrOffer()) ? 'NOK' : 'MANUAL'))"></ucs-calc-icon>
      </div>
    </div>
    <div *ngIf="grossPriceValidationError" class="row col-12 mt-1 mb-3">
      <span class="error">{{ grossPriceValidationError | translate }}</span>
    </div>
  </div>
</div>
