<span [popper]="novaHint" [popperPlacement]="NgxPopperjsPlacements.BOTTOM"
      [popperDisabled] = "type !== 'MANUAL'"
      [ngClass]="{'manual' : type === 'MANUAL'}" [popperApplyClass]="'info-popover-size'">
  <ucs-icon [shape]="'calculator_'+ type" [class]="'grey'"
            [size]="18"></ucs-icon>
  <span class="text-type ms-1">
      <strong>{{ text | translate }}</strong>
  </span>
</span>
<popper-content #novaHint>
  <div class="manual-text">
    {{ 'icon.nova.manual.tooltip' | translate }}
  </div>
</popper-content>
