<div *ngIf="offer" id="{{ 'offer' + offer.id }}" class="offer rounded container-fluid">
  <div
    [ngClass]="{'opacity-50': userState?.userInfo?.accredited === false && offer.channel.data === 'PIA'}">
    <div class="px-2 pb-lg-2 pt-2 row mobile-shift-column-for-gallery">

        <div class="col-3 px-0">
            <ucs-detail-gallery-dialog class="no-black-edge"
                                       [images]="galleryImages"
                                       [channel]="offer?.channel.data"
                                       [appScope]="'SALES_STORE'"
                                       [galleryId]="offer?.id">
            </ucs-detail-gallery-dialog>
        </div>

      <div class="col-9 ps-2 ps-md-3 mt-3 mt-md-0">
        <div class="row height-40">
          <div class="col model-name link-title" routerLink="/offer/{{offer.id}}"
               data-cy="offer-component.offer-title">
            <a [routerLink]="'/offer/' + offer.id" (click)="storeBacklink()">
              <strong>{{ offer.title }}</strong>
            </a>
          </div>

          <div class="ms-auto col-auto px-0 fav">
            <span *ngIf="enableNote" class="d-inline-block cursor-pointer pe-md-3">
              <ucs-icon class="pe-1" [shape]="'feather/edit-2'" [size]="24" [class]="'note feather-blue'"
                        (click)="showNoteInput(); favNoteElement.focus()"></ucs-icon>
              <strong class="d-none d-md-inline"
                      (click)="showNoteInput(); favNoteElement.focus()">{{ 'offer-item.note' | translate }}
              </strong>
            </span>

            <ucs-info-icon *ngIf="!userState.isGerman && vehicle?.infoText && vehicle.infoText !== ''"
                           [infoText]="vehicle.infoText"></ucs-info-icon>
            <ucs-offer-bookmarking [offer]="offer"></ucs-offer-bookmarking>
          </div>
        </div>

        <div class="row d-block height-40 location-wrapper">
          <div class="col-12">
            <span class="d-inline-block">
              <ucs-icon [shape]="'location_pin'" [size]="16"></ucs-icon>
              {{ offer.location }}
              <ucs-icon class="pb-1" [shape]="'flags/' + countryFlag" [size]="16"></ucs-icon>
            </span>
            <ng-container *ngIf="showVatType">
              <ucs-icon class="mx-2" [shape]="'circle'" [size]="12" [class]="'grey opacity-025'"></ucs-icon>
              <span>{{ vehicle.vatType.text }}</span>
            </ng-container>
          </div>
        </div>

        <div *ngIf="viewSettings" class="row height-40 ps-2 ps-md-3">
          <span *ngIf="view('MILEAGE') && !ucsIsNil(vehicle.mileage)"
                ucsHideableData [channel]="this.offer.channel.data" [dataType]="'mileage'"
                class="col-auto box-item me-1 mb-1 px-2">{{ vehicle.mileage | km }}</span>
          <span *ngIf="view('INITIAL-REGISTRATION') && !ucsIsNil(vehicle.initialRegistrationDate)"
                ucsHideableData [channel]="this.offer.channel.data" [dataType]="'initial-registration-date'"
                class="col-auto box-item me-1 mb-1 px-2">{{ 'offer-item.initial-registration.short' | translate }} {{ vehicle.initialRegistrationDate | date : 'MM/yyyy' }}</span>
          <span *ngIf="view('FUEL') && !ucsIsNil(vehicle.fuelTypes[0])"
                ucsHideableData [channel]="this.offer.channel.data" [dataType]="'fuel-types'"
                class="col-auto box-item me-1 mb-1 px-2">{{ vehicle.fuelTypes[0] }}</span>
          <span *ngIf="view('POWER') && !ucsIsNil(vehicle.powerPs) && !ucsIsNil(vehicle.powerKw)"
                ucsHideableData [channel]="this.offer.channel.data" [dataType]="'power'"
                class="col-auto box-item me-1 mb-1 px-2">{{ vehicle.powerPs | ps }}
            ({{ vehicle.powerKw | kw }})</span>
          <span *ngIf="view('COLOR') && !ucsIsNil(vehicle.color)"
                ucsHideableData [channel]="this.offer.channel.data" [dataType]="'color'"
                class="col-auto box-item me-1 mb-1 px-2">{{ vehicle.color }}</span>
          <span *ngIf="view('FUEL-CONSUMPTION') && !ucsIsNil(vehicle.consumption)"
                ucsHideableData [channel]="this.offer.channel.data" [dataType]="'consumption'"
                class="col-auto box-item me-1 mb-1 px-2">{{ vehicle.combinedFuelCode === '4' ? (vehicle.consumption | kwhPerHundredKm) :
                  (vehicle.consumption | litersPerHundredKm)}}</span>
          <span *ngIf="view('TAX') && !ucsIsNil(vehicle.vatType)"
                ucsHideableData [channel]="this.offer.channel.data" [dataType]="'tax-type'"
                class="col-auto box-item me-1 mb-1 px-2">{{ vehicle.vatType?.text }}</span>
          <span *ngIf="view('EMISSION') && !ucsIsNil(vehicle.emissionClass)"
                ucsHideableData [channel]="this.offer.channel.data" [dataType]="'emission-class'"
                class="col-auto box-item me-1 mb-1 px-2">{{ vehicle.emissionClass | emissionClass }}</span>
          <span *ngIf="view('EXPLOITATION-TYPE') && !ucsIsNil(vehicle.exploitationType)"
                ucsHideableData [channel]="this.offer.channel.data" [dataType]="'exploitation-type'"
                class="col-auto box-item me-1 mb-1 px-2">{{ vehicle.exploitationType?.text }}</span>
        </div>

        <div class="row ps-2 d-md-none">
            <button class="btn-show-further-data w-100 text-start my-1"
                    (click)="showFurtherData = !showFurtherData">
              <ng-container *ngIf="showFurtherData; else hideFurtherData">
                {{ 'ucs.show-less' | translate}}
                <ucs-icon [shape]="'angle_up'" [class]="'blue'"></ucs-icon>
              </ng-container>
              <ng-template #hideFurtherData>
                {{ 'ucs.show-more' | translate}}
                <ucs-icon [shape]="'angle_down'" [class]="'blue'"></ucs-icon>
              </ng-template>
            </button>
        </div>

        <div class="row height-40 d-md-flex" [ngClass]="{'d-none': !showFurtherData, 'd-flex': showFurtherData}">
            <div class="col-auto pe-0" *ngIf="vehicle.mediaInfos?.length > 0">
            <button class="btn-popover px-0" type="button"
                    [popper]="documentsPopover"
                    [popperPlacement]="NgxPopperjsPlacements.BOTTOM"
                    [popperApplyClass]="'info-popover-auto-width'">
              <ucs-icon [shape]="'document'" [class]="'blue'"></ucs-icon>
            </button>
            <popper-content #documentsPopover>
              <a *ngFor="let document of vehicle.mediaInfos"
                 href="{{ document.externalLink ? document.url : document.url + '/original' }}"
                 (mousedown)="$event.preventDefault()"
                 target="_blank">
                  <span class="ps-1 d-inline-block text-alternate">
                    {{ document.type.text }}
                  </span>
                <span class="ps-1 d-inline-block text-alternate"
                      *ngIf="showExternalDocumentLink && document.externalLink">
                    {{'vms.media-types.external-document' | translate}}
                  </span>
              </a>
            </popper-content>
          </div>
          <div class="col-auto pe-0">
            <span class="id">{{ vehicle.externalId }}</span>
          </div>
          <ucs-highlight-equipment [highlightEquipments]="vehicle.highlightEquipments" class="col-auto d-none d-md-flex">
          </ucs-highlight-equipment>
          <div class="row d-md-none"> <!-- only displayed here for mobile view, otherwise displayed in card-footer -->
            <ucs-tax-nova-gross-profit [vatType]="vehicle.vatType.data"
                                       [channel]="offer.channel.data"
                                       [country]="offer.country"
                                       [nationalSale]="offer.nationalSale"
                                       [currency]="['AUCTION','ENFORCED_AUCTION'].includes(offer.offerType.data) ? offer.highestBid.currency?.data : offer.price?.currency?.data"
                                       [extraTax]="vehicle.extraTax" [vehicleOfferItem]="vehicle" [scopeSection]="'SALES_STORE'">
            </ucs-tax-nova-gross-profit>
            <ng-container *ngTemplateOutlet="vin"></ng-container>
          </div>
        </div>

      </div>
    </div>

    <ng-template #vin>
      <div class="mt-0 mb-auto col-12 col-md-auto vin">
        <strong>{{ 'offer-item.vin' | translate }}</strong> {{vehicle.vin}}
      </div>
    </ng-template>

    <div class="row">
      <ng-container *ngIf="!mobileView">
        <ng-container *ngTemplateOutlet="vin"></ng-container>
      </ng-container>


      <div class="favnote d-none" (blur)="saveNote()" contenteditable #favNoteElement></div>

      <ucs-bid-status class="ms-auto me-0 mt-auto mb-0 col-12 col-md-auto d-md-flex d-none"
                      *ngIf="['AUCTION','ENFORCED_AUCTION'].includes(offer?.offerType?.data) && offer?.eligible === true"
                      [status]="offer?.bidStatus?.data"
                      [offerStatus]="offer?.status?.data"
                      [maximumBid]="offer?.maximumBid"
                      [myLastHighestBid]="offer?.myLastHighestBid"
                      [myLastMaximumBid]="offer?.myLastMaximumBid"
                      [tooLateBid]="offer?.tooLateBid"
                      [purchasePrice]="offer?.purchase?.price"
                      [highestBid]="offer?.highestBid"
                      [purchaseReceived]="offer?.purchase?.purchaseReceived"
                      [nationalSale]="offer?.nationalSale"></ucs-bid-status>

      <ucs-buy-now-purchase-status class="ms-auto my-auto col-12 col-md-auto buy pe-2 mobile d-none d-md-flex"
                          *ngIf="offer.offerType.data === 'BUYNOW' &&
                          offer.purchase?.buyingDealer?.id === userState.userInfo.currentDealerId"
                          [offerStatus]="offer?.status?.data">
      </ucs-buy-now-purchase-status>
    </div>
  </div>

  <div class="row footer rounded-bottom">
    <div class="col col-12 mb-lg-0 card-footer-wrapper">
      <ucs-card-footer [offerData]="offer"
                       [vehicle]="vehicle"
                       [extraTax]="vehicle.extraTax"
                       [vatType]="vehicle.vatType.data" [channel]="offer.channel.data" [showOfferLink]="false"
                       [currentPrice]="['AUCTION','ENFORCED_AUCTION'].includes(offer.offerType.data) ? offer.highestBid : offer.price"
                       [minimumBidPrice]="offer.minimumBid"
                       [footerStatus]="footerStatus"
                       [hideFooterStatus]="true"
                       [country]="offer.country"
                       [disableBidding]="isExpired"
                       [startPrice]="offer.startPrice"
                       [scopeSection]="'SALES_STORE'">
      </ucs-card-footer>
    </div>
    <ucs-buy-now-dialog *ngIf="offer.offerType.data === 'BUYNOW'" [offer]="offer"
                        (newOfferStatus)="offer.status.data = $event"></ucs-buy-now-dialog>
    <ucs-bid-dialog *ngIf="['AUCTION','ENFORCED_AUCTION'].includes(offer.offerType.data)" [offer]="offer"
                    (isOfferExpired)="isExpired = $event"></ucs-bid-dialog>
    <ucs-thermal-window-dialog [offer]="offer"></ucs-thermal-window-dialog>
  </div>

</div>
