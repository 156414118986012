<div *ngIf="offerUpdateDto && offerDto" class="d-flex">
  <div class="col-auto col-lg">
    <strong class="text text-bright me-1">{{ 'offer-maintenance.detail.receiver' | translate }}</strong>

    <div *ngIf="isCascadeSelectionEnabled">
      <div class="row mt-3 mb-2">
        <div *ngIf="isStatusPreparation" class="btn-group btn-group-toggle col-12">
          <label class="btn btn-sm button-toggle-options" data-cy="offer-maintenance.without-cascade" [ngClass]="{'active': selectedCascade === 'STANDARD'}">
            <input type="radio" [value]="'STANDARD'" [(ngModel)]="selectedCascade"
                   (click)="selectStandardCascade()"> {{'offer-maintenance.detail.withoutcascade' | translate}}
          </label>
          <label class="btn btn-sm button-toggle-options" data-cy="offer-maintenance.domestic" [ngClass]="{'active': selectedCascade === 'DOMESTIC'}">
            <input type="radio" [value]="'DOMESTIC'" [(ngModel)]="selectedCascade"
                   (click)="selectDomesticCascade()"> {{'offer-maintenance.detail.domestic' | translate}}
          </label>
          <label class="btn btn-sm button-toggle-options" data-cy="offer-maintenance.individual" [ngClass]="{'active': selectedCascade === 'INDIVIDUAL'}">
            <input type="radio" [value]="'INDIVIDUAL'" [(ngModel)]="selectedCascade"
                   (click)="selectIndividualCascade()"> {{'offer-maintenance.detail.individual' | translate}}
          </label>
        </div>
      </div>

      <ng-container *ngFor="let entry of accessGroupSettings | keyvalue">
        <div class="mt-3">
          <div *ngIf="entry.key !== SCRAP_DISPOSAL" class="col-12 mt-2 ps-0 access-group" data-cy="offer-maintenance.cascade">
            <ucs-icon (click)="canToggleAccessGroup(entry.key) ? toggleAccessGroup(entry.key) : '';"
                      [shape]="evaluateCheckboxShape(entry.key, entry.value)"
                      [class]="canToggleAccessGroup(entry.key) ? (isStatusCancelledOrExpired ? 'disabled-grey' : 'blue') : 'disabled-blue'"></ucs-icon>
            <span class="ms-2 ucs-input-label" [ngClass]="{'disabled-text':isStatusCancelledOrExpired && entry.value === false}"
                  (click)="canToggleAccessGroup(entry.key) ? toggleAccessGroup(entry.key) : '';">
              {{'offer-maintenance.detail.access-group.' + entry.key | translate}}</span>
          </div>
        </div>
      </ng-container>
      <div class="mt-4">
        <ucs-icon (click)="canToggleScrapDisposal() ? toggleAccessGroup(SCRAP_DISPOSAL) : '';"
                  [shape]="evaluateScrapDisposalCheckboxShape()"
                  [class]="canToggleScrapDisposal() ? (isStatusCancelledOrExpired ? 'disabled-grey' : 'blue') : 'disabled-blue'"></ucs-icon>
        <span class="ms-2 ucs-input-label" [ngClass]="{'disabled-text':isStatusCancelledOrExpired}"
              (click)="canToggleScrapDisposal() ? toggleAccessGroup(SCRAP_DISPOSAL) : '';">
                {{'offer-maintenance.detail.access-group.' + SCRAP_DISPOSAL | translate}}</span>
      </div>
      <button *ngIf="isStatusPreparation" type="button" class="col-12 btn btn-secondary mt-3" (click)="resetAccessGroupSelection()">
        {{'offer-maintenance.detail.button.deselect' | translate}}
      </button>
    </div>

    <ucs-dealer-autocomplete *ngIf="offerDto.offerType.data === 'BUYNOW' && offerDto.status.data === 'PREPARATION'"
                             class="d-inline-block custom-autocomplete-layout pt-2"
                             [sourceFunction]="dealerAutoCompleteSourceFunction"
                             [channel]="offerDto.channel.data"
                             [sidebar]=true
                             [label]="'initial-proposal.dialog.dealer' | translate"
                             [placeholder]="''"
                             (onSelected)="addDealer($event)">
    </ucs-dealer-autocomplete>
    <div *ngIf="offerDto.offerType.data === 'BUYNOW'" class="d-flex">
      <strong class="mt-3 col col-4">{{ 'offer-maintenance.detail.selected-dealer' | translate }}</strong>
      <div *ngIf="addedDealers?.length < 1" class="mt-3 col col-6">{{ 'offer-maintenance.detail.no-dealer-selection' |
        translate }}
      </div>
      <ul *ngIf="addedDealers?.length > 0" class="mt-3 col col-6">
        <li *ngFor="let dealer of addedDealers"><span
          class="dealer-name">{{ dealerService.getCompanyNumberOrPbvCustomerNumber(dealer, offerDto?.channel?.data) + ' ' + dealer.name }}</span>
          <ucs-icon *ngIf="offerDto.status.data === 'PREPARATION'" class="delete ps-1" [shape]="'x'" [size]="24" [class]="'black'"
                    (click)="removeDealer(dealer)"></ucs-icon>
        </li>
      </ul>
    </div>
    <div
      *ngIf="offerDto.offerType.data === 'BUYNOW'  && offerDto.status.data === 'PREPARATION' && offerUpdateDto.directSaleDealerIds?.length > 0"
      class="mt-3">
      <div class="col-12 mt-2 ps-0">
        <ucs-icon (click)="offerUpdateDto.notifyDirectSaleDealers = !offerUpdateDto.notifyDirectSaleDealers"
                  [shape]="offerUpdateDto.notifyDirectSaleDealers ? 'square_checked' : 'square_outline'"
                  [class]="'blue'"></ucs-icon>
        <span class="ms-2">{{'offer-maintenance.detail.notify-direct-sale-dealers' | translate}}</span>
      </div>
    </div>


    <div *ngIf="showNotifyDeliveryDealer" class="col-12 mt-3 ps-0">
      <ucs-icon (click)="offerUpdateDto.notifyDeliveryDealer = !offerUpdateDto.notifyDeliveryDealer"
                [shape]="offerUpdateDto.notifyDeliveryDealer ? 'square_checked' : 'square_outline'"
                [class]="'blue'"></ucs-icon>
      <span class="ms-2">{{'offer-maintenance.detail.notify-delivery-dealer' | translate}}</span>
    </div>
  </div>
</div>
