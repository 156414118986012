<div [ngClass]="{ 'row':true, 'mb-3':hint === undefined, 'mb-2':hint !== undefined}">
  <div class="col-12 col-md-5" [ngClass]="{'tooltip-label': tooltipOverLabel}">
    <span [popper]="tooltipOverLabel" [popperTrigger]="NgxPopperjsTriggers.hover" [popperDisabled]="!tooltipOverLabel">
      {{label}}
    </span>
  </div>
  <div class="col-12 col-md-2 price">
    <div *ngIf="showNetPriceUnavailableInfo; else showPrice">
      <span class="no-net-price">
              <ucs-icon [shape]="'alert-triangle'" [size]="18"
                        [class]="'grey'" class="warning-no-net-price"></ucs-icon>
        {{'label-price.price-unknown' | translate}}
      </span>
    </div>
    <ng-template #showPrice>
      <span class="float-left">{{ currency }}</span>
      <span class="float-right">
        {{ price | price: undefined : undefined :  '1.2-2' }}
      </span>
    </ng-template>
  </div>
  <div *ngIf="sumIncalculable">
    <ucs-icon [shape]="'alert-triangle'" [size]="18"
              [class]="'grey'"></ucs-icon>
    {{ 'label-price.sum-incalculable' | translate}}
  </div>
</div>
<div *ngIf="hint !== undefined" class="row mb-3 hint">
  <div class="col-12">
    {{hint}}
  </div>
</div>

