<div class=" d-flex justify-content-between ms-auto shift-up ">
    <div class="d-flex">
        <div *ngFor="let equipment of equipmentList" >
                <ucs-icon *ngIf="hasHighlightEquipment(equipment.equipment)"
                      [shape]="equipment.shape" [class]="'grey opacity-05 me-2'"
                      [popper]="equipment.key | translate"
                      [popperTrigger]="NgxPopperjsTriggers.hover"></ucs-icon>
        </div>
    </div>
    <div>
        <ucs-icon *ngIf="showEtbLongTimePending"
                  [shape]="'etb_logo'" [class]="'grey'"
                  [popper]="'vehicle.etb-present' | translate"
                  [popperTrigger]="'hover'"></ucs-icon>
    </div>
</div>
