<div *ngIf="vehicle" class="row footer rounded-bottom">
  <div class="left-side col {{ vehicle?.offerDataDto?.grossProfit ? 'col-6' : 'col-12'}} col-sm-auto">
    <!--LEFT-->
    <ucs-vehicle-status
      *ngIf="['DATA_INCOMPLETE', 'READY_FOR_OFFER_PHASE', 'BUYNOW_PREPARATION', 'AUCTION_PREPARATION',
       'BUYNOW_CANCELLED', 'AUCTION_CANCELLED'].includes(vehicleFooterStatus)"
      [vehicle]="vehicle"></ucs-vehicle-status>
  </div>
  <!-- RIGHT -->
  <div class="col ms-sm-auto col-12 col-sm-auto pe-2" *ngIf="vehicleFooterStatus !== 'UNKNOWN'">
    <span
      [class]="'status-text row me-0 ms-0 ' + vehicleFooterStatus">{{ ('vehicle-footer.' + vehicleFooterStatus) | translate }}</span>
      <div class="d-flex me-0 ms-0 offer-create-buttons" *ngIf="vehicleFooterStatus === 'READY_FOR_OFFER_PHASE'">
        <ng-container class="buy" *ngIf="mayCreateBuyNow">
          <button
            class="btn btn-primary btn-buynow align-items-center pt-2 pe-3 pb-2 ps-3"
            (click)="createOffer('BUYNOW')">
            <strong>{{ 'vehicle-maintenance.button-buynow' | translate }}</strong>
          </button>
        </ng-container>
        <ng-container class="buy" *ngIf="mayCreateAuction">
          <button
            class="btn btn-primary btn-auction align-items-center pt-2 pe-3 pb-2 ps-3"
            (click)="createOffer('AUCTION')">
            <strong>{{ 'vehicle-maintenance.button-auction' | translate }}</strong>
          </button>
        </ng-container>
      </div>
  </div>
</div>
