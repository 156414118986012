<div class="row mt-3 mb-2" *ngIf="label !== undefined">
  <div class="col-12 text">
    <strong>{{label}}</strong>
  </div>
</div>

<div [ngClass]="{
  'row':true,
  'mt-2':label === undefined,
  'mb-2':label === undefined}">
  <div [class]="inputWithSearchImage === true ? 'input-with-image' : ''" class="col-12">
    <input [class]="validationError === true ? 'no-valid-input ' + class : class"
           [(ngModel)]="selectedValue"
           [placeholder]="placeholder"
           [formControl]="simpleInputFormControl"
           (keyup)="keyUpEnter($event)"
           type="search"
           class="form-control text">
    <ucs-icon *ngIf="inputWithSearchImage" [size]="24" [shape]="'search'" (click)="search()"
              [ngClass]="validationError === true ? 'icon-disabled input-with-image-searchable-icon' : 'input-with-image-searchable-icon'">
    </ucs-icon>
    <ucs-icon *ngIf="inputWithInfoImage" class="info-icon" [shape]="'info'" [class]="'blue'" [size]="24"
              [popper]="infoImageText" [popperPlacement]="NgxPopperjsPlacements.BOTTOM">
    </ucs-icon>
  </div>
</div>
<div *ngIf="validationError && minCharacterCount > 0" class="row col-12 mt-1">
  <span class="error">{{ 'vehicle-maintenance.search.validation.text-part-1' | translate }}
    {{this.minCharacterCount}} {{ 'vehicle-maintenance.search.validation.text-part-2' | translate }}</span>
</div>
