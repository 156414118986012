import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import * as fromRoot from '../../shared/store/app.reducers';
import {first, takeUntil} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {SystemSettingsService} from '../../shared/service/system-settings.service';
import {APP_CONFIG} from '../../shared/misc/inject-tokens';
import {AppConfig} from '../../shared/model/app-config.model';
import {
  CarouselLibConfig,
  DescriptionStrategy,
  GalleryModule,
  GridLayout,
  Image,
  ModalGalleryComponent,
  ModalGalleryService,
  ModalLibConfig,
  PlainGalleryStrategy,
  PlainLibConfig
} from '@ks89/angular-modal-gallery';
import {Subject} from 'rxjs';
import {InternalLibImage} from '@ks89/angular-modal-gallery/lib/model/image-internal.class';
import {CommonModule} from '@angular/common';

@Component({
  standalone: true,
  selector: 'ucs-detail-gallery-dialog',
  imports: [
    CommonModule,
    GalleryModule
  ],
  templateUrl: './detail-gallery.component.html',
  styleUrls: ['./detail-gallery.component.scss']
})
export class DetailGalleryComponent implements OnInit, OnChanges, OnDestroy {
  @Input() images: MediaInfoDto[];
  @Input() channel: DistributionChannel;
  @Input() appScope: AppScope;
  @Input() galleryId: number;
  @ViewChild('galleryModal', {static: true}) galleryModal: ElementRef;
  @ViewChild('carouselGallery', {static: false}) carouselGallery: ModalGalleryComponent;
  @ViewChild('modalCarouselGallery', {static: false}) modalCarouselGallery: ModalGalleryComponent;
  galleryImages: Image[] = [];
  isImageGalleryConfigEnabled: boolean;
  igoHeight: string;
  igoThumbnailsColumn: number;
  igoThumbnailsRows: number;
  igoImage: boolean;
  igoMoveSize: number;
  imageGallerySettings: ImageGalleryConfig;

  thumbnailCarouselConfig: CarouselLibConfig = {
    carouselImageConfig: {
      description: {
        strategy: DescriptionStrategy.ALWAYS_VISIBLE,
        imageText: ''
      },
    },
    carouselDotsConfig: {
      visible: false
    },
    carouselPreviewsConfig: {
      visible: false
    },
    carouselPlayConfig: {
      autoPlay: false,
      interval: 3000,
      pauseOnHover: true
    }
  };
  carouselGalleryConfig: CarouselLibConfig = {
    carouselImageConfig: {
      description: {
        strategy: DescriptionStrategy.ALWAYS_VISIBLE,
        imageText: ''
      },
    },
    carouselDotsConfig: {
      visible: false
    },
    carouselPlayConfig: {
      autoPlay: false,
      interval: 3000,
      pauseOnHover: true
    },
    carouselPreviewsConfig: {
      visible: true,
      number: 5,
      width: 'auto',
      maxHeight: '100px'
    },
    carouselConfig: {
      maxWidth: '100%',
      maxHeight: '70vh',
      showArrows: true,
      objectFit: 'cover',
      keyboardEnable: true,
      modalGalleryEnable: true
    }
  };
  plainGalleryConfig: PlainLibConfig;
  plainGalleryThumbnailWidth: number;
  galleryViewInScope: boolean;

  //We have to use the same ID for galleries in the same view since otherwise
  //the gallery config won't be updated when navigating to other offers (without loading a new view)
  //This is due to the fact that the config is stored in the configService which is only updated
  //in ngOnInit of the CarouselComponent and not in the ngOnChanges (commented out on line 294 in carousel.component.ts)
  DETAIL_THUMBNAIL_CAROUSEL_ID = 1001; //random number
  DETAIL_PLAIN_GALLERY_ID = 1002; //random number
  DETAIL_CAROUSEL_GALLERY_ID = 1003; //random number

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(private store: Store<fromRoot.AppState>, private translateService: TranslateService,
              private systemSettings: SystemSettingsService, @Inject(APP_CONFIG) private config: AppConfig,
              private modalGalleryService: ModalGalleryService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (propName === 'images') {
        //Await translateService to load translations so the image description can be translated
        this.translateService.get('vms.view-types.OTHER').pipe(first())
          .subscribe(value => this.initImages());
      }
    }
  }

  ngOnInit(): void {
    this.systemSettings.isSystemFeatureActivatedForAnyChannel('IMAGE_GALLERY_CONFIG')
      .pipe().subscribe(isEnabled => {
        this.isImageGalleryConfigEnabled = isEnabled;

        if (this.isImageGalleryConfigEnabled) {
          this.store.select(fromRoot.getUserSettings)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(settings => {
              const galleryConfigSettings = settings?.find(setting => setting.propertyKey === 'IMAGE_GALLERY_CONFIG');
              if (!galleryConfigSettings) {
                this.systemSettings.getSystemFeatureSettingForChannel('IMAGE_GALLERY_CONFIG.image_gallery_config', this.channel)
                  .pipe(first())
                  .subscribe(featureSettings => {
                    this.imageGallerySettings = JSON.parse(featureSettings);
                    this.checkAndSetImageGalleryOptions();
                  });
              } else {
                this.imageGallerySettings = JSON.parse(galleryConfigSettings.value);
                this.checkAndSetImageGalleryOptions();
              }
            });
        } else {
        //Carousel
          this.setSeriesViewOptions();
        }
      });

  }

  private checkAndSetImageGalleryOptions() {

    this.galleryViewInScope =
      (this.appScope === 'SALES_STORE' && this.imageGallerySettings.salesShowGalleryView)
      || (this.appScope === 'SALES_MAINTENANCE' && this.imageGallerySettings.maintenanceShowGalleryView);

    if (this.galleryViewInScope) {
      const options: ImageGalleryOptions = this.imageGallerySettings.imageGalleryConfig[this.appScope].find(option => option.enabled);
      this.setGalleryViewOptions(options.columns, options.rows);
    } else {
      //Carousel
      this.setSeriesViewOptions();
    }
    this.setupPlainGalleryConfig();
  }

  private setSeriesViewOptions() {
    this.igoHeight = window.innerHeight - 200 + 'px';
    this.igoThumbnailsColumn = 6;
    this.igoThumbnailsRows = 1;
    this.igoImage = true;
    this.igoMoveSize = 1;
  }

  private setGalleryViewOptions(rows: number, columns: number) {
    this.igoHeight = window.innerHeight - 400 + 'px';
    this.igoThumbnailsColumn = columns; //user-setting.column
    this.igoThumbnailsRows = rows; // user-settings.rows
    this.igoImage = false;
    this.igoMoveSize = columns;
  }


  /**
   * (Re-)Initializes the images
   */
  initImages() {
    this.galleryImages = [];
    let galleryImageIndex = 0;
    let categoryImageCounter: number[] = [];
    this.images.forEach(image => {
      galleryImageIndex++;
      if (image.type.data === 'IMAGE' && image.imageCategory) {
        categoryImageCounter[image.imageCategory] = categoryImageCounter[image.imageCategory] ?
          categoryImageCounter[image.imageCategory] + 1 : 1;
      }

      if (image?.url) {
        let carouselImageDescription = '';
        if(image.imageCategory !== null) {
          carouselImageDescription = this.translateService.instant('vms.view-types.' + image.imageCategory);
        }
        let imageUrl = image.url;
        if (image.scalable) {
          imageUrl += '/1024';
        }
        this.galleryImages.push(new Image(image.id,
          {
            img: imageUrl,
            description: carouselImageDescription,
            fallbackImg: '/assets/icons/ucs_fallback_img.svg'
          }));
      }
    });

    //We have to set the current image again since otherwise it won't change after
    //navigating to another offer
    if (this.carouselGallery) {
      this.carouselGallery.currentImage = this.galleryImages[0] as InternalLibImage;
    }
    if (this.modalCarouselGallery) {
      this.modalCarouselGallery.currentImage = this.galleryImages[0] as InternalLibImage;
    }
  }

  private setupPlainGalleryConfig() {
    this.calculatePlainGalleryThumbnailSize();
    this.plainGalleryConfig = {
      plainGalleryConfig: {
        strategy: PlainGalleryStrategy.GRID,
        layout: new GridLayout({
          width: this.plainGalleryThumbnailWidth + 'px',
          height: this.calculatePlainGalleryThumbnailHeight() + 'px'
        }, {length: this.igoThumbnailsColumn, wrap: true})
      },
    };
  }

  /**
   * Listens to window:resize events to resize images in gallery
   */
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setupPlainGalleryConfig();
  }

  calculatePlainGalleryThumbnailSize() {
    //First calculate the modal size: 90% of the window size
    //Then subtract 32 since the gallery has a padding of 16px per side
    //Additionally, subtract 11 (4px thumbnail margin plus 3px scrollbar)
    this.plainGalleryThumbnailWidth = ((window.innerWidth * 0.9 - 32) / this.igoThumbnailsColumn) - 11;
  }

  calculatePlainGalleryThumbnailHeight(): number {
    let ratio = 16 / 9;
    return this.plainGalleryThumbnailWidth / ratio;
  }

  onShow(id: number, index: number, images: Image[] = this.galleryImages): void {
    this.modalGalleryService.close(id, false);
    this.modalGalleryService.open({
      id,
      images,
      currentImage: images[index],
      libConfig: {
        currentImageConfig: {
          description: {
            strategy: DescriptionStrategy.ALWAYS_VISIBLE,
            imageText: '',
          },
        },
        slideConfig: {
          infinite: true,
          sidePreviews: {
            show: false
          }
        },
        previewConfig: {
          visible: false
        },
        dotsConfig: {
          visible: false
        }
      } as ModalLibConfig
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
