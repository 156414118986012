<!-- first row -->
<ng-container *ngIf="userInfo">
  <ucs-notification *ngIf="showUserNotifications"></ucs-notification>

  <nav class="navbar topbar navbar-expand-md header p-0">
    <a routerLink="/" class="navbar-brand pe-3">
      <ucs-icon [shape]="'ucs_logo'" [class]="'white'" data-cy='ucs-logo'></ucs-icon>
    </a>
    <div class="navbar-toggler menu-right">
      <ucs-icon *ngIf="!userInfo.locked" class="d-inline-block" [shape]="'user'"
                [class]="'white'" (click)="showMobileUserMenu = !showMobileUserMenu"></ucs-icon>
      <ucs-icon *ngIf="userInfo.locked" class="d-inline-block" [shape]="'user'"
                [class]="'red'" (click)="showMobileUserMenu = !showMobileUserMenu"></ucs-icon>
      <ucs-icon class="d-none" [shape]="'notification'" [class]="'white'"></ucs-icon>
      <button id="burger-menu-toggle" class="navbar-toggler" type="button" data-bs-toggle="collapse"
              data-bs-target="#burger-menu-content"
              aria-controls="burger-menu-content" aria-expanded="false" aria-label="Toggle navigation">
        <ucs-icon [shape]="'menu_bars_burger'" [class]="'white'"></ucs-icon>
      </button>

      <span class="dropdown">
        <button class="btn local dropdown-toggle position-relative" type="button" data-bs-toggle="dropdown">
          {{'top-menu.dropdownLocal.' + actualLocal | translate}}
        </button>
        <div class="dropdown-menu local-menu">
          <span class="dropdown-item" *ngFor="let language of languages"
                (click)="changeLocal(language.value)">{{language.name | translate}}</span>
          <span class="dropdown-item" value="de-de-key"
                (click)="changeLocal('de-de-key')">{{ 'top-menu.dropdownLocal.de-de-key' | translate }}</span>
        </div>
      </span>
    </div>

    <div class="collapse navbar-collapse">
      <ul class="navbar-nav me-auto menu-left text-topbar">
        <li *ngIf="isBuyer" class="nav-item">
          <a class="d-none d-md-inline-block button-navbar pe-3 py-2 hover-underline"
             routerLink="/offer" [ngClass]="{'underline': isInSalesScope()}"
             (click)="subMenuOffer()"
             data-cy="top-menu.button.buy">
            {{ 'top-menu.buy' | translate }}
          </a>
        </li>
        <li *ngIf="(isSeller || isEnforcedAuctionUser) && isNotLocked" class="nav-item">
          <a class="d-none d-md-inline-block button-navbar pe-3 py-2 hover-underline"
             routerLink="/maintenance/vehicle" [ngClass]="{'underline': currentRoute?.startsWith('/maintenance/')}"
             (click)="subMenuSell()"
             data-cy="top-menu.button.sell">
            {{ 'top-menu.sell' | translate }}
          </a>
        </li>
        <li *ngIf="isAdmin || isAllowedToSeeSettings" class="nav-item">
          <a class="d-none d-md-inline-block button-navbar pe-3 py-2 hover-underline"
             data-cy="top-menu.button.admin"
             routerLink="/administration/{{administrationRoute}}"
             [ngClass]="{'underline': currentRoute?.startsWith('/administration/')}">
            {{ 'top-menu.administration' | translate }}
          </a>
        </li>
      </ul>

      <ul class="navbar-nav mt-2 ms-auto menu-right">
        <div class="popover-container me-5"
             [popper]="userMenu"
             [popperPlacement]="NgxPopperjsPlacements.BOTTOM"
             [popperApplyClass]="'user-menu-popover'">
          <li class="nav-item">
            <span *ngIf="userState?.userInfo" class="username" data-cy="top-menu.userName"
                  id="top-menu.userName">{{ userInfo.firstName + ' ' + userInfo.lastName | truncate:29 }}</span>
            <ucs-icon *ngIf="!userInfo.locked" class="me-3 d-inline-block" [shape]="'user'"
                      [class]="'white'"></ucs-icon>
            <ucs-icon *ngIf="userInfo.locked" class="me-3 d-inline-block" [shape]="'user'" [class]="'red'"></ucs-icon>
          </li>
          <li class="nav-item">
            <span *ngIf="userState?.userInfo" class="dealer" id="top-menu.currentDealer">
              {{ getCompanyNumberOrPbvCustomerNumber(currentDealer) + ' ' + currentDealer.name | truncate: 38 }}
            </span>
          </li>
        </div>

        <!-- removed notification icon for now, because it is not implemented -->
        <li class="d-none nav-item">
          <ucs-icon class="d-none d-md-inline-block me-3" [shape]="'notification'" [class]="'white'"></ucs-icon>
        </li>
      </ul>

      <div class="dropdown me-3">
        <button class="btn local dropdown-toggle position-relative" type="button" data-bs-toggle="dropdown" data-cy="language-button">
          {{'top-menu.dropdownLocal.' + actualLocal | translate}}
        </button>
        <div class="dropdown-menu local-menu">
          <span class="dropdown-item" data-cy="language-entry" *ngFor="let language of languages"
                (click)="changeLocal(language.value)">{{language.name | translate}}</span>
          <span class="dropdown-item" value="de-de-key"
                (click)="changeLocal('de-de-key')">{{ 'top-menu.dropdownLocal.de-de-key' | translate }}</span>
        </div>
      </div>

    </div>

    <popper-content class="user-menu-popover user-menu-content" #userMenu>
      <div class="user-menu-font">
        <p>
          <span *ngIf="userInfo.locked" class="warning">
            <ucs-icon [shape]="'warning'" [class]="'red'"></ucs-icon>
            {{ userInfo.lockReason }}
          </span><br>
          {{ getCompanyNumberOrPbvCustomerNumber(currentDealer) + ' ' + currentDealer.name }}<br/>
          {{ userInfo.address }}<br>
          {{ userInfo.zipCode + ' ' + userInfo.city }}
        </p>
        <p><a routerLink="/user-settings">
          <ucs-icon shape="settings" class="blue"></ucs-icon>
          {{ 'top-menu.user-settings' | translate }}</a></p>

        <ng-select *ngIf="userInfo.dealers.length > 1"
                   [items]="userInfo.dealers"
                   [searchable]="true"
                   bindLabel="displayName"
                   bindValue="id"
                   [(ngModel)]="selectedDealerId"
                   (change)="dealerChange($event)"
                   [placeholder]="currentDealer['displayName']">
        </ng-select>
        <hr>
        <a class="logout" routerLink="/logout">
          <ucs-icon [shape]="'log-out'" [class]="'black'"></ucs-icon>
          <span class="logout-text">Logout</span>
        </a>
      </div>
    </popper-content>
  </nav>

  <!-- second row buy-->
  <nav *ngIf="showSubMenuOffer && isBuyer" class="d-none d-md-flex navbar navbar-expand header p-0">
    <ul class="navbar navbar-nav topbar topbar--small menu-left text-topbar text-topbar--small pe-3 pt-2 pb-0">
      <li *ngIf="isNotLocked && !needGtcApproval" class="nav-item me-2">
        <a routerLink="/" class="nav-link button-navbar"
           [ngClass]="{'active': currentRoute === '/'}"
           data-cy="top-menu.tabs.new-search">
          {{ 'top-menu.new-search' | translate }}

        </a>
      </li>
      <li *ngIf="isNotLocked && !needGtcApproval" class="nav-item me-2">
        <a routerLink="/saved-search" class="nav-link button-navbar"
           [ngClass]="{'active': currentRoute === '/saved-search'}"
           data-cy="top-menu.tabs.saved-search"
        >
          {{ 'quick-search.saved-searches' | translate }}
        </a>
      </li>
      <li *ngIf="isNotLocked && !needGtcApproval" class="nav-item me-2">
        <a routerLink="/offer" class="nav-link button-navbar"
           [ngClass]="{'active': !currentRoute.includes('/offer/') && currentRoute.includes('/offer')}"
           data-cy="top-menu.tabs.search"
        >
          {{ 'top-menu.search' | translate }}
        </a>
      </li>
      <li *ngIf="isNotLocked && !needGtcApproval && isEnforcedAuctionToggleEnabled && isEnforcedAuctionSettingEnabled"
          class="nav-item me-2">
        <a routerLink="/pia-auction" class="nav-link button-navbar"
           [ngClass]="{'active': currentRoute === '/pia-auction'}"
           data-cy="top-menu.tabs.pia-auction">
          {{ 'top-menu.pia-auction' | translate }}
        </a>
      </li>
      <li *ngIf="isNotLocked && !needGtcApproval" class="nav-item me-2">
        <a routerLink="/bookmarks" class="nav-link button-navbar"
           [ngClass]="{'active': currentRoute.includes('/bookmarks')}"
           data-cy="top-menu.tabs.bookmarks"
        >
          {{ 'top-menu.all-saved' | translate }}
        </a>
      </li>
      <li *ngIf="isNotLocked && !needGtcApproval" class="nav-item me-2">
        <a routerLink="/my-auctions" class="nav-link button-navbar"
           [ngClass]="{'active': currentRoute.includes('/my-auctions')}"
           data-cy="top-menu.tabs.my-auctions"
        >
          {{ 'top-menu.my-auctions' | translate }}
        </a>
      </li>
      <li class="nav-item me-2">
        <a routerLink="/finished-offers" class="nav-link button-navbar"
           [ngClass]="{'active': currentRoute.includes('/finished-offers')}"
           data-cy="top-menu.tabs.finished-offers"
        >
          {{ 'top-menu.finished-offers' | translate }}
        </a>
      </li>
    </ul>
  </nav>

  <!-- second row sell-->
  <nav *ngIf="showSubMenuSell && (isSeller || isEnforcedAuctionUser)" class="d-none d-md-flex navbar navbar-expand header p-0">
    <ul class="navbar navbar-nav topbar topbar--small menu-left text-topbar text-topbar--small pe-3 pt-2 pb-0">
      <li class="nav-item me-2">
        <a routerLink="/maintenance/vehicle" class="nav-link button-navbar" data-cy="top-menu.tabs.vehicle"
           [ngClass]="{'active': currentRoute?.startsWith('/maintenance/vehicle')}">
          {{ 'top-menu.vehicle' | translate }}
        </a>
      </li>
      <li *ngIf="isAllowedToSeeOfferMaintenance" class="nav-item me-2">
        <a routerLink="/maintenance/offer" class="nav-link button-navbar" data-cy="top-menu.tabs.offer"
           [ngClass]="{'active': currentRoute?.startsWith('/maintenance/offer')}">
          {{ 'top-menu.offer' | translate }}
        </a>
      </li>
    </ul>
    <ul class="navbar navbar-nav ms-auto open-tasks menu-right pb-0">
      <li *ngIf="isCalenderActive" class="nav-item">
        <a  class="nav-link button-navbar pb-0" data-bs-toggle="modal" data-bs-target="#calendarModal">{{ 'ucs.calendar' | translate }}</a>
      </li>
      <li *ngIf="isAllowedToSeeOpenTasks" class="nav-item" (click)="onOpenTaskNavItemClick()">
        <a href="" class="nav-link button-navbar pb-0" data-bs-toggle="modal"
           data-bs-target="#openTasksModal">{{ 'vehicle-maintenance.open-tasks.open-tasks' | translate }}</a>
        <span *ngIf="numberOfOpenTasks && numberOfOpenTasks > 0"
              class="badge badge-pill bg-danger number-of-opentasks position-absolute">{{ numberOfOpenTasks }}</span>
      </li>
    </ul>
  </nav>

  <!-- second row administration -->
  <nav *ngIf="showSubMenuAdministration && (isAdmin || isAllowedToSeeSettings)"
       class="d-none d-md-flex navbar navbar-expand header p-0">
    <ul class="navbar navbar-nav topbar topbar--small menu-left text-topbar text-topbar--small pe-3 pt-2 pb-0">
      <li *ngIf="isAdmin" class="nav-item me-2">
        <a routerLink="/administration/reporting" class="nav-link button-navbar" data-cy="top-menu.tabs.reporting"
           [ngClass]="{'active': currentRoute?.startsWith('/administration/reporting')}">
          {{ 'top-menu.reporting' | translate }}
        </a>
      </li>
      <li *ngIf="isAdmin" class="nav-item me-2">
        <a routerLink="/administration/gdpr" class="nav-link button-navbar" data-cy="top-menu.tabs.gdpr"
           [ngClass]="{'active': currentRoute?.startsWith('/administration/gdpr')}">
          {{ 'top-menu.gdpr' | translate }}
        </a>
      </li>
      <li *ngIf="isAllowedToSeeSettings" class="nav-item me-2">
        <a routerLink="/administration/settings" class="nav-link button-navbar" data-cy="top-menu.tabs.settings"
           [ngClass]="{'active': currentRoute?.startsWith('/administration/settings')}">
          {{ 'top-menu.settings' | translate }}
        </a>
      </li>
      <li *ngIf="isAdmin" class="nav-item me-2">
        <a routerLink="/administration/system-settings" class="nav-link button-navbar"
           data-cy="top-menu.tabs.system-settings"
           [ngClass]="{'active': currentRoute?.startsWith('/administration/system-settings')}">
          {{ 'top-menu.other' | translate }}
        </a>
      </li>
      <li *ngIf="isAllowedSeeDealerDocuments" class="nav-item me-2">
        <a routerLink="/administration/dealer-documents" class="nav-link button-navbar"
           data-cy="top-menu.tabs.dealer-documents"
           [ngClass]="{'active': currentRoute?.startsWith('/administration/dealer-documents')}">
          {{ 'top-menu.dealer-documents' | translate }}
        </a>
      </li>
      <li *ngIf="isAllowedToSeeDealerLocking" class="nav-item me-2">
        <a routerLink="/administration/dealer-locking" class="nav-link button-navbar"
           data-cy="top-menu.tabs.dealer-locking"
           [ngClass]="{'active': currentRoute?.startsWith('/administration/dealer-locking')}">
          {{ 'top-menu.dealer-locking' | translate }}
        </a>
      </li>
      <li *ngIf="userState.isDevPoi || userState.isSupportPoi" class="nav-item me-2">
        <a routerLink="/administration/job-monitoring" class="nav-link button-navbar"
           data-cy="top-menu.tabs.job-monitoring"
           [ngClass]="{'active': currentRoute?.startsWith('/administration/job-monitoring')}">
          {{ 'top-menu.job-monitoring' | translate }}
        </a>
      </li>
      <li *ngIf="userState.isDevPoi" class="nav-item me-2">
        <a routerLink="/administration/temporary-features" class="nav-link button-navbar"
           data-cy="top-menu.tabs.temporary-features"
           [ngClass]="{'active': currentRoute?.startsWith('/administration/temporary-features')}">
          {{ 'top-menu.temporary-features' | translate }}
        </a>
      </li>
    </ul>
  </nav>

  <!-- burger menu -->
  <div id="burger-menu-content" class="d-md-none collapse navbar-collapse">
    <ul class="navbar-nav me-auto menu-left text-topbar pt-2">
      <li *ngIf="isBuyer">
        <a (click)="hideBurgerMenuContent()" class="button-navbar ps-3 py-2" routerLink="/offer">
          {{ 'top-menu.buy' | translate }}
        </a>
        <ul class="navbar-nav inner-list px-4 py-1">
          <li *ngIf="isNotLocked && !needGtcApproval" class="nav-item">
            <a (click)="hideBurgerMenuContent()" routerLink="/" class="nav-link button-navbar ps-1"
               [ngClass]="{'active': currentRoute === '/'}">
              {{ 'top-menu.new-search' | translate }}
            </a>
          </li>
          <li *ngIf="isNotLocked && !needGtcApproval" class="nav-item me-2">
            <a (click)="hideBurgerMenuContent()" routerLink="/saved-search" class="nav-link button-navbar"
               [ngClass]="{'active': currentRoute === '/saved-search'}">
              {{ 'quick-search.saved-searches' | translate }}
            </a>
          </li>
          <li *ngIf="isNotLocked && !needGtcApproval" class="nav-item">
            <a (click)="hideBurgerMenuContent()" routerLink="/offer" class="nav-link button-navbar ps-1"
               [ngClass]="{'active': currentRoute === '/offer'}">
              {{ 'top-menu.search' | translate }}
            </a>
          </li>
          <li *ngIf="isNotLocked && !needGtcApproval && isEnforcedAuctionToggleEnabled && isEnforcedAuctionSettingEnabled"
              class="nav-item">
            <a (click)="hideBurgerMenuContent()" routerLink="/pia-auction" class="nav-link button-navbar ps-1"
               [ngClass]="{'active': currentRoute === '/pia-auction'}">
              {{ 'top-menu.pia-auction' | translate }}
            </a>
          </li>
          <li *ngIf="isNotLocked && !needGtcApproval" class="nav-item">
            <a (click)="hideBurgerMenuContent()" routerLink="/bookmarks" class="nav-link button-navbar ps-1">
              {{ 'top-menu.all-saved' | translate }}
            </a>
          </li>
          <li *ngIf="isNotLocked && !needGtcApproval" class="nav-item">
            <a (click)="hideBurgerMenuContent()" routerLink="/my-auctions" class="nav-link button-navbar ps-1"
               [ngClass]="{'active': currentRoute === '/my-auctions'}">
              {{ 'top-menu.my-auctions' | translate }}
            </a>
          </li>
          <li class="nav-item">
            <a (click)="hideBurgerMenuContent()" routerLink="/finished-offers" class="nav-link button-navbar ps-1"
               [ngClass]="{'active': currentRoute === '/finished-offers'}">
              {{ 'top-menu.finished-offers' | translate }}
            </a>
          </li>
        </ul>
      </li>
      <li *ngIf="(isSeller || isEnforcedAuctionUser) && isNotLocked">
        <a (click)="hideBurgerMenuContent()" class="button-navbar ps-3 py-2" href="#">
          {{ 'top-menu.sell' | translate }}
        </a>
        <ul class="navbar-nav inner-list px-4 py-1">
          <li class="nav-item">
            <a (click)="hideBurgerMenuContent()" routerLink="/maintenance/vehicle"
               [ngClass]="{'active': currentRoute?.startsWith('/maintenance/vehicle')}"
               class="nav-link button-navbar ps-1">
              {{ 'top-menu.vehicle' | translate }}

            </a>
          </li>
          <li class="nav-item">
            <a (click)="hideBurgerMenuContent()" routerLink="/maintenance/offer"
               [ngClass]="{'active': currentRoute?.startsWith('/maintenance/offer')}"
               class="nav-link button-navbar ps-1">
              {{ 'top-menu.offer' | translate }}
            </a>
          </li>
        </ul>
      </li>
      <li *ngIf="(isAdmin || isAllowedToSeeSettings) && isNotLocked">
        <a (click)="hideBurgerMenuContent()" class="button-navbar ps-3 py-2" href="administration">
          {{ 'top-menu.administration' | translate }}
        </a>
        <ul class="navbar-nav inner-list px-4 py-1">
          <li *ngIf="isAdmin" class="nav-item">
            <a (click)="hideBurgerMenuContent()" routerLink="/administration/reporting"
               class="nav-link button-navbar ps-1">
              {{ 'top-menu.reporting' | translate }}
            </a>
          </li>
          <li *ngIf="isAdmin" class="nav-item">
            <a (click)="hideBurgerMenuContent()" routerLink="/administration/gdpr"
               class="nav-link button-navbar ps-1">
              {{ 'top-menu.gdpr' | translate }}
            </a>
          </li>
          <li *ngIf="isAllowedToSeeSettings" class="nav-item">
            <a (click)="hideBurgerMenuContent()" routerLink="/administration/settings"
               class="nav-link button-navbar ps-1">
              {{ 'top-menu.settings' | translate }}
            </a>
          </li>
          <li *ngIf="isAdmin" class="nav-item">
            <a (click)="hideBurgerMenuContent()" routerLink="/administration/system-settings"
               class="nav-link button-navbar ps-1">
              {{ 'top-menu.other' | translate }}
            </a>
          </li>
          <li *ngIf="userState.canSellOffersPia" class="nav-item">
            <a (click)="hideBurgerMenuContent()" routerLink="/administration/dealer-documents"
               class="nav-link button-navbar ps-1">
              {{ 'top-menu.dealer-documents' | translate }}
            </a>
          </li>
          <li *ngIf="isAllowedToSeeDealerLocking" class="nav-item">
            <a (click)="hideBurgerMenuContent()" routerLink="/administration/dealer-locking"
               class="nav-link button-navbar ps-1">
              {{ 'top-menu.dealer-locking' | translate }}
            </a>
          </li>
          <li *ngIf="userState.isDevPoi || userState.isSupportPoi" class="nav-item">
            <a (click)="hideBurgerMenuContent()" routerLink="/administration/job-monitoring"
               class="nav-link button-navbar ps-1">
              {{ 'top-menu.job-monitoring' | translate }}
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
  <div *ngIf="showMobileUserMenu" class="user-menu-content mobile">
    <p>
      <span *ngIf="userInfo.locked" class="warning">
        <ucs-icon [shape]="'warning'" [class]="'red'"></ucs-icon>
        {{ userInfo.lockReason }}<br/>
      </span>
      {{ getCompanyNumberOrPbvCustomerNumber(currentDealer) + ' ' + currentDealer.name }}<br/>
      {{ userInfo.address }}<br/>
      {{ userInfo.zipCode + ' ' + userInfo.city }}</p>
    <p><a routerLink="/user-settings">
      <ucs-icon shape="settings" class="blue"></ucs-icon>
      {{ 'top-menu.user-settings' | translate }}</a></p>
    <ng-select *ngIf="userInfo.dealers.length > 1"
               [items]="userInfo.dealers"
               [searchable]="true"
               bindLabel="displayName"
               bindValue="id"
               [(ngModel)]="selectedDealerId"
               (change)="dealerChange($event)"
               [placeholder]="currentDealer['displayName']">
    </ng-select>
    <hr/>
    <a class="logout" routerLink="/logout">
      <ucs-icon [shape]="'log-out'" [class]="'black'"></ucs-icon>
      <span class="logout-text">Logout</span>
    </a>
  </div>
</ng-container>
<ucs-open-tasks *ngIf="isAllowedToSeeOpenTasks" [isSpinnerActive]="isSpinnerActive"></ucs-open-tasks>
<ucs-expiring-offer-calendar *ngIf="isCalenderActive" [currentChannel]="currentChannel"></ucs-expiring-offer-calendar>
