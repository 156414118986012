<!-- if its a followup proposal dialog -->
<button *ngIf="followup" class="btn button" data-bs-toggle="modal" (click)="createDeepCopyForFollowup()"
        [attr.data-bs-target]="'#proposalModal'+ custodyDealer?.id + idSuffix">
  <ucs-icon [shape]="'award'" [class]="'blue'"></ucs-icon>
  <strong *ngIf="!followupSmallBtn" class="ms-1">{{'offer-maintenance.detail.new-followup-proposal-price' | translate }}</strong>
</button>

<!-- if its a initial proposal dialog -->
<button *ngIf="initial" class="btn button" data-bs-toggle="modal"
        [attr.data-bs-target]="'#proposalModal' + custodyDealer?.id + idSuffix" (click)="checkExtraTaxPaid()">
  <ucs-icon [shape]="'plus-square'" [class]="'blue'"></ucs-icon>
  <strong class="ms-1">{{'vehicle-maintenance.detail.new-initial-proposal-price' | translate }}</strong>
</button>

<div class="modal fade" id="{{'proposalModal' + custodyDealer?.id + idSuffix}}" aria-labelledby="proposalModal"
     aria-hidden="true"
     tabindex="-1"
     role="dialog"
     data-cy="proposal-dialog.dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ name | translate }}</h5>
        <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" (click)="closed.emit()">
          <span aria-hidden="true"></span>
        </button>
      </div>

      <ng-container *ngIf="!manualInput">
        <div class="row ms-0">
          <div class="col-7">
            <ucs-dealer-autocomplete class="d-inline-block"
                                     [sourceFunction]="dealerAutoCompleteSourceFunction"
                                     [channel]="getChannel()"
                                     [sidebar]=true
                                     [label]="'initial-proposal.dialog.dealer' | translate"
                                     [placeholder]="''"
                                     (onSelected)="dealerSelected($event)">
            </ucs-dealer-autocomplete>

          </div>
          <div class="col-5 ps-3 mt-1">
            <button type="button" class="btn-popover mb-1" (click)="changeToInput()">
              <ucs-icon [shape]="'edit-2'"></ucs-icon>
              <strong>{{'initial-proposal.dialog.manual-input' | translate}}</strong>
            </button>
          </div>
        </div>

        <div class="col-12 ps-3 mt-3" data-cy="proposal-dialog.dealer-info" *ngIf="custodyDealer?.name">
          {{ custodyDealer?.companyNumber ? custodyDealer?.companyNumber + ' ' : ''}}{{ custodyDealer?.name }}<br/>
          {{ custodyDealer?.address ? custodyDealer?.address : '' }}<br/>
          {{ custodyDealer?.zipCode ? custodyDealer?.zipCode + ' ' + custodyDealer?.city : '' }}
        </div>

        <form #followupProposal="ngForm">
          <div class="col-12 px-3 mt-2" *ngIf="followup">
            <strong>{{'vehicle-maintenance.detail.initial-proposal.amount' | translate}}</strong>
            <div class="row">
              <div class="col-12 mt-2">
                <ucs-net-gross-input [netPrice]="popUpPrice?.net"
                                     [grossPrice]="popUpPrice?.gross"
                                     (backNetPriceChange)="popUpPrice.net = $event"
                                     (backGrossPriceChange)="popUpPrice.gross = $event"
                                     [currency]="popUpPrice?.currency.data"
                                     [hideGrossPrice]="!vehicleOrOfferDto.nationalSale"
                                     [vehicleOrOfferDto]="vehicleOrOfferDto"
                                     (inputErrorOutput)="getInputError($event)"
                                     [sectionScope]="'SALES_MAINTENANCE'">
                </ucs-net-gross-input>
              </div>
            </div>
            <div
              *ngIf="followupProposal.form.controls.amount?.invalid && (followupProposal.form.controls.amount?.touched || followupProposal.form.controls.amount?.dirty)"
              class="error">{{ 'ucs.error.no-number-or-to-high' | translate }}
            </div>
          </div>
        </form>

        <div class="col-12 ps-3 mt-3 mb-3">
          <input [(ngModel)]="proposalMake.sendNotification" [id]="'email-notification-' + idSuffix" type="checkbox">
          <label class="font-weight-bold"
                 [for]="'email-notification-' + idSuffix"><span
            class="ms-2 d-inline-block">{{'initial-proposal.dialog.mail' | translate}}</span></label>
        </div>
      </ng-container>

      <ng-container *ngIf="manualInput">
        <div class="row ms-0">
          <div class="col-7 text">
            <strong>{{'initial-proposal.dialog.dealer' | translate}}</strong>
          </div>
          <div class="col-5 ps-3 mt-1">
            <button type="button" class="btn-popover mb-1" (click)="changeToInput()">
              <ucs-icon [shape]="'search'" [class]="'blue'"></ucs-icon>
              <strong>{{'initial-proposal.dialog.search-dealer' | translate}}</strong>
            </button>
          </div>
        </div>

        <ucs-manual-dealer-input [vehicleId]="vehicleId" (externalDealerChanged)="dealerEntered($event)"
                                 (validityChanged)="manualInputValid = $event"></ucs-manual-dealer-input>

        <form #initialProposal="ngForm">
          <div class="col-12 px-3 mt-2" *ngIf="followup">
            <strong>{{'vehicle-maintenance.detail.initial-proposal.amount' | translate}}</strong>
            <div class="row">
              <div class="col-12 mt-2">
                <ucs-net-gross-input [netPrice]="popUpPrice.net"
                                     (backNetPriceChange)="popUpPrice.net = $event"
                                     [currency]="popUpPrice.currency.data"
                                     [hideGrossPrice]="!vehicleOrOfferDto.nationalSale"
                                     [vehicleOrOfferDto]="vehicleOrOfferDto"
                                     [sectionScope]="'SALES_MAINTENANCE'">
                </ucs-net-gross-input>
              </div>
            </div>
            <div
              *ngIf="initialProposal.form.controls.amount?.invalid && (initialProposal.form.controls.amount?.touched || initialProposal.form.controls.amount?.dirty)"
              class="error">{{ 'ucs.error.no-number-or-to-high' | translate }}
            </div>
          </div>
        </form>

      </ng-container>

      <div class="modal-footer">
        <button type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
                (click)="closed.emit()">{{ 'proposal.dialog.cancel' | translate }}
        </button>
        <button type="button"
                class="btn btn-primary align-items-center no-radius-right pt-2 pe-3 pb-2 ps-3 me-0"
                (click)="createProposal()"
                data-bs-dismiss="modal"
                data-cy="proposal-dialog.create"
                [disabled]="isSubmitButtonDisabled()">
          <strong>{{ 'proposal.dialog.create' | translate }}</strong>
        </button>
      </div>
    </div>
  </div>
</div>
