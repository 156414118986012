/**
 * This component displays a calculator icon and his text
 */
import {Component, Input} from '@angular/core';
import {NgxPopperjsPlacements} from 'ngx-popperjs';

@Component({
  selector: 'ucs-calc-icon',
  templateUrl: './calc-icon.component.html',
  styleUrls: ['./calc-icon.component.scss']
})

export class CalcIconComponent {
  @Input() type: 'OK' | 'NOK' | 'MANUAL';
  @Input() text: string;
  protected readonly NgxPopperjsPlacements = NgxPopperjsPlacements;
}
