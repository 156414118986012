import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {Store} from '@ngrx/store';
import * as fromRoot from '../store/app.reducers';
import {APP_CONFIG} from '../misc/inject-tokens';
import {AppConfig} from '../model/app-config.model';

/**
 * This service handles survey-related http calls to the respective REST endpoints
 */
@Injectable()
export class SurveyMaintenanceService {

  constructor(private http: HttpClient,
              private store: Store<fromRoot.AppState>,
              @Inject(APP_CONFIG) private config: AppConfig) {
  }

  /**
   * Get a list of surveyors for the given vehicle.
   *
   * @param {number} vehicleId The ID of the vehicle
   * @param {DistributionChannel} channel The channel of the vehicle
   * @returns {Observable<SurveyorDto[]>} An observable of a list of surveyors
   */
  getSurveyors(vehicleId: number, channel: DistributionChannel): Observable<SurveyorDto[]> {
    return this.http.get<SurveyorDto[]>(
      this.config.salesApiUrl + '/maintenance/survey/' + channel + ' /surveyors?vehicleId=' + vehicleId
    );
  }

  /**
   * Create a survey request using the data from the given survey creation DTO.
   *
   * @param {number} vehicleId The vehicle's ID for which to create a survey request
   * @param {DistributionChannel} channel The channel of the vehicle
   * @param {SurveyCreationDto} surveyCreationDto The data from which to create the survey request
   * @returns {Observable<Object>}
   */
  createSurveyRequest(vehicleId: number, channel: DistributionChannel, surveyCreationDto: SurveyCreationDto): Observable<SurveyDto> {
    return this.http.post<SurveyDto>(
      this.config.salesApiUrl + '/maintenance/survey/' + vehicleId + '/surveys-requests?channel=' + channel, surveyCreationDto
    );
  }

  /**
   * Get a survey request for a given vehicle.
   *
   * @param {number} vehicleId The vehicle's ID
   * @param {number} surveyRequestId The survey request's ID
   * @param {DistributionChannel} channel The channel of the vehicle
   * @returns {Observable<SurveyDto>} The requested survey request
   */
  getSurveyRequest(vehicleId: number, surveyRequestId: number, channel: DistributionChannel) {
    return this.http.get<SurveyDto>(
      `${this.config.salesApiUrl}/maintenance/survey/${vehicleId}/survey-requests/${surveyRequestId}?channel=${channel}`
    );
  }

  /**
   * Cancel a survey request with the given cause and eventually send an email to the surveyor.
   *
   * @param {number} vehicleId The vehicle's ID
   * @param {number} surveyRequestId The survey request's ID
   * @param {DistributionChannel} channel The channel of the vehicle
   * @param {SurveyModificationDto} surveyModification The data including the cause and if an email shall be sent to the surveyor
   * @returns {Observable<Object>}
   */
  cancelSurveyRequest(vehicleId: number, surveyRequestId: number, channel: DistributionChannel,
    surveyModification: SurveyModificationDto) {
    return this.http.request('delete',
      `${this.config.salesApiUrl}/maintenance/survey/${vehicleId}/survey-requests/${surveyRequestId}?channel=${channel}`,
      {body: surveyModification});
  }

  /**
   * Urge a survey request. The surveyor gets notified to fulfill the survey request.
   *
   * @param {number} vehicleId The vehicle's ID
   * @param {number} surveyRequestId The survey request's ID
   * @param {DistributionChannel} channel The channel of the vehicle
   * @returns {Observable<Object>}
   */
  urgeSurveyRequest(vehicleId: number, surveyRequestId: number, channel: DistributionChannel) {
    return this.http.post(
      `${this.config.salesApiUrl}/maintenance/survey/${vehicleId}/survey-requests/${surveyRequestId}?channel=${channel}`,
      null);
  }

  /**
   * Order a survey correction to an existing survey.
   *
   * @param {number} vehicleId The vehicle's ID
   * @param {number} surveyId The survey ID
   * @param {DistributionChannel} channel The channel of the vehicle
   * @param {SurveyModificationDto} surveyModification The data including the cause and if an email shall be sent to the surveyor
   * @returns {Observable<Object>}
   */
  correctSurvey(vehicleId: number, surveyId: number, channel: DistributionChannel, surveyModification: SurveyModificationDto) {
    return this.http.put(
      `${this.config.salesApiUrl}/maintenance/survey/${vehicleId}/surveys/${surveyId}?channel=${channel}`, surveyModification
    );
  }

  /**
   * Discard a survey with the given cause and eventually send an email to the surveyor.
   *
   * @param {number} vehicleId The vehicle's ID
   * @param {number} surveyRequestId The survey request's ID
   * @param {DistributionChannel} channel The channel of the vehicle
   * @returns {Observable<Object>}
   */
  discardSurvey(vehicleId: number, surveyId: number, channel: DistributionChannel) {
    return this.http.request('delete',
      `${this.config.salesApiUrl}/maintenance/survey/${vehicleId}/surveys/${surveyId}?channel=${channel}`);
  }

  /**
   * Get estimated gross price from survey
   *
   * @param {number} vehicleId The vehicle's ID
   * @returns {number} The gross price estimated in the survey
   */
  getSurveyEstimatedGrossPrice(vehicleId: number) {
    return this.http.get<number>(
      `${this.config.salesApiUrl}/maintenance/survey/${vehicleId}/surveys`
    );
  }
}
