import {Component, OnDestroy, OnInit, ViewChild,} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter, first, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {AuthenticationService} from '../../service/authentication.service';
import * as fromRoot from '../../store/app.reducers';
import {getMaintenanceScope, getVehicleMaintenanceDistributionChannel} from '../../store/app.reducers';
import {Store} from '@ngrx/store';
import {SystemSettingsService} from '../../service/system-settings.service';
import {UpdateOpenTasksAction} from '../../store/vehicle-maintenance/vehicle-maintenance.actions';
import {UserService} from '../../service/user.service';
import * as UserActions from '../../store/user/user.actions';
import {UpdateUserNotificationAction} from '../../store/notification/notification.actions';
import {TranslateService} from '@ngx-translate/core';
import {PopoverDirective} from 'ngx-bootstrap/popover';
import {MaintenanceScope} from '../../model/maintenance-scope';
import {ucsIsNil} from '../../misc/utils';
import {NgxPopperjsPlacements} from 'ngx-popperjs';
import {SpinnerService} from '../../service/spinner.service';

@Component({
  selector: 'ucs-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss']
})
export class TopMenuComponent implements OnDestroy, OnInit {
  @ViewChild('pop', {static: false}) pop: PopoverDirective;
  currentRoute: string;
  administrationRoute: string;
  userState: any;
  userInfo: UserInfoDto;
  selectedDealerId: number;
  currentDealer: DealerBaseDto;
  showMobileUserMenu = false;
  showSubMenuAdministration: boolean;
  showSubMenuOffer: boolean;
  showSubMenuSell: boolean;
  showUserNotifications = false;
  appScope: AppScope;
  numberOfOpenTasks: number;
  isAllowedToSeeOpenTasks = false;
  isAllowedSeeDealerDocuments: boolean;
  currentChannel: DistributionChannel;
  maintenanceScope: MaintenanceScope;

  // can sell offers in one of the channels
  isSeller = false;
  // can buy offers in one of the channels
  isBuyer = false;
  // is allowed to see admin area
  isAdmin = false;
  isAllowedToSeeSettings = false;
  // states whether the user's current dealer is not locked
  isNotLocked = false;
  // states whether the current user has not accepted the terms and conditions
  needGtcApproval = false;
  // states whether the current user is allowed to see the offer maintenance section. PIA examiner, e.g., is not allowed
  isAllowedToSeeOfferMaintenance = false;
  isAllowedToSeeDealerLocking = false;
  // show dropdown change local
  isShowLocalSwitchFeatureActive = false;
  // is allowed to see enforced auction areas
  isEnforcedAuctionUser = false;

  isEnforcedAuctionToggleEnabled: boolean;
  isEnforcedAuctionSettingEnabled: boolean;

  isSpinnerActive: boolean = false;

  actualLocal: string;
  // all available locals
  languages: Array<Object> = [
    {value: 'en', name: 'top-menu.dropdownLocal.en'},
    {value: 'de', name: 'top-menu.dropdownLocal.de'},
    {value: 'sl', name: 'top-menu.dropdownLocal.sl'},
    {value: 'bs', name: 'top-menu.dropdownLocal.bs'},
    {value: 'bg', name: 'top-menu.dropdownLocal.bg'},
    {value: 'hr', name: 'top-menu.dropdownLocal.hr'},
    {value: 'cs', name: 'top-menu.dropdownLocal.cs'},
    {value: 'fr', name: 'top-menu.dropdownLocal.fr'},
    {value: 'hu', name: 'top-menu.dropdownLocal.hu'},
    {value: 'it', name: 'top-menu.dropdownLocal.it'},
    {value: 'mk', name: 'top-menu.dropdownLocal.mk'},
    {value: 'pl', name: 'top-menu.dropdownLocal.pl'},
    {value: 'pt', name: 'top-menu.dropdownLocal.pt'},
    {value: 'ro', name: 'top-menu.dropdownLocal.ro'},
    {value: 'sr', name: 'top-menu.dropdownLocal.sr'},
    {value: 'sk', name: 'top-menu.dropdownLocal.sk'},
    {value: 'es', name: 'top-menu.dropdownLocal.es'},
    {value: 'uk', name: 'top-menu.dropdownLocal.uk'}
  ];

  private unsubscribe: Subject<void> = new Subject<void>();
  isCalenderActive: boolean = false;

  constructor(private router: Router, private authService: AuthenticationService,
              private systemSettingsService: SystemSettingsService,
              private store: Store<fromRoot.AppState>,
              private userService: UserService,
              private translateService: TranslateService,
              private spinnerService: SpinnerService) {
  }

  ngOnInit(): void {
    document.getElementById('ucs-content').addEventListener('click', this.hideBurgerMenuContent);

    this.store.select(getMaintenanceScope)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(scope => {
        if (scope) {
          this.maintenanceScope = scope;
        }
      });

    this.store.select(fromRoot.getUserInfo)
      .pipe(takeUntil(this.unsubscribe),
        filter(userInfo => (!!userInfo && !!(userInfo.firstName)))
      )
      .subscribe(userInfo => {
        this.userInfo = userInfo;
        this.userInfo.dealers = this.preprocessDealers(userInfo.dealers);
        this.selectedDealerId = userInfo.currentDealerId;
        this.currentDealer = this.userInfo.dealers.find(dealer => dealer.id === userInfo.currentDealerId);

        this.appScope = 'SALES_STORE';

        this.router.events
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(event => { // subscribe to change of route parameters
            if (event instanceof NavigationEnd) {
              this.currentRoute = event.url;
              if (this.currentRoute.startsWith('/maintenance')) {
                this.showSubMenuAdministration = false;
                this.showSubMenuOffer = false;
                this.showSubMenuSell = true;
              } else if (this.currentRoute.startsWith('/administration')) {
                this.showSubMenuAdministration = true;
                this.showSubMenuOffer = false;
                this.showSubMenuSell = false;
              } else {
                this.showSubMenuAdministration = false;
                this.showSubMenuSell = false;
                this.showSubMenuOffer = true;
              }
            }
          });

        this.systemSettingsService
          .isSystemFeatureActivatedForAnyChannel('OPEN_TASK_LIST').pipe(first())
          .subscribe(isAllowedToSeeOpenTasks => {
            this.isAllowedToSeeOpenTasks = isAllowedToSeeOpenTasks;
            if (isAllowedToSeeOpenTasks) {
              this.store.dispatch(new UpdateOpenTasksAction());
            }
          });

        this.store.select(getVehicleMaintenanceDistributionChannel)
          .pipe(takeUntil(this.unsubscribe), filter(distributionChannel => !!distributionChannel))
          .subscribe(distributionChannel => {
            this.currentChannel = distributionChannel;
            this.systemSettingsService.getSystemFeatureSettingForChannel(
              'VEHICLE_MAINTENANCE.expiring_auctions_calendar', this.currentChannel)
              .pipe(takeUntil(this.unsubscribe))
              .subscribe(data => {
                if (data) {
                  const stringValue = 'true';
                  this.isCalenderActive = data === stringValue;
                } else {
                  this.isCalenderActive = false;
                }
              });
          });

        this.systemSettingsService
          .isSystemFeatureActivatedForAnyChannel('CONTRACT_PARTNER_LOCKING').pipe(first())
          .subscribe(isAllowedToSeeDealerLocking => {
            this.isAllowedToSeeDealerLocking = isAllowedToSeeDealerLocking;
          });

        this.systemSettingsService
          .isSystemFeatureActivatedForAnyChannel('SHOW_DROPDOWN_LOCAL_CHANGE')
          .subscribe(isShowLocalSwitchFeatureActive => {
            this.actualLocal = 'en';
            this.isShowLocalSwitchFeatureActive = isShowLocalSwitchFeatureActive;
          });

        this.systemSettingsService
          .isSystemFeatureActivatedForAnyChannel('RECIEVE_USER_NOTIFICATIONS')
          .subscribe(isShowUserNotificationFeatureActive => {
            this.showUserNotifications = isShowUserNotificationFeatureActive;
          })
        ;

        this.systemSettingsService
          .isSystemFeatureActivatedForAnyChannel('ENFORCED_AUCTION')
          .subscribe(isEnabled => {
            this.isEnforcedAuctionToggleEnabled = isEnabled;
          });

        this.systemSettingsService.getSystemFeatureSettingForChannel(
          'ENFORCED_AUCTION.sales', 'PIA')
          .pipe(takeUntil(this.unsubscribe)).subscribe(settingString => {
            if (settingString) {
              const setting = JSON.parse(settingString);
              this.isEnforcedAuctionSettingEnabled = setting.enabled;
            }
          });
        this.getUserState();
      });

    this.store.select(fromRoot.getOpenTasks)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(openTasks => {
        this.numberOfOpenTasks = openTasks.length;
      });
  }


  private getUserState() {
    this.store.select(fromRoot.getUserState)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(userState => {
        if (userState) {
          this.userState = userState;
          if (localStorage.getItem(UserService.CURRENT_LANGUAGE + this.userState.userInfo.id) !== null) {
            this.actualLocal = localStorage.getItem(UserService.CURRENT_LANGUAGE + this.userState.userInfo.id);
          } else if (this.userState.local) {
            this.actualLocal = this.userState.local;
          } else {
            this.actualLocal = 'en';
          }
          this.store.dispatch(new UserActions.UpdateUserLocalAction(this.actualLocal));
          this.translateService.use(this.actualLocal);
        }

        this.isBuyer = this.evaluateIsBuyer();
        this.isSeller = this.evaluateIsSeller();
        this.isAdmin = this.evaluateIsAdmin();
        this.isEnforcedAuctionUser = this.userState.isBuyerEnforcedAuction;
        this.isAllowedToSeeSettings = this.evaluateIsAllowedToSeeSettings();
        this.isNotLocked = !AuthenticationService.isOnlyAllowedToSeeFinishedOffersReasonLocked(this.userInfo);
        // Enforced auction viewer has the same permission as missing GTC
        this.needGtcApproval = AuthenticationService.isOnlyAllowedToSeeFinishedOffersReasonGtc(this.userInfo) ||
            (userState.isViewerEnforcedAuction && !userState.hasAnyBuyerRoleOrAdmin);
        this.isAllowedSeeDealerDocuments = userState.isAdminPia || userState.isDevPoi;
        this.isAllowedToSeeOfferMaintenance = this.evaluateIsAllowedToSeeOfferMaintenance();

        if (this.isAllowedToSeeSettings) {
          this.administrationRoute = 'settings';
        } else {
          this.administrationRoute = 'reporting';
        }
      });
  }

  private preprocessDealers(dealers: DealerBaseDto[]): any[] {
    return dealers.map(dealer => ({
      ...dealer,
      displayName: `${this.getCompanyNumberOrPbvCustomerNumber(dealer)} ${dealer.name}`
    })).sort((a, b) => a.displayName.localeCompare(b.displayName));
  }

  private evaluateIsAllowedToSeeOfferMaintenance(): boolean {
    return AuthenticationService.canSellOffersForAnyChannel(this.userState)
      || this.userState.isCustodianPb
      || this.userState.isClerkPb;
  }

  private evaluateIsAdmin(): boolean {
    return AuthenticationService.isAdminForAnyChannel(this.userState) ||
      AuthenticationService.isSupportOrDevPoi(this.userState) ||
      this.userState.isSupportPb;
  }

  private evaluateIsSeller(): boolean {
    return AuthenticationService.canSellOffersForAnyChannel(this.userState)
      || this.userState.isExaminerPia
      || this.userState.isCustodianPb
      || this.userState.isSurveyorPb
      || this.userState.isClerkPb
      || this.userState.isClerkBmiPb;
  }

  private evaluateIsBuyer(): boolean {
    return AuthenticationService.canBuyOffers('PIA', this.userState)
      || AuthenticationService.canBuyOffers('ALL_UC', this.userState)
      || AuthenticationService.canBuyOffers('PB', this.userState)
      || AuthenticationService.canBuyOffers('DIN_BIL', this.userState)
      || AuthenticationService.canBuyOffers('VGRX', this.userState);
  }

  private evaluateIsAllowedToSeeSettings(): boolean {
    return this.userState.isAuctionSellerAllUc ||
      this.userState.isAuctionSellerPia ||
      this.userState.isAuctionSellerPb ||
      this.userState.isAuctionSellerDinBil ||
      this.userState.isAuctionSellerVgrx ||
      this.userState.isBuyNowSellerAllUc ||
      this.userState.isBuyNowSellerPia ||
      this.userState.isBuyNowSellerPb ||
      this.userState.isBuyNowSellerDinBil ||
      this.userState.isBuyNowSellerVgrx ||
      this.userState.isBuyerAllUc ||
      this.userState.isBuyerPia ||
      this.userState.isBuyerPb ||
      this.userState.isBuyerDinBil ||
      this.userState.isBuyerVgrx ||
      this.userState.isCustodianPb ||
      this.userState.isAdminPb ||
      this.userState.isAdminAllUc ||
      this.userState.isAdminDinBil ||
      this.userState.isAdminVgrx ||
      this.userState.isAdminPia ||
      this.userState.isDevPoi ||
      this.userState.isSupportPoi ||
      this.userState.isSupportPb;
  }

  dealerChange(dealer: any) {

    if (ucsIsNil(dealer)) {
      return;
    }

    this.authService.setDealer(dealer.id.toString()).pipe(takeUntil(this.unsubscribe)).subscribe((token) => {
      this.authService.storeToken(token);
      this.store.select(fromRoot.getUserInfo)
        .pipe(
          filter(u => (u?.currentDealerId + '') === dealer.id.toString()),
          first()
        )
        .subscribe(userInfo => {
          this.appScope = 'SALES_STORE';
          if (this.isSeller) {
            this.navigateSeller(userInfo);
          } else {
            if (!userInfo.locked) {
              this.router.navigate(['/maintenance/vehicle']);
            }
            this.appScope = 'SALES_MAINTENANCE';
          }
          this.store.dispatch(new UpdateUserNotificationAction(this.appScope));
        });
      location.reload();
    });
  }

  private navigateSeller(userInfo: UserInfoDto) {
    if (userInfo.locked) {
      this.router.navigate(['/finished-offers']);
    } else {
      this.router.navigate(['/']);
    }
  }

  getCompanyNumberOrPbvCustomerNumber(dealer: DealerBaseDto): string {
    // case 1: the dealer has a regular company number (not just the country code, e.g. 'AT')
    if (dealer?.companyNumber !== this.userInfo?.country) {
      return dealer.companyNumber;

      // case 2: the dealer has a PBV customer number AND has roles for the PB channel
    } else if (this.userState?.hasAnyRoleForPb && dealer?.pbvCustomerNumber) {
      return dealer.pbvCustomerNumber;
    }

    // case 3: the dealer has neither -> return companyNumber as is (will be just the country code, e.g. 'AT')
    return dealer?.companyNumber ?? '';
  }

  /**
   * programmatically hides BurgerMenuContent
   */
  hideBurgerMenuContent() {
    document.getElementById('burger-menu-toggle').setAttribute('aria-expanded', 'false');
    document.getElementById('burger-menu-toggle').classList.add('collapsed');
    document.getElementById('burger-menu-content').classList.remove('show');
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    document.getElementById('ucs-content').removeEventListener('click', this.hideBurgerMenuContent);
  }

  subMenuOffer() {
    this.showSubMenuOffer = true;
    this.showSubMenuSell = false;
    if (this.showUserNotifications) {
      this.initSalesStoreNotifications();
    }
  }

  subMenuSell() {
    this.showSubMenuSell = true;
    this.showSubMenuOffer = false;
    if (this.showUserNotifications) {
      this.initMaintenanceNotifications();
    }
  }

  changeLocal(local: string) {
    this.actualLocal = local;
    this.store.dispatch(new UserActions.UpdateUserLocalAction(local));
    this.translateService.use(this.userState.local);
    if (this.actualLocal !== 'de-de-key') {
      localStorage.setItem(UserService.CURRENT_LANGUAGE + this.userInfo.id, this.actualLocal);
    }

    // refresh component
    const currentUrl = this.router.url;
    this.router.navigate([currentUrl]);
  }

  isInSalesScope(): boolean {
    return this.currentRoute === '/' ||
      ['/offer', '/saved-search', '/bookmarks', '/my-auctions', '/finished-offers', '/pia-auction'].some(substr => this.currentRoute?.startsWith(substr));
  }

  initMaintenanceNotifications() {
    this.store.dispatch(new UpdateUserNotificationAction('SALES_MAINTENANCE'));
  }

  initSalesStoreNotifications() {
    this.store.dispatch(new UpdateUserNotificationAction('SALES_STORE'));
  }

  onOpenTaskNavItemClick() {
    if (this.isAllowedToSeeOpenTasks) {
      this.store.dispatch(new UpdateOpenTasksAction());
      this.spinnerService.activateSpinner();
      this.isSpinnerActive = true;
    }
  }

  protected readonly NgxPopperjsPlacements = NgxPopperjsPlacements;
}
