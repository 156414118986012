<div class="mt-4 mb-2 text">
  <strong>{{'search-filter.all-equipment' | translate}}</strong>
</div>

<!-- selected equipments are displayed above the equipment search field -->
<ng-container *ngFor="let equipment of aggregatedStates">
  <div class="mb-3" *ngIf="equipment.selected">
    <a>
      <ucs-icon (click)="onToggleCheckbox(equipment.id)"
                [shape]="'square_checked'"
                [class]="'blue me-1'"></ucs-icon>
    </a>
    <span class="text text-bright">{{ equipment.text }} ({{equipment.count}})</span>
  </div>
</ng-container>

<!-- equipment search field -->
<div class="row mb-3">
  <div [ngClass]="{'col-12':true, 'col-lg-5 col-md-6':quickSearch}">
    <input [(ngModel)]="selectedOption"
           [typeahead]="aggregatedStates"
           [typeaheadMinLength]=3
           [typeaheadOptionsLimit]="999"
           typeaheadOptionField="option"
           (typeaheadOnSelect)="onSelect($event)"
           placeholder="{{'search-filter.search-equipment' | translate}}"
           class="form-control text">
  </div>
</div>

<!-- unselected equipments are displayed below the equipment search field -->
<ng-container *ngFor="let equipments of searchAggregation.equipments; index as i">

  <button class="button-borderless w-100 d-flex justify-content-between icon-height mb-3"
          (click)="onToggleCategory(i)">
    {{ equipments.category }}
    <ng-container *ngIf="isCategoryExpanded(i); else hideCategory">
      <ucs-icon [shape]="'angle_up'" [class]="'blue'"></ucs-icon>
    </ng-container>
    <ng-template #hideCategory>
      <ucs-icon [shape]="'angle_down'" [class]="'blue'"></ucs-icon>
    </ng-template>
  </button>

  <ng-container *ngIf="isCategoryExpanded(i)">
    <ng-container *ngFor="let equipment of aggregatedStates">
      <div class="mb-3" *ngIf="equipments.category === equipment.category && !equipment.selected">
        <a>
          <ucs-icon (click)="onToggleCheckbox(equipment.id)"
                    [shape]="'square_outline'"
                    [class]="'blue me-1'"></ucs-icon>
        </a>
        <span class="text text-bright">{{ equipment.text }} ({{equipment.count}})</span>
      </div>
    </ng-container>
  </ng-container>

</ng-container>
