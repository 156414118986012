<div *ngIf="vehicle" id="{{ 'vehicle' + vehicle.id }}" class="vehicle rounded container-fluid">
  <div class="px-2 pb-lg-2 pt-2 row">

    <!--headline-->
    <div class="col-xl-9 offset-xl-3 ps-xl-4 col-lg-8 ps-lg-0 headline">
      <div class="row">
        <!-- title vehicle.modelVariant -->
        <div class="col-auto pe-0 ps-0">
          <div class="col model-name link-title"
               data-cy="vehicle-component.vehicle-modelVariant">
            <a [routerLink]="'/maintenance/vehicle/' + vehicle.id" (click)="storeBacklink()">
              <strong>{{ vehicle.title ?? vehicle.modelVariant }}</strong>
            </a>
          </div>
          <!-- create-date -->
          <div class="row ps-0 mb-1 create-date">
            <span>{{ 'offer-item.create-date' | translate }} {{ vehicle.created | date : 'dd.MM.yyyy' }}</span>
          </div>
        </div>
        <!-- info sign -->
        <span class="col info" *ngIf="vehicle?.infoText && vehicle.infoText !== ''">
          <button class="btn-popover px-0 ps-1" type="button"
                  [popper]="popTemplate"
                  [popperTrigger]="NgxPopperjsTriggers.hover"
                  [popperPlacement]="NgxPopperjsPlacements.BOTTOM"
                  [popperApplyClass]="'info-popover-size'">
                  <ucs-icon class="pe-1" [shape]="'info'" [size]="24" [class]="'blue'"></ucs-icon>
          </button>
                <popper-content #popTemplate>
                  <div>
                    {{vehicle.infoText}}
                  </div>
                </popper-content>
        </span>

        <!-- basket -->
        <div class="col ms-auto px-0 fav">
          <!--only show basket when ready and allowed to create offer-->
          <ng-container *ngIf="(mayCreateOfferBuyNow || mayCreateOfferAuction) && this.activeCategory !== 'LONGTIME_PENDING'">
                <span>
                  <button class="tray-general me-2"
                          [ngClass]="checkInBasket() ? 'in-tray' : 'not-in-tray'"
                          triggers="focus"
                          (click)="toggleTray()">
                    <ucs-icon class="pe-lg-1" [shape]="'inbox'" [size]="24"></ucs-icon>
                    <strong class="d-none d-xl-inline">
                      {{ (checkInBasket() ? 'vehicle.in-tray' : 'vehicle.not-in-tray') | translate }}
                    </strong>
                  </button>
                </span>
          </ng-container>

          <ng-container *ngIf="this.activeCategory === 'LONGTIME_PENDING' && this.isAdminForEnforcedAuction">
            <ng-container *ngIf="this.vehicle?.status !== 'ONHOLD'
              && this.vehicle.enforcedAuctionVehicleData?.status !== 'OFFER_FINISHED'">
                <span class="row pe-3">
                  <button class="enforced-bookmark" data-cy="enforced-bookmark" (click)="onToggleEnforcedVehicleBookmark()">
                    <ng-container *ngIf="['AUTO_SELECTED', 'MANUALLY_SELECTED', 'VALIDATION_ERROR'].includes(vehicle.enforcedAuctionVehicleData?.status); else notChecked">
                      <ucs-icon [shape]="'bookmark-fill'"></ucs-icon>
                       <strong class="d-none d-xl-inline">
                      {{ 'vehicle.enforcedAuction.bookmarked' | translate }}
                    </strong>
                    </ng-container>
                    <ng-template #notChecked>
                      <ucs-icon [shape]="'bookmark'"></ucs-icon>
                       <strong class="d-none d-xl-inline">
                      {{ 'vehicle.enforcedAuction.bookmark' | translate }}
                    </strong>
                    </ng-template>
                  </button>
                </span>
            </ng-container>
            <ng-container *ngIf="!['MANUALLY_SELECTED', 'AUTO_SELECTED'].includes(vehicle.enforcedAuctionVehicleData?.status)">
              <span class="row pe-3">
              <button *ngIf="this.vehicle?.status === 'ONHOLD'"
                      class="btn-tertiary enforced-locked"
                      data-cy="enforced-unlock"
                      (click)="lockEnforcedVehicle('ACTIVE')">
                <ucs-icon class="mb-1" [shape]="'CANCELLED'" [size]="24"></ucs-icon>
                {{ 'vehicle.enforcedAuction.unlock' | translate }}
              </button>
              <button class="btn-tertiary enforced-locked" data-cy="enforced-lock"
                      *ngIf="this.vehicle?.status !== 'ONHOLD' && ['MANUALLY_REMOVED', 'NOT_SELECTED', null].includes(vehicle.enforcedAuctionVehicleData?.status)"
                      (click)="lockEnforcedVehicle('ONHOLD')">
                {{ 'vehicle.enforcedAuction.lock' | translate }}
              </button>
              </span>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>

    <!--image-->
    <div class="image">
      <div class="small-carousel-gallery-wrapper">
        <ks-carousel *ngIf="galleryImages.length > 0" [images]="galleryImages" [config]="carouselConfig" [id]="vehicle.id" class="d-block"></ks-carousel>
      </div>
      <div *ngIf="vehicle.carhubStatus !== 'ACTIVE'"
           class="overlay">{{ 'vehicle-maintenance.carhub-status.' + vehicle.carhubStatus | translate }}</div>
    </div>

    <div class="col-7 col-md-8 col-xl-9 ms-0 ps-xl-4 pe-0 ps-2 row location-dealer-vat-container"
         [ngClass]="leasingDealer ? 'max-height-90' : 'max-height-60'">
      <!--location-->
      <div class="col ps-1 pe-1 location-contract">
        <ucs-icon [shape]="'location_pin'" [size]="12"
                  [class]="'d-lg-inline-block align-top me-sm-2 grey opacity-05'" ></ucs-icon>
        <span class="d-lg-inline-block" *ngIf="vehicle.dealerInformation?.id">
          ({{ dealerService.getCompanyNumberOrPbvCustomerNumber(vehicle.dealerInformation, vehicle?.channel) }})
          {{ vehicle.dealerInformation?.address }}
          {{ vehicle.dealerInformation?.city }} -
          {{ vehicle.dealerInformation?.country?.data }}
        </span>
      </div>

      <!--leasing dealer-->
      <div *ngIf="leasingDealer" class="col ps-0 pe-1 pe-md-0 leasing-dealer location-contract"
           ucsHideableData [channel]="vehicle.channel" [dataType]="'contract-data'">
        <ucs-icon [shape]="'pen-tool'" [size]="12"
                  [class]="'d-lg-inline-block align-top me-sm-2 grey'"></ucs-icon>
        <span class="d-lg-inline-block">{{ vehicle.contractPartner }}</span>
      </div>

      <!--vatType-->
      <div class="col ps-0 pe-1 pe-md-0 location-contract">
        <ucs-icon [shape]="'circle'" [size]="12" [class]="'me-sm-2 grey opacity-025'"></ucs-icon>
        <span>{{ vehicle.vatType.text }}</span>
      </div>
    </div>
    <!--viewSettings-->
    <div class="row d-lg-block height-40 technical view-settings">
      <div class="col-8 d-md-block d-none">
        <div class="row ps-1 ps-sm-3">
          <span *ngIf="view('MILEAGE') && vehicle.mileage"
                ucsHideableData [channel]="vehicle.channel" [dataType]="'mileage'"
                class="col-md-auto box-item me-1 mb-1 px-2">{{ vehicle.mileage | km }}</span>
          <span *ngIf="view('INITIAL-REGISTRATION') && vehicle.initialRegistrationDate"
                ucsHideableData [channel]="vehicle.channel" [dataType]="'initial-registration-date'"
                class="col-md-auto box-item me-1 mb-1 px-2">{{ 'offer-item.initial-registration.short' | translate }} {{ vehicle.initialRegistrationDate | date : 'MM/yyyy' }}</span>
          <span *ngIf="view('FUEL') && vehicle.fuelTypes[0]"
                ucsHideableData [channel]="vehicle.channel" [dataType]="'fuel-types'"
                class="col-md-auto box-item me-1 mb-1 px-2">{{ vehicle.fuelTypes[0] }}</span>
          <span *ngIf="view('POWER') && vehicle.powerPs"
                ucsHideableData [channel]="vehicle.channel" [dataType]="'power'"
                class="col-md-auto box-item me-1 mb-1 px-2">{{ vehicle.powerPs | ps }}
            ({{ vehicle.powerKw | kw }})</span>
          <span *ngIf="view('COLOR') && vehicle.color"
                ucsHideableData [channel]="vehicle.channel" [dataType]="'color'"
                class="col-md-auto box-item me-1 mb-1 px-2">{{ vehicle.color }}</span>
          <span *ngIf="view('FUEL-CONSUMPTION') && vehicle.consumption"
                ucsHideableData [channel]="vehicle.channel" [dataType]="'consumption'"
                class="col-md-auto box-item me-1 mb-1 px-2">{{ vehicle.combinedFuelCode === '4' ? (vehicle.consumption | kwhPerHundredKm) :
                  (vehicle.consumption | litersPerHundredKm)}}</span>
          <span *ngIf="view('TAX') && vehicle.vatType?.text"
                ucsHideableData [channel]="vehicle.channel" [dataType]="'tax-type'"
                class="col-md-auto box-item me-1 mb-1 px-2">{{ vehicle.vatType?.text }}</span>
          <span *ngIf="view('EMISSION') && vehicle.emissionClass"
                ucsHideableData [channel]="vehicle.channel" [dataType]="'emission-class'"
                class="col-md-auto box-item me-1 mb-1 px-2">{{ vehicle.emissionClass | emissionClass }}</span>
          <span *ngIf="view('EXPLOITATION-TYPE') && vehicle.exploitationType?.text"
                ucsHideableData [channel]="vehicle.channel" [dataType]="'exploitation-type'"
                class="col-md-auto box-item me-1 mb-1 px-2">{{ vehicle.exploitationType?.text }}</span>
        </div>
      </div>
      <div *ngIf="isAllowedToSeeDiscrepancies && discrepanciesAvailable" class="d-inline-flex mt-3 ms-1 discrepancies">
        <ucs-icon [shape]="'info'" [size]="16" [class]="'orange'"></ucs-icon>
        <div class="ms-1" style="margin-top: 0.12em">
          {{ 'vehicle-maintenance.detail.discrepancies' | translate}}
          <a class="ps-1 discrepancye-link" [routerLink]="'/maintenance/vehicle/' + vehicle.id">{{
            'vehicle-maintenance.detail.changes' |
            translate }} </a>
        </div>
      </div>
    </div>

    <!--VIN, leasing-contract-number (V-Nr)-->
    <div class="row justify-content-start mt-1 contract-data">
      <div class="d-inline-flex justify-content-between">
      <div class="d-inline-flex">
        <strong>{{ 'offer-item.vin' | translate }}&nbsp;</strong> {{vehicle.vin}}
      </div>

        <div class="justify-content-start row pe-0">
          <!--HighlightEquipment-->
          <ucs-highlight-equipment [ngClass]="{'highlight-equipment': leasingContractNumber || showExternalId}"
                                   class="d-inline-flex justify-content-start pe-0"
                                   [highlightEquipments]="vehicle.highlightEquipments"
                                   [showEtbLongTimePending]="!vehicle?.missingEtb && this.activeCategory === 'LONGTIME_PENDING'">
          </ucs-highlight-equipment>
        </div>
      </div>

      <div *ngIf="leasingContractNumber" ucsHideableData
           [channel]="vehicle.channel" [dataType]="'contract-data'"
           class="d-inline-flex ">
        <strong>{{ 'offer-item.leasingContractNumber' | translate }}</strong> {{vehicle.contractId}}
      </div>
      <div class="d-flex external-id text-break justify-content-between">
        <div >
          <ng-container *ngIf="showExternalId">
          <strong>{{ 'vehicle-maintenance.external-id' | translate }}</strong>: {{ vehicle.externalId }}
        </ng-container>
          </div>
        <div>
          <!--internal note-->
          <button *ngIf="vehicle?.note?.length > 0 && canSeeInternalNote"
                  class="col-auto justify-content-end custom-grid shift-right-Internal icon-footer-general"
                  type="button"
                  [ngClass]="{'pointer': !!vehicle?.note?.length}"
                  [popper]="popTemplateNoteLg"
                  [popperPlacement]="NgxPopperjsPlacements.TOP"
                  [popperApplyClass]="'popper-content-display'"
                  [disabled]="!!!vehicle?.note?.length">
            <span class="internal-note">{{ 'offer-item.internal-note' | translate }}</span>
            <ucs-icon [shape]="'chevron-down'"></ucs-icon>
          </button>
          <popper-content class="popper-content-display" #popTemplateNoteLg>
            <div class="internal-note-content text-start">
              <p>{{ vehicle?.note }}</p>
            </div>
          </popper-content>

          <!--document-->
          <button *ngIf="vehicle?.mediaInfos.length > 0 && this.activeCategory !== 'LONGTIME_PENDING'"
                  class="icon-footer-general col-auto"
                  [ngClass]="{'pointer': !!vehicle.mediaInfos.length, 'offset-6': !canSeeInternalNote}" type="button"
                  [popper]="popTemplateLg" [popperPlacement]="NgxPopperjsPlacements.TOP"
                  [popperStyles]="{'max-width': '300px'}"
                  [disabled]="!!!vehicle.mediaInfos.length">
            <ucs-icon [shape]="'document'"
                      [class]="vehicle.missingDocument ? 'grey' : 'blue'"></ucs-icon>
            <span class="documents">{{ 'offer-item.documents' | translate }}</span>
            <ucs-icon [shape]="'chevron-down'"></ucs-icon>
          </button>
          <popper-content #popTemplateLg>
            <a rel="noopener" *ngFor="let document of vehicle.mediaInfos"
               href="{{ document.externalLink ? document.url : document.url + '/original' }}"
               target="_blank"
               (mousedown)="$event.preventDefault()">
          <span class="ps-1 d-inline-block text-alternate">
              {{ document.type.text }}
          </span><br/>
              <span class="ps-1 d-inline-block text-alternate"
                    *ngIf="showExternalDocumentLink && document.externalLink">
              {{ 'vms.media-types.external-document' | translate }}
          </span>
            </a>
          </popper-content>
        </div>
      </div>
    </div>
  </div>

  <ucs-vehicle-footer *ngIf="!vehicle.offerDataDto && this.activeCategory !== 'LONGTIME_PENDING'" [vehicle]="vehicle"
                      [mayCreateBuyNow]="mayCreateOfferBuyNow && vehicle.carhubStatus === 'ACTIVE'"
                      [mayCreateAuction]="mayCreateOfferAuction && vehicle.carhubStatus === 'ACTIVE'">
  </ucs-vehicle-footer>
  <ucs-card-footer *ngIf="vehicle.offerDataDto || this.activeCategory === 'LONGTIME_PENDING'"
                   [offerData]="vehicle.offerDataDto"
                   [channel]="vehicle.channel"
                   [currentPrice]="vehicle.offerDataDto?.offerType === 'BUYNOW' ? vehicle.offerDataDto?.price : vehicle.offerDataDto?.highestBid"
                   [startPrice]="vehicle.offerDataDto?.initialBid"
                   [vatType]="vehicle.vatType.data"
                   [extraTax]="vehicle.offerDataDto?.extraTaxDto"
                   [grossProfitData]="vehicle.offerDataDto?.grossProfit"
                   [vehicle]="vehicle"
                   [showOfferLink]="showLinkToOffer"
                   [footerStatus]="footerStatus"
                   (linkToOfferClicked)="storeBacklink()"
                   ucsHideableData
                   [dataType]="'offer-price-information'"
                   [country]="vehicle.country"
                   [scopeSection]="'SALES_MAINTENANCE'"
                   [activeCategory]="activeCategory"
                   [enforcedAuctionData]="vehicle?.enforcedAuctionVehicleData"
                   [displayLogo]="false">
  </ucs-card-footer>
</div>
