import {EMPTY, Observable, Subject} from 'rxjs';
import {Inject, Injectable, OnDestroy} from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {Store} from '@ngrx/store';
import * as fromRoot from '../store/app.reducers';
import {takeUntil} from 'rxjs/operators';
import {AuthenticationService} from './authentication.service';
import {APP_CONFIG} from '../misc/inject-tokens';
import {AppConfig} from '../model/app-config.model';

/**
 * This service handles offer-related http calls to the respective REST endpoints
 */
@Injectable()
export class OfferService implements OnDestroy {
  offerScopeSearchParams: VehicleOfferSearchParams;
  enforcedAuctionScopeParams: VehicleOfferSearchParams;
  finishedSearchParams: VehicleOfferSearchParams;
  offerMaintenanceSearchParams: OfferMaintenanceSearchParams;
  userInfo: UserInfoDto;
  private unsubscribe: Subject<void> = new Subject<void>();
  private noCacheHeaders = new HttpHeaders({
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
  });

  constructor(private http: HttpClient,
              private store: Store<fromRoot.AppState>,
              @Inject(APP_CONFIG) private config: AppConfig) {
    this.onInit(); // life cycle hooks do not get called within @Injectable() except for OnDestroy
  }

  /**
   * Initialize the service
   */
  onInit() {
    // select searchParams from store and subscribe to future updates
    this.store.select(fromRoot.getSearchParams)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(searchParams => {
        this.offerScopeSearchParams = searchParams;
      });

    this.store.select(fromRoot.getOfferMaintenanceSearchParams)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(searchParams => {
        this.offerMaintenanceSearchParams = searchParams;
      });

    this.store.select(fromRoot.getFinishedSearchParams)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(searchParams => {
        this.finishedSearchParams = searchParams;
      });

    this.store.select(fromRoot.getUserInfo)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(userInfo => {
        if (userInfo) {
          this.userInfo = userInfo;
        }
      });

    this.store.select(fromRoot.getEnforcedAuctionOfferSearchParams)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(searchParams => {
        this.enforcedAuctionScopeParams = searchParams;
      });
  }

  /**
   * Fetches all offers
   * @returns {Observable<OfferDto[]>}: offers
   */
  getOffers(): Observable<OfferDto[]> {
    let params = this.searchParamsToHttpParams(this.offerScopeSearchParams);
    let response = this.http.get<OfferDto[]>(this.config.salesApiUrl + '/offers',
      {params: params, headers: this.noCacheHeaders});

    this.setModelsSearchParams(this.offerScopeSearchParams, params, 'decode');
    this.setModelVariantsSearchParams(this.offerScopeSearchParams, params, 'decode');

    return response;
  }

  /**
   * Pageable offer full text identification search.
   * Fetches all offers for a given distribution channel and a search string
   *
   * @returns {Observable<OfferDto[]>}: offers for distribution channel matching the search string
   */
  getOffersByIdentificationMatch(channel: DistributionChannel, searchString: string): Observable<OfferDto[]> {
    let params = new HttpParams();
    params = params.set('channel', channel);
    params = params.set('searchString', searchString);
    params = params.set('size', '20');
    params = params.set('page', '0');
    params = params.set('sort', 'modified' + ',' + 'desc');

    return this.http.get<OfferDto[]>(this.config.salesApiUrl + '/offers/identification', {
      params: params,
      headers: this.noCacheHeaders
    });
  }

  /**
   * Fetches offers for the "new offers"-slider of a specified channel
   * @returns {Observable<OfferDto[]>}: offers
   */
  getNewSliderOffersForChannel(channel: DistributionChannel): Observable<any> {
    let params = new HttpParams();
    params = params.set('channels', channel);
    params = params.set('size', '20');
    params = params.set('page', '0');
    params = params.set('sort', 'START,DESC');
    return this.http.get<OfferDto[]>(this.config.salesApiUrl + '/offers',
      {params: params, headers: this.noCacheHeaders});
  }

  /**
   * Fetches offers for the "soon-expiring"-slider of a specified channel
   * @returns {Observable<OfferDto[]>}: offers
   */
  getExpiringSliderOffersForChannel(channel: DistributionChannel): Observable<any> {
    let params = new HttpParams();
    params = params.set('channels', channel);
    params = params.set('size', '20');
    params = params.set('page', '0');
    params = params.set('sort', 'EXPIRATION,ASC');
    return this.http.get<OfferDto[]>(this.config.salesApiUrl + '/offers',
      {params: params, headers: this.noCacheHeaders});
  }

  private setParams(channel: DistributionChannel,
    size: number, page: number, sort: string, order: string, searchParams: VehicleOfferSearchParams) {
    let params = this.searchParamsToHttpParams(searchParams);
    params = params.set('channels', channel);
    params = params.set('size', size);
    params = params.set('page', page);
    return params.set('sort', sort + ',' + order);
  }

  /**
   * Fetches all offers for channels selected with the multiselect
   * @returns {Observable<OfferDto[]>}: offers for distribution channels
   */
  getOffersForFilteredChannels(size: number, page: number, sort: string, order: string): Observable<OfferDto[]> {
    if (AuthenticationService.isOnlyAllowedToSeeFinishedOffersReasonGtc(this.userInfo) || sort === 'FINISHED_OFFER') {
      //this method should not be used when we want to fetch finished offers
      //Sort check can be removed when pageSettings and searchAggregations are split for each sales sub-page
      return EMPTY;
    }
    let params = this.searchParamsToHttpParams(this.offerScopeSearchParams);
    if (this.offerScopeSearchParams.bundling === undefined || this.offerScopeSearchParams.bundling === null) {
      this.offerScopeSearchParams.bundling = 'SINGLE';
    }
    if (this.offerScopeSearchParams.channels && this.offerScopeSearchParams.channels.length > 0) {
      params = params.set('channels', this.offerScopeSearchParams.channels.toString());
    }
    if (this.offerScopeSearchParams.types === undefined || this.offerScopeSearchParams.types === null) {
      this.offerScopeSearchParams.types = <OfferType[]>['AUCTION','BUYNOW'];
      params = params.set('types', this.offerScopeSearchParams.types.toString());
    }

    params = params.set('size', size);
    params = params.set('page', page);
    params = params.set('sort', sort + ',' + order);

    let response = this.http.get<OfferDto[]>(this.config.salesApiUrl + '/offers', {
      params: params,
      headers: this.noCacheHeaders
    });

    this.setModelsSearchParams(this.offerScopeSearchParams, params, 'decode');
    this.setModelVariantsSearchParams(this.offerScopeSearchParams, params, 'decode');

    return response;
  }


  /**
   * Fetches all offers for channels selected with the multiselect
   * @returns {Observable<OfferDto[]>}: offers for distribution channels
   */
  getEnforcedAuctionOffers(size: number, page: number, sort: string, order: string): Observable<OfferDto[]> {
    if (AuthenticationService.isOnlyAllowedToSeeFinishedOffersReasonGtc(this.userInfo) || sort === 'FINISHED_OFFER') {
      //this method should not be used when we want to fetch finished offers
      //Sort check can be removed when pageSettings and searchAggregations are split for each sales sub-page
      return EMPTY;
    }
    let params = this.searchParamsToHttpParams(this.enforcedAuctionScopeParams);

    params = params.set('size', size);
    params = params.set('page', page);
    params = params.set('sort', sort + ',' + order);
    params = params.set('types', 'ENFORCED_AUCTION');

    let response = this.http.get<OfferDto[]>(this.config.salesApiUrl + '/offers', {
      params: params,
      headers: this.noCacheHeaders
    });

    this.setModelsSearchParams(this.enforcedAuctionScopeParams, params, 'decode');
    this.setModelVariantsSearchParams(this.enforcedAuctionScopeParams, params, 'decode');

    return response;
  }


  /**
   * Fetches all current user-company specific offers
   * @returns {Observable<OfferDto[]>} offers for distribution channel
   */
  getCurrentUserCompanyOffersForChannel(channel: DistributionChannel, size: number, page: number,
    sort: string, order: string) {
    let params = new HttpParams();
    params = params.set('channels', channel);
    params = params.set('size', size);
    params = params.set('page', page);
    params = params.set('sort', sort + ',' + order);

    return this.http.get<OfferDto[]>(this.config.salesApiUrl + '/users/' + this.userInfo.id + '/offers', {
      params: params,
      headers: this.noCacheHeaders
    });
  }

  /**
   * Fetches all finished user-company specific offers
   * @returns {Observable<OfferDto[]>} offers for distribution channel
   */
  getFinishedUserCompanyOffersForChannel(channel: DistributionChannel, size: number, page: number,
    sort: string, order: string) {

    let params = this.setParams(channel, size, page, sort, order, this.finishedSearchParams);
    let response = this.http.get<OfferDto[]>(this.config.salesApiUrl + '/users/' + this.userInfo.id + '/finished-offers', {
      params: params,
      headers: this.noCacheHeaders
    });

    this.setModelsSearchParams(this.finishedSearchParams, params, 'decode');
    this.setModelVariantsSearchParams(this.finishedSearchParams, params, 'decode');

    return response;
  }

  /**
   * Fetches all bookmarked favorite offers for a given distribution channel
   * @returns {Observable<OfferDto[]>}: bookmarked favorite offers for distribution channel
   */
  getBookmarkedOffersForChannel(channel: DistributionChannel, size: number, page: number,
    sort: string, order: string): Observable<OfferDto[]> {
    let params = new HttpParams();
    params = params.set('channels', channel);
    params = params.set('size', size);
    params = params.set('page', page);
    params = params.set('sort', sort + ',' + order);

    return this.http.get<OfferDto[]>(this.config.salesApiUrl + '/users/' + this.userInfo.id + '/bookmarked-offers', {
      params: params,
      headers: this.noCacheHeaders
    });
  }

  /**
   * Bookmarks an offer for the current user
   * @param {number} offerId
   */
  addBookmark(offerId: number): Observable<any> {
    return this.http.post(this.config.salesApiUrl + '/users/' + this.userInfo.id + '/bookmarked-offers', offerId);
  }

  /**
   * Deletes a bookmark for the current user
   * @param {number} offerId
   */
  deleteBookmark(offerId: number): Observable<any> {
    return this.http.delete(this.config.salesApiUrl + '/users/' + this.userInfo.id + '/bookmarked-offers/' + offerId);
  }

  /**
   * Get a list of logs for the given offer.
   *
   * @param {number} offerId The ID of the offer
   * @returns {Observable<LogDto[]>} An observable of a list of auditlogs
   */
  getLogs(offerId: number): Observable<LogDto[]> {
    return this.http.get<LogDto[]>(this.config.salesApiUrl + '/offers/' + offerId + '/logs');
  }

  /**
   * Fetches an offer based on its id
   * @returns {Observable<OfferDto[]>}: the offer matching the provided id
   */
  getOffer(id: number): Observable<OfferDto> {
    return this.http.get<OfferDto>(this.config.salesApiUrl + '/offers/' + id, {headers: this.noCacheHeaders});
  }

  /**
   * Fetches an offer based on its id without user/dealer access tracking.
   * Just needed for discrepancy check.
   *
   * @returns {Observable<OfferDto[]>}: the offer matching the provided id
   */
  getOfferCheckForDiscrepancy(id: number): Observable<OfferDto> {
    return this.http.get<OfferDto>(this.config.salesApiUrl + '/offerCheckForDiscrepancy/' + id, {headers: this.noCacheHeaders});
  }

  /**
   * Fetches a sales contract status on its offerId
   * @returns {Observable<string>}: the sales contract status matching the provided offerId
   */
  getSalesContractStatus(offerId: number): Observable<string> {
    return this.http.get(this.config.salesApiUrl + '/salesContractStatus/' + offerId,
      {headers: this.noCacheHeaders, responseType: 'text'});
  }

  /**
   * Fetches an offer based on its id
   * @returns {Observable<OfferDto[]>}: the offer matching the provided id
   */
  getOfferForMaintenance(offerId: number): Observable<OfferDto> {
    return this.http.get<OfferDto>(this.config.salesApiUrl + '/maintenance/offers/' + offerId, {headers: this.noCacheHeaders});
  }

  /**
   * Purchase an offer
   *
   * @param {number} offerId
   */
  purchaseOffer(offerId: number, logOfferAccess: boolean): Observable<OfferStatus> {
    let params = new HttpParams();
    params = this.addHttpParam(logOfferAccess, 'logOfferAccess', params);
    return this.http.post<OfferStatus>(this.config.salesApiUrl + '/offers/' + offerId + '/purchase', offerId,
      {params: params, headers: this.noCacheHeaders});
  }

  /**
   * Bid on offer
   *
   * @param {number} offerId
   */
  bidOnOffer(offerId: number, bidRequest: BidRequestDto): Observable<any> {
    return this.http.post(this.config.salesApiUrl + '/bidding/offers/' + offerId + '/bid', bidRequest);
  }

  /**
   * update an Offer
   * @param {number} offerId
   * @param {number} bidId
   */
  updateBid(offerId: number, bidId: number, updateBid: BidUpdateDto): Observable<any> {
    return this.http.put(this.config.salesApiUrl + '/maintenance/offers/' + offerId + '/bids/' + bidId, updateBid);
  }

  resendPbPurchaseMail(offerId: number, pbMailTransferDto: PbMailTransferDto): Observable<any> {
    return this.http.post(this.config.salesApiUrl + '/maintenance/send-pb-mail/' + offerId, pbMailTransferDto);
  }
  /**
   * Fetches all offers
   * @returns {Observable<OfferDto[]>}: offers
   */
  findOffersForMaintenance(size: number, page: number, sort: string, order: string): Observable<OfferMaintenanceDto> {
    let params = this.offerMaintenanceSearchParamsToHttpParams(this.offerMaintenanceSearchParams);
    params = params.set('channel', this.offerMaintenanceSearchParams.channel);
    params = params.set('size', size);
    params = params.set('page', page);
    params = params.set('sort', sort + ',' + order);
    return this.http.get<OfferMaintenanceDto>(this.config.salesApiUrl + '/maintenance/offers',
      {params: params, headers: this.noCacheHeaders});
  }

  /**
   * Create a new offer based on one vehicle (single offer) or several vehicles (bundle offer)
   * @param createOfferDto: the dto containing the necessary data to create a new offer
   */
  createOffer(createOfferDto: CreateOfferDto): Observable<any> {
    return this.http.post(this.config.salesApiUrl + '/maintenance/offers', createOfferDto);
  }

  /**
   * Updates an offer based on the give OfferUpdateDto
   * @param {number} offerId The ID of the offer to update
   * @param {OfferUpdateDto} offerUpdate The update data
   * @returns {Observable<any>} An Observable representing the result
   */
  updateOffer(offerId: number, offerUpdate: OfferUpdateDto): Observable<any> {
    return this.http.put<VehicleDetailDto>(this.config.salesApiUrl + '/maintenance/offers/' + offerId, offerUpdate);
  }

  /**
   * Add a followup proposal to an vehicle
   * @param {number} vehicleId
   */
  makeFollowupProposal(offerId: number, proposalMake: ProposalMakeDto): Observable<any> {
    return this.http.post(this.config.salesApiUrl + '/maintenance/offers/' + offerId + '/followupProposals', proposalMake);
  }

  /**
   * Deletes a followup proposal to an vehicle
   * @param {number} proposalId
   */
  deleteFollowupProposal(offerId: number, proposalId: number): Observable<any> {
    return this.http.delete(this.config.salesApiUrl + '/maintenance/offers/' + offerId + '/followupProposals/' + proposalId);
  }

  /**
   * Update followup proposal to an vehicle
   * @param {number} vehicleId
   */
  updateFollowupProposal(offerId: number, proposalId: number, proposalUpdate: ProposalUpdateDto): Observable<any> {
    return this.http.put(this.config.salesApiUrl + '/maintenance/offers/' + offerId + '/followupProposals/' + proposalId, proposalUpdate);
  }

  /**
   * resend followup proposal mail to custody dealers
   * @param {number} offerId
   */
  resendFollowupProposalEmails(offerId: number, proposalId: number): Observable<any> {
    return this.http.put(this.config.salesApiUrl + '/maintenance/offers/' + offerId + '/mailFollowupproposals/' + proposalId, proposalId);
  }


  /**
   * Delete the offer with the given ID
   * @param {number} offerId The ID of the offer to delete
   * @returns {Observable<any>} Observable representing the result
   */
  deleteOffer(offerId: number): Observable<any> {
    return this.http.delete<any>(this.config.salesApiUrl + '/maintenance/offers/' + offerId);
  }

  /**
   * Updates the status of the offer with the given ID
   * @param {number} offerId The ID of the offer in question
   * @param {OfferStatusUpdateDto} offerStatusUpdate The status to change to, including the reason
   * @returns {Observable<any>} Observable representing the result
   */
  updateOfferStatus(offerId: number, offerStatusUpdate: OfferStatusUpdateDto): Observable<any> {
    const updateOnlyOfferStatus = {
      offerStatusUpdateDto: offerStatusUpdate,
      offerUpdateDto: null
    } as OfferAndStatusUpdateDto;

    return this.http.patch<any>(this.config.salesApiUrl + '/maintenance/offers/' + offerId, updateOnlyOfferStatus);
  }

  /**
   * Expands the offer's expiration date and updates given fields.
   * @param offerId The offer ID of the offer to update.
   * @param auctionOfferUpdateDto The update data.
   */
  expandOffer(offerId: number, offerUpdateDto: OfferUpdateDto): Observable<OfferDto> {
    return this.http.post<OfferDto>(this.config.salesApiUrl + '/maintenance/offers/extension/' + offerId, offerUpdateDto);
  }

  updateOfferAndOfferStatus(offerId: number, offerAndOfferStatusUpdate: OfferAndStatusUpdateDto): Observable<any> {
    return this.http.patch<any>(this.config.salesApiUrl + '/maintenance/offers/' + offerId, offerAndOfferStatusUpdate);
  }

  /**
   * Creates an auction offer out of this offer.
   * @param {number} offerId The ID of the offer to copy
   * @param {OfferStatusUpdateDto} offerStatusUpdate The status to change to, including the reason
   * @returns {Observable<number>} An Observable of the ID of the new auction offer
   */
  copyToAuctionOffer(offerId: number, offerStatusUpdate: OfferStatusUpdateDto): Observable<number> {
    return this.http.post<number>(this.config.salesApiUrl + '/maintenance/offers/auction?sourceId=' + offerId, offerStatusUpdate);
  }

  /**
   * Creates a buy now offer out of this offer.
   * @param {number} offerId The ID of the offer to copy
   * @param {OfferStatusUpdateDto} offerStatusUpdate The status to change to, including the reason
   * @returns {Observable<number>} An Observable of the ID of the new buy now offer
   */
  copyToBuyNowOffer(offerId: number, offerStatusUpdate: OfferStatusUpdateDto): Observable<number> {
    return this.http.post<number>(this.config.salesApiUrl + '/maintenance/offers/buynow?sourceId=' + offerId, offerStatusUpdate);
  }

  /**
   * Returns if a given date is marked as disabled
   * @param {number} year The currently viewed year
   * @param {number} month The currently viewed month
   * @returns {Observable<boolean>} Observable of True, if the date in question shall be disabled, of False otherwise
   */
  getInvalidAuctionEndDates(channel: DistributionChannel, country: Country, year: number, month: number): Observable<InvalidAuctionEndDatesDto> {
    let params = new HttpParams();
    params = params.set('channel', String(channel));
    params = params.set('country', String(country));
    params = params.set('year', String(year));
    params = params.set('month', String(month));
    return this.http.get<InvalidAuctionEndDatesDto>(this.config.salesApiUrl + '/maintenance/invalid-auction-enddates',
      {params: params, headers: this.noCacheHeaders});
  }

  /**
   * Update an offers dealer access groups while offer is in OfferStatus.ACTIVE or OfferStatus.PREPARATION
   * @param {number} offerId The offer's ID
   * @param {OfferAccessGroupsUpdateDto} offerAccessgroupsUpdateDto The dealer access groups DTO to update
   * @returns {Observable<OfferDto>} The updated offer
   */
  updateOfferAccessGroups(offerId: number, offerAccessgroupsUpdateDto: OfferAccessGroupsUpdateDto): Observable<OfferDto> {
    return this.http.put<OfferDto>(this.config.salesApiUrl + '/maintenance/offers/' + offerId
      + '/access-groups', offerAccessgroupsUpdateDto);
  }

  /**
   * Returns the visits of the offer with the specified id
   * @param {number} id
   * @returns {Observable<OfferVisitsDto>}
   */
  getOfferVisits(id: number): Observable<OfferVisitsDto> {
    return this.http.get<OfferVisitsDto>(this.config.salesApiUrl + '/maintenance/offers/' + id + '/visits',
      {headers: this.noCacheHeaders});
  }

  /**
   * Refresh an offer's stored vehicle data
   * @param offerId The offer's ID
   */
  refreshVehicleDataInOffer(offerId: number): Observable<number> {
    return this.http.post<number>(this.config.salesApiUrl + '/maintenance/offers/' + offerId + '?refresh=VEHICLE_DATA', null);
  }

  /**
   * Revoke purchase of an offer
   * @param offerId The offer's ID
   * @param purchaseUpdateDTO The necessary update informationen
   * @returns {Observable<OfferDto>}
   */
  revokePurchase(offerId: number, purchaseUpdateDTO: PurchaseUpdateDTO): Observable<OfferDto> {
    return this.http.put<OfferDto>(this.config.salesApiUrl + '/maintenance/offers/' + offerId + '/purchase', purchaseUpdateDTO);
  }

  getExpiringOffersCount(channel: DistributionChannel): Observable<ExpiringAuctionsCalenderDto> {
    return this.http.get<ExpiringAuctionsCalenderDto>(this.config.salesApiUrl + '/maintenance/expiring-offers-calender/' + channel, { headers: this.noCacheHeaders });
  }

  /**
   * Maps search params to http params
   * @returns {HttpParams}: http params based on search params
   */
  private searchParamsToHttpParams(searchParams: VehicleOfferSearchParams): HttpParams {
    let params = new HttpParams();

    if (searchParams) {
      params = this.addHttpParam(searchParams.brand, 'brand', params);
      params = this.addHttpParam(searchParams.brand2, 'brand2', params);
      params = this.addHttpParam(searchParams.brand3, 'brand3', params);
      params = this.addHttpParam(searchParams.brand4, 'brand4', params);
      params = this.addHttpParam(searchParams.brand5, 'brand5', params);

      params = this.setModelsSearchParams(searchParams, params, 'encode');
      params = this.setModelVariantsSearchParams(searchParams, params, 'encode');

      params = this.addHttpParam(searchParams.priceFrom, 'priceFrom', params);
      params = this.addHttpParam(searchParams.priceTo, 'priceTo', params);
      params = this.addHttpParam(searchParams.powerPsFrom, 'powerPsFrom', params);
      params = this.addHttpParam(searchParams.powerPsTo, 'powerPsTo', params);
      params = this.addHttpParam(searchParams.mileageFrom, 'mileageFrom', params);
      params = this.addHttpParam(searchParams.mileageTo, 'mileageTo', params);
      params = this.addHttpParam(searchParams.motorCapacityFrom, 'motorCapacityFrom', params);
      params = this.addHttpParam(searchParams.motorCapacityto, 'motorCapacityto', params);
      params = this.addHttpParam(searchParams.consumptionFrom, 'consumptionFrom', params);
      params = this.addHttpParam(searchParams.consumptionTo, 'consumptionTo', params);
      params = this.addHttpParam(searchParams.initialRegistrationFrom, 'initialRegistrationFrom', params);
      params = this.addHttpParam(searchParams.initialRegistrationTo, 'initialRegistrationTo', params);
      params = this.addHttpParam(searchParams.zipCode, 'zipCode', params);
      params = this.addHttpParam(searchParams.city, 'city', params);

      params = this.addHttpParam(searchParams.countries, 'countries', params);
      params = this.addHttpParam(searchParams.seats, 'seats', params);
      params = this.addHttpParam(searchParams.chassis, 'chassis', params);
      params = this.addHttpParam(searchParams.colors, 'colors', params);
      params = this.addHttpParam(searchParams.gearTypes, 'gearTypes', params);
      params = this.addHttpParam(searchParams.fuels, 'fuels', params);
      params = this.addHttpParam(searchParams.equipments, 'equipments', params);
      params = this.addHttpParam(searchParams.co2Emission, 'co2Emission', params);
      params = this.addHttpParam(searchParams.emissionClass, 'emissionClass', params);
      params = this.addHttpParam(searchParams.combinedSellingDealers, 'combinedSellingDealers', params);
      params = this.addHttpParam(searchParams.exploitationType, 'exploitationType', params);

      params = this.addHttpParam(searchParams.vatType, 'vatType', params);
      params = this.addHttpParam(searchParams.expiration, 'expiration', params);
      params = this.addHttpParam(searchParams.automaticTransmission, 'automaticTransmission', params);
      params = this.addHttpParam(searchParams.fourWD, 'fourWD', params);
      params = this.addHttpParam(searchParams.heatedSeats, 'heatedSeats', params);
      params = this.addHttpParam(searchParams.leatherSeats, 'leatherSeats', params);
      params = this.addHttpParam(searchParams.trailerHitch, 'trailerHitch', params);
      params = this.addHttpParam(searchParams.navigation, 'navigation', params);
      params = this.addHttpParam(searchParams.xenonLedLight, 'xenonLedLight', params);
      params = this.addHttpParam(searchParams.types, 'types', params);
      params = this.addHttpParam(searchParams.bundling, 'bundling', params);
      params = this.addHttpParam(searchParams.vin, 'vin', params);
      params = this.addHttpParam(searchParams.extDealerId, 'extDealerId', params);
      params = this.addHttpParam(searchParams.extVehicleId, 'extVehicleId', params);
      params = this.addHttpParam(searchParams.directSale, 'directSale', params);
      params = this.addHttpParam(searchParams.allPurchases, 'allPurchases', params);
      params = this.addHttpParam(searchParams.highestBid, 'highestBid', params);
      params = this.addHttpParam(searchParams.onlyOffered, 'onlyOffered', params);
      params = this.addHttpParam(searchParams.purchaseNotReceived, 'purchaseNotReceived', params);
    }
    return params;
  }

  private processSearchParams(searchParams: VehicleOfferSearchParams, params: HttpParams, encodeDecode: string, paramNames: string[]) {
    for (const paramName of paramNames) {
      const paramValue = searchParams[paramName];

      if (paramValue?.length > 0) {
        if (encodeDecode === 'encode') {
          this.encodeComma(paramValue);
        } else if (encodeDecode === 'decode') {
          this.decodeComma(paramValue);
        }

        params = params.set(paramName, String(paramValue));
      }
    }
    return params;
  }

  private setModelVariantsSearchParams(searchParams: VehicleOfferSearchParams, params: HttpParams, encodeDecode: string) {
    const paramNames = ['modelVariants', 'modelVariants2', 'modelVariants3', 'modelVariants4', 'modelVariants5'];
    return this.processSearchParams(searchParams, params, encodeDecode, paramNames);
  }

  private setModelsSearchParams(searchParams: VehicleOfferSearchParams, params: HttpParams, encodeDecode: string) {
    const paramNames = ['models', 'models2', 'models3', 'models4', 'models5'];
    return this.processSearchParams(searchParams, params, encodeDecode, paramNames);
  }

  private encodeComma(strings: string[]) {
    for (let i = 0; i < strings.length; i++) {
      strings[i] = encodeURIComponent(strings[i]);
    }
  }

  private decodeComma(strings: string[]) {
    for (let i = 0; i < strings.length; i++) {
      strings[i] = decodeURIComponent(strings[i]);
    }
  }

  private addHttpParam(field: any, name: string, params: HttpParams) {
    if (field) {
      if (field instanceof Array) {
        params = params.set(name, field.toString());
      } else {
        params = params.set(name, String(field));
      }
    }
    return params;
  }

  /**
   * Maps offer maintenance search params to http params
   * @returns {HttpParams}: http params based on search params
   */
  private offerMaintenanceSearchParamsToHttpParams(searchParams: OfferMaintenanceSearchParams): HttpParams {
    let params = new HttpParams();

    if (searchParams) {
      params = this.addHttpParam(searchParams.missingFollowUpProposalPrice, 'missingFollowUpProposalPrice', params);
      params = this.addHttpParam(searchParams.missingData, 'missingData', params);
      params = this.addHttpParam(searchParams.expiredMissingPurchase, 'expiredMissingPurchase', params);
      params = this.addHttpParam(searchParams.readyToPublish, 'readyToPublish', params);
      params = this.addHttpParam(searchParams.active, 'active', params);
      params = this.addHttpParam(searchParams.withoutCancelled, 'withoutCancelled', params);
      params = this.addHttpParam(searchParams.withoutSold, 'withoutSold', params);
      params = this.addHttpParam(searchParams.withoutCopied, 'withoutCopied', params);
      params = this.addHttpParam(searchParams.bundling, 'bundling', params);
      params = this.addHttpParam(searchParams.types, 'types', params);
      params = this.addHttpParam(searchParams.vin, 'vin', params);
      params = this.addHttpParam(searchParams.externalId, 'externalId', params);
      params = this.addHttpParam(searchParams.contractId, 'contractId', params);
    }

    return params;
  }

  /**
   * Derive the vehicle footer status from an offer.
   */
  deriveVehicleFooterStatusFromOffer(offer: OfferDto): VehicleFooterStatus {
    const offerStatus = offer.status.data;
    const offerType = offer.offerType.data;
    if (['PREPARATION', 'ACTIVE', 'EXTENDED', 'FINISHED','CANCELLED', 'COPIED', 'DELETED', 'EXPIRED']
      .includes(offerStatus)) {
      if (offerType === 'ENFORCED_AUCTION') {
        return <VehicleFooterStatus>('AUCTION' + '_' + offerStatus);
      }
      return <VehicleFooterStatus>(offerType + '_' + offerStatus);
    }
    console.error('Unknown vehicle footer status!');
    return 'UNKNOWN';
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
