import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../store/app.reducers';
import {Observable, Subject} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {VehicleMaintenanceService} from '../../service/vehicle-maintenance.service';
import {TranslateService} from '@ngx-translate/core';
import {filter, takeUntil} from 'rxjs/operators';
import {isVehicleDetailDto, isVehicleItemBaseDto} from '../../misc/typeguard';
import {DealerService} from '../../service/dealer.service';
import {ToastAlertService} from '../../service/toast-alert.service';
import {NgxPopperjsPlacements, NgxPopperjsTriggers} from 'ngx-popperjs';

@Component({
  selector: 'ucs-transport',
  templateUrl: './transport.component.html',
  styleUrls: ['./transport.component.scss']
})

/**
 * Dialog component for transport request creation (including trigger button)
 */
export class TransportComponent implements OnInit, OnChanges, OnDestroy {
  @Input() id: string; // id in case there are more than one transport dialogs in one page
  @Input() disabled = false;
  @Input() existingTransportRequests: TransportRequestDto[];
  @Input() selectedVehicles: VehicleDetailDto[] & OfferItemDto[];
  @Input() purchaseDealer: DealerDetailDto;
  @Input() sellerInformation: SellerInformationDto;
  @Input() tooltipText: string;
  @Input() channel: DistributionChannel;
  @Output() requestCreated = new EventEmitter<TransportRequestDto>();

  transportRequestCreation: TransportRequestCreationDto;
  destinationDealer: DealerDetailDto; // only for display of address
  fromDealer: DealerDetailDto;
  custodyDealerCompanyName: string;
  custodyDealerExternalId: number;
  manualDestinationInput: boolean;
  isManualDealerFormValid: boolean;
  showExistingTransportRequests: boolean;
  allowedToSeeExistingTRs: boolean;
  transportRequestForOwnPurchase: boolean;
  isPb: boolean;
  isSellerPb: boolean;
  dealerAutoCompleteSourceFunction: (string) => Observable<DealerDetailDto[]>;

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private store: Store<fromRoot.AppState>,
    private alertService: ToastAlertService,
    private translate: TranslateService,
    protected dealerService: DealerService,
    private vehicleService: VehicleMaintenanceService
  ) {
  }

  /**
   * Initial setting of the variables, including to TransportRequestCreationDto to create new transport requests
   */
  ngOnInit() {
    this.dealerAutoCompleteSourceFunction = (filter: string) =>
      this.dealerService.getDealers(filter, ['PBV_BUYER', 'PIA_BUYER', 'ALL_UC_BUYER'], this.channel);
    this.isManualDealerFormValid = false;
    this.allowedToSeeExistingTRs = false;
    this.isPb = false;
    this.isSellerPb = false;
    this.resetTransportRequest();
    this.store
      .select(fromRoot.getUserInfo)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(userInfo => {
        this.allowedToSeeExistingTRs = userInfo.currentDealerId === this.sellerInformation?.id;
        this.transportRequestForOwnPurchase = !!(
          this.purchaseDealer && userInfo.currentDealerId === this.purchaseDealer?.id
        );
      });

    this.store
      .select(fromRoot.getUserState)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(userState => {
        this.isSellerPb = userState.isAuctionSellerPb || userState.isBuyNowSellerPb;
        this.isPb = this.channel === 'PB';
        this.setDealerInformation();
      });
  }

  /**
   * On change of input parameters, redefine the fromDealer
   */
  ngOnChanges() {
    this.setDealerInformation();
  }

  private setDealerInformation() {
    if (this.isPb && this.selectedVehicles?.length > 0) {
      if (isVehicleItemBaseDto(this.selectedVehicles[0]) && this.selectedVehicles[0].custodyDealer) {
        this.fromDealer = this.selectedVehicles[0].custodyDealer;
      } else if (isVehicleDetailDto(this.selectedVehicles[0]) && this.selectedVehicles[0].dealerInformation) {
        this.fromDealer = this.selectedVehicles[0].dealerInformation;
      }
    } else {
      this.fromDealer = this.sellerInformation;
    }
    if (this.selectedVehicles[0]?.custodyDealerCompanyName) {
      this.custodyDealerCompanyName = this.selectedVehicles[0].custodyDealerCompanyName;
    }
    if (this.selectedVehicles[0]?.custodyDealerExternalId) {
      this.custodyDealerExternalId = this.selectedVehicles[0].custodyDealerExternalId;
    }
  }

  /**
   * Reset transport request data
   */
  resetTransportRequest() {
    this.transportRequestCreation = {} as TransportRequestCreationDto;
    this.transportRequestCreation.transportRequestType = 'ORDER';
    this.transportRequestCreation.emailCopy = true;
    this.transportRequestCreation.vehicleIds = [];
    this.transportRequestCreation.fromLocationInfo = this.translate.instant(
      'transport.modal.pickup-date-info.placeholder'
    );
    this.transportRequestCreation.toLocationInfo = this.translate.instant(
      'transport.modal.delivery-date-info.placeholder'
    );
    this.selectedVehicles.forEach(vehicle => {
      // seller's view: vehicle.id (vehicle is VehicleBaseDto)
      // buyer's view vehicle.vehicleId (vehicle is VehicleItemBaseDto)
      isVehicleItemBaseDto(vehicle)
        ? this.transportRequestCreation.vehicleIds.push(vehicle.vehicleId)
        : this.transportRequestCreation.vehicleIds.push(vehicle.id);
    });
    this.showExistingTransportRequests = true;
    this.manualDestinationInput = false;
    this.destinationDealer = {} as DealerDetailDto;
    this.transportRequestCreation.destinationDealer = {} as InternalDealerInformationDto;
    this.transportRequestCreation.destinationDealer.type = 'UCS_DEALER';
    if (this.purchaseDealer) {
      (<InternalDealerInformationDto>this.transportRequestCreation.destinationDealer).ucsDealerId = this.purchaseDealer.id;
      this.destinationDealer = this.purchaseDealer;
    }
  }

  /**
   * Toggle the checkbox for vehicle selection
   * @param {number} id The vehicle's ID
   */
  toggleVehicle(id: number) {
    if (this.isVehicleSelected(id)) {
      this.transportRequestCreation.vehicleIds = this.transportRequestCreation.vehicleIds.filter(vehicleId => vehicleId !== id);
    } else {
      this.transportRequestCreation.vehicleIds.push(id);
    }
  }

  /**
   * Returns if a vehicle is selected.
   * @param {number} id The vehicle's ID
   * @returns {boolean} True, if the vehicle is selected, false otherwise.
   */
  isVehicleSelected(id: number): boolean {
    return !!this.transportRequestCreation.vehicleIds.find(vehicle => vehicle === id);
  }

  /**
   * Change dealer autcomplete search to manual input fields
   */
  changeToInput() {
    this.manualDestinationInput = !this.manualDestinationInput;
    if (this.manualDestinationInput) {
      this.transportRequestCreation.destinationDealer = {} as ExternalDealerInformationDto;
      this.transportRequestCreation.destinationDealer.type = 'EXTERNAL_DEALER';
    } else {
      this.transportRequestCreation.destinationDealer = {} as InternalDealerInformationDto;
      this.transportRequestCreation.destinationDealer.type = 'UCS_DEALER';
    }
  }

  /**
   * Select dealer via auto complete dropdown
   */
  dealerSelected(dealer: DealerDetailDto) {
    if (dealer) {
      this.destinationDealer = dealer;
      (<InternalDealerInformationDto>this.transportRequestCreation.destinationDealer).ucsDealerId = dealer.id;
    } else {
      this.transportRequestCreation.destinationDealer = {} as InternalDealerInformationDto;
      this.transportRequestCreation.destinationDealer.type = 'UCS_DEALER';
      this.destinationDealer = {} as DealerDetailDto;
    }
  }

  isCreateButtonDisabled(): boolean {
    return this.transportRequestCreation.vehicleIds.length < 1
      || (!this.isManualDealerFormValid && this.transportRequestCreation.destinationDealer.type === 'EXTERNAL_DEALER')
      || (!this.destinationDealer.address && this.transportRequestCreation.destinationDealer.type === 'UCS_DEALER')
      || !this.transportRequestCreation.carrier;
  }

  /**
   * Create a transport request with the data entered
   */
  createTransportRequest() {
    this.vehicleService.createTransportRequest(this.transportRequestCreation).subscribe(
      (transportRequest) => {
        this.requestCreated.emit(transportRequest);
        this.alertService.success(this.translate.instant('transport.modal.successfully-created'));
        this.resetTransportRequest();
      },
      err => {
        this.alertService.danger(this.translate.instant('transport.modal.error-while-creating'));
        this.resetTransportRequest();
      }
    );
  }

  protected readonly NgxPopperjsPlacements = NgxPopperjsPlacements;
  protected readonly NgxPopperjsTriggers = NgxPopperjsTriggers;

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
